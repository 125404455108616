@import 'theme/variables.scss';

.react-image-viewer {
  overflow: hidden;
  background-color: rgba($black, 0.7);
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .button-group {
    float: right;
    margin-right: 7rem;

    .button {
      position: relative;
      display: block;
      cursor: pointer;
      text-align: center;
      float: left;
      cursor: pointer;
      transition: all 0.4s ease;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .prev-button,
  .next-button {
    position: fixed;
    line-height: 80vh;
    background: none;
    border: 0;
    top: 10vh;
    padding: 0;
    color: $white;
    cursor: pointer;
    opacity: 0.4;
    transition: all 0.4s ease;

    &:hover {
      opacity: 0.8;
    }
  }

  .prev-button {
    left: 0;
  }

  .next-button {
    right: 0;
  }

  .close-button {
    z-index: 2;
    position: absolute;
    width: 2.4rem;
    height: 2.4rem;
    right: 0.8rem;
    top: 0.8rem;
    cursor: pointer;
    transition: all 0.4s ease;
    opacity: 0.5;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 2.4rem;
      width: 0.12rem;
      top: 0;
      right: 1.13rem;
      background-color: $white;
      border-radius: 2rem;
      transform-origin: center;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .image-wrapper {
    position: relative;
    width: 92vw;
    height: 92vh;
    margin: 4vh 4vw;
    overflow: hidden;
  }

  .image-outer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    transition: all 0.35s ease;
    cursor: move;

    &.dragging {
      transition: all 0s ease;
    }

    &:before {
      content: '';
      display: inline-block;
      height: 50%;
      width: 1px;
      margin-right: -1px;
    }

    .image {
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;
      max-height: 100%;
      width: auto !important;
      height: auto !important;
      object-fit: cover;
      transform: scale3d(1, 1, 1);
      transition: all 0.4s ease;
      animation: image-fade-in 0.4s ease;
    }

    &.zoom-0 {
      cursor: default;
    }
  }

  .tool-bar {
    height: 4rem;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    padding-right: 4rem;
    line-height: 4rem;
    opacity: 0.5;
    background: rgba($black, 0);
    transition: all 0.3s ease-in-out;
    min-width: 16rem;
    overflow: hidden;

    &:hover {
      opacity: 1;
      background: rgba($black, 0.7);
    }

    .index-indicator,
    .caption {
      float: left;
      color: rgba($white, 0.6);
      font-size: 1rem;
      font-weight: lighter;
      padding-left: 1.4rem;
      line-height: 4rem;
      vertical-align: middle;
      margin: 0;
    }

    .caption {
      max-width: 60vw;
      overflow: hidden;
    }
  }

  .image-info {
    position: absolute;
    bottom: 2rem;
    left: 0;
    width: 100%;
    text-align: center;
    line-height: 1.4rem;
    font-size: 0.9rem;
    font-weight: lighter;
    color: rgba($white, 0.6);
  }

  .indicators {
    position: fixed;
    left: 0;
    bottom: 1rem;
    width: 100vw;
    height: 3rem;
    min-width: 30rem;
    overflow: hidden;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    .indicators-item {
      position: relative;
      width: 4rem;
      max-width: 10vw;
      height: 3rem;
      margin: 0 0.3rem;
      float: left;
      line-height: 3rem;
      opacity: 0.25;
      cursor: pointer;
      transition: all 0.4s ease;

      &:first-child,
      &:last-child {
        &:after {
          position: absolute;
          content: '';
          display: inline-block;
          height: 0.4rem;
          width: 0.4rem;
          border-radius: 4rem;
          background: rgba($primary-color, 1);
          top: 1.4rem;
          opacity: 0;
          transform: scale(0);
          -webkit-transform: scale(0);
          transition: all 0.6s ease;
        }

        &.invisible:after {
          opacity: 1;
          transform: scale(1);
        }
      }

      &:first-child:after {
        left: -1rem;
      }

      &:last-child:after {
        right: -1rem;
      }

      &.invisible {
        width: 0;
        margin: 0;
      }

      &:before {
        content: '';
        display: inline-block;
        height: 0.3rem;
        width: 100%;
        border-radius: 5rem;
        background: rgba($primary-color, 1);
      }

      &.active,
      &:hover {
        opacity: 0.85 !important;
      }

      &.preview {
        border: 1px solid $color-black-95;
        opacity: 0.5;
        box-sizing: border-box;

        &.active,
        &:hover {
          border: 1px solid rgba($primary-color, 1);
        }

        &:first-child,
        &:last-child {
          &:after {
            content: '';
            display: inline-block;
            height: 2rem;
            width: 0.1rem;
            background: rgba($white, 0.8);
            position: absolute;
            top: 0.5rem;
            opacity: 0;
            transform: scaleY(0);
            -webkit-transform: scaleY(0);
            transition: all 0.6s ease;
            -webkit-transition: all 0.6s ease;
          }

          &.invisible:after {
            opacity: 1;
            transform: scaleY(1);
            -webkit-transform: scaleY(1);
          }
        }

        &.invisible {
          border: 0 !important;
        }

        &:before {
          display: none;
        }

        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          overflow: hidden;
        }
      }
    }
  }
}

.react-image-viewer {
  .direction-control-button {
    .button {
      .bar {
        width: 4rem;
        height: 4rem;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        &:before,
        &:after {
          content: '';
          position: absolute;
          height: 2rem;
          width: 0.12rem;
          left: 1rem;
          top: 0;
          background-color: $white;
          transform-origin: 0 100%;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(135deg);
        }
      }
    }

    .next-button .bar {
      transform: rotate(180deg);
    }
  }

  .button-group {
    .button {
      width: 2rem;
      height: 2rem;
      text-align: center;
      margin: 0.86rem 1rem;
      opacity: 0.5;
      border: 0.14rem solid $white;
      border-radius: 50%;

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 1.6rem;
        width: 0.12rem;
        top: 0.1rem;
        left: 0.8rem;
        background-color: $white;
        border-radius: 2rem;
        transform-origin: center;
        transform: rotate(90deg);
      }
    }

    .zoom-in:after {
      transform: rotate(0deg);
    }
  }

  .bounce {
    width: 2.4rem;
    height: 2.4rem;
    display: inline-block;
    position: relative;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      border-radius: 50%;
      background-color: rgba($primary-color, 1);
      animation: bounce 2s infinite ease-in-out;
    }
  }

  .bounce:after {
    animation-delay: -1s;
  }
}

.image-gallery {
  display: flex;
  height: 100vh;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
}

.image-item {
  width: 10rem;
  height: 10rem;
  background: $white;
  overflow: hidden;
  cursor: pointer;
  border: 1rem solid $white;
  box-shadow: 0 0 0.5rem rgba($black, 0.4);
}

.image-inner {
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center !important;
  transition: all 0.3s ease;
  transform: scale(1);

  &:hover {
    transform: scale(1.2);
  }
}

@keyframes image-fade-in {
  0% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}
