.root {
  font-size: 18px;
  flex-shrink: 0;
  position: relative;
  & :global {
    .ant-avatar-string {
      transform: scale(0.8) translateX(-50%) !important;
    }

    .ant-avatar-string > svg {
      transform: scale(0.8);
    }
  }

  &.isDismissed {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .dismiss {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    zoom: 1.2;
  }
}
