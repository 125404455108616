@import 'theme/variables.scss';

.flexSwitch {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  :global(.ant-legacy-form-item-control-wrapper) {
    flex: 0;
  }

  :global(.ant-legacy-form-item-label) {
    line-height: 42px;
    padding-left: 10px;
    padding-bottom: 0;
  }

  .btn {
    height: auto;
    padding: 0 0 0 8px;

    &::after {
      display: none;
    }

    span {
      color: $text-color;
    }
  }
}
