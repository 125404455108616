@import 'theme/variables.scss';

.root {
  display: flex;
  justify-content: flex-start;

  .time {
    margin-right: 70px;

    :global .ant-progress {
      margin-right: 10px;
    }

    &.hovered {
      &:hover {
        .icon {
          color: $primary-color;
          cursor: pointer;
        }
      }
    }

    &:last-child {
      margin-right: 20px;
    }
  }

  .text {
    font-size: 14px;
    color: $label-color;
  }

  .block {
    margin-top: 8px;
    display: flex;
    flex-direction: column;

    span {
      &:hover {
        cursor: pointer;
      }
    }

    &.disabled {
      span {
        color: $color-black-65;

        &:hover {
          cursor: default;
        }
      }
    }

    :global {
      .ant-popover-open {
        background-color: $white;
      }
    }

    .label {
      margin-left: 34px;
    }
  }

  .label {
    font-size: 12px;
    color: rgba($color-black-55, 0.7);
    display: block;
    margin-bottom: 2px;
  }
}

.estimate {
  width: 100%;
  max-width: 250px;
}

.switch {
  margin-bottom: 8px;
}
