.root {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 16px;

  .fieldName {
    width: 100%;
    margin: 0;
  }
}

.fieldsWrap {
  display: flex;
  align-items: center;

  .input {
    display: none;
    margin: 0;
  }
}

.switch {
  margin-left: 16px;
}

.remove {
  height: auto;
  padding: 0;
  margin: -10px 0 0 4px;
}

.append {
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 0;
}

.dropdownOverlay {
  min-width: unset;
}
