@import 'theme/variables.scss';

.root {
  padding: 24px;

  .form {
    display: flex;
    flex-direction: column;

    .taskSelect {
      margin-top: 24px;
    }

    .btn {
      margin-left: auto;
    }
  }

  .errorMessage {
    margin-bottom: 24px;
  }
}
