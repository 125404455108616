@import 'theme/variables.scss';

.root {
  position: relative;

  .icon {
    position: absolute;
    display: flex;
    top: -4px;
    right: -4px;
    background-color: $color-black-95;
    border-radius: 20px;
    padding: 2px 4px;
  }
}

.tooltipContent {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .changeTariffText {
    text-decoration: underline;
    cursor: pointer;
    align-self: flex-end;
  }
}
