.toChatSelectOption {
  display: flex;
  gap: 8px;
  align-items: center;

  .icon > svg {
    border-radius: 50%;
  }
}

.toChatPopover {
  width: auto !important;
}

.toChatSelect {
  border: 0;
  box-shadow: none;
  background-color: transparent;
  padding: 0;
  min-height: unset !important;
  overflow: hidden;
}
