@import 'theme/variables.scss';

.root {
  padding: 8px;
  border: 1px solid $color-black-85;
  border-radius: 5px;
  cursor: pointer;

  .title {
    margin: 8px 0;
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
  }
}

.header {
  display: flex;
  align-items: center;

  .actions {
    margin-left: auto;
  }
}

.repeating {
  display: flex;
  align-items: center;
  margin: 0 8px;
  font-size: 12px;

  &.on {
    color: $color-green;
  }

  &.off {
    color: $color-red-70;
  }

  &.none {
    color: $color-black-55;
  }

  .text {
    display: block;
    margin-right: 8px;
    color: inherit;
  }
}
