@import 'theme/variables.scss';

.filter {
  margin-bottom: 15px;
  flex-shrink: 0;
}

.titleWrap {
  background-color: $color-white;
  padding: 8px 20px;
  margin: 0 -20px 16px;
  width: calc(100% + 40px);

  & > h3 {
    margin: 0;
  }
}

.filterMenu {
  margin-right: 6px;
  margin-left: auto;
}
