@import 'theme/variables.scss';

.root {
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;

  .contentWrap {
    display: flex;
    align-items: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    line-height: 1.2;
    overflow: hidden;
  }
}
