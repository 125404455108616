@import 'theme/variables.scss';

.root {
  color: $white;
  z-index: $zindex-sider;
  overflow: auto;

  :global {
    .ant-layout-sider-children {
      width: 100%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: auto;
    }
  }
}
