.ant-tag {
  &.custom {
    padding: 2px 8px;
    display: flex;
    align-items: center;
    background: $color-brand-95;
    color: $color-brand-30;
    border: none;
    cursor: default;
    border-radius: 20px;
    font-weight: 300;

    > i.anticon-close {
      margin-left: 20px;
      font-size: 16px;
      color: $white;
      cursor: pointer;
    }

    &.blue {
      background: linear-gradient(to left, #06add0 60%, #038ab6);
    }
  }

  &.small {
    padding: 0 6px;
    height: 14px;
  }
}
