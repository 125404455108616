@import 'theme/variables.scss';

.linkEditor {
  position: absolute;
  z-index: 1000;
  margin-top: -6px;
  max-width: 300px;
  width: 100%;
  opacity: 0;
  background-color: $color-white;
  box-shadow: 0px 5px 10px $color-black-65;
  border-radius: 5px;
  transition: opacity 0.5s;

  .linkWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 8px;
  }

  .linkInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 24px);
    box-sizing: border-box;
    margin: 8px 12px;
    padding: 8px 12px;
    border-radius: 5px;
    background-color: $color-black-95;
    font-size: 15px;
    color: $color-black;
    border: 0;
    outline: 0;
    position: relative;
    font-family: inherit;
  }

  div.linkEdit {
    width: 35px;
    vertical-align: -0.25em;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }

  a {
    color: $color-brand;
    text-decoration: none;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      text-decoration: underline;
    }
  }

  .button {
    width: 20px;
    height: 20px;
    display: inline-block;
    padding: 6px;
    border-radius: 8px;
    cursor: pointer;
    margin: 0 2px;
  }

  :global .ant-tooltip {
    z-index: 100000;
  }
}

.dropdown {
  z-index: 10000;
  display: block;
  position: absolute;
  box-shadow:
    0 12px 28px 0 $color-black-85,
    0 2px 4px 0 $color-black-55;
  border-radius: 4px;
  min-width: 100px;
  min-height: 40px;
  background-color: #fff;

  .dropdownItem {
    cursor: pointer;
    line-height: 16px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-black-55;
    background-color: $color-white;
    border: 0;
    max-width: 109px;
    height: 28px;
    padding-left: 8px;

    .dropdownText {
      display: flex;
      line-height: 20px;
      flex-grow: 1;
      width: 200px;
      font-size: 12px;
    }
  }

  .dropdownItem:hover {
    background-color: #eee;
  }
}

.toolbar {
  display: flex;
  align-items: center;
  margin-bottom: 1px;
  background: $color-black-98;
  padding: 0 16px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  vertical-align: middle;
  height: 44px;
  gap: 8px;
  border-radius: 8px 8px 0 0;

  &.isDisabled {
    background-color: $color-black-95 !important;

    button.toolbarItem {
      pointer-events: none;
      color: $color-black-65;
    }
  }

  .toolbarActive {
    display: flex;
    background-size: contain;
    background-color: rgba(223, 232, 250, 0.3);
  }

  button.toolbarItem {
    box-shadow: none;
    height: 16px;
    border: 0;
    display: flex;
    align-items: center;
    background: none;
    border-radius: 10px;
    cursor: pointer;
    vertical-align: middle;
    padding: 0 4px;
    gap: 16px;
    font-size: 12px;
    color: $color-black-55;
    font-weight: 600;

    &:disabled {
      cursor: not-allowed;

      span.toolbarFormat {
        opacity: 0.2;
      }
    }

    &.toolbarActive {
      background-color: $color-black-85;
      height: 22px;
      border-radius: 5px;

      span {
        opacity: 1;
      }
    }

    span.toolbarFormat {
      background-size: contain;
      display: inline-block;
      height: 16px;
      width: 16px;
      margin-top: 2px;
      vertical-align: -0.25em;
      display: flex;
    }
  }
}

.error {
  display: block;
  margin: 8px 16px 0;
}

.bottomToolbar {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 16px;
  padding-bottom: 8px;

  &.bottomToolbarGap {
    gap: 0;
  }

  &.isDisabled {
    & * {
      pointer-events: none;
      color: $color-black-65 !important;
    }
  }

  &.messageToolbar {
    background: $color-black-98;
    padding: 12px 16px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  i {
    color: $color-black-55;
  }

  :global .ant-btn {
    padding: 0;
  }

  .left {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .right {
    overflow-wrap: anywhere;
    display: flex;
    justify-content: flex-end;
    flex: 1;
    gap: 20px;
  }

  :global .filter-select_root {
    border: none;
    background: transparent;
  }
}

.dividerLeftSide {
  margin-left: 16px;
  margin-right: 16px;
  height: 24px;
}

.resizeInput {
  position: absolute;
  bottom: 0;
  background-color: $color-white;
  width: 98%;
  border-radius: 5px;
}

.isDisabledToolbar {
  background-color: $color-black-95;
}

.divider {
  height: 16px;
  margin: 0;
}
