@import 'theme/variables.scss';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  .iconId {
    margin-right: 0;
  }

  .block {
    display: flex;
    align-items: center;
    gap: 16px;
    overflow: hidden;
    max-width: 90%;
  }
}
