@import 'theme/variables.scss';

.delay {
  width: 50px;
}

.repeatInterval {
  width: 120px !important;
}

.weekdays {
  :global .ant-checkbox-wrapper {
    width: 32px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $color-black-95;
    user-select: none;
    transition: all ease-in-out 0.2s;

    &:hover {
      background-color: $color-brand-90;
    }

    &.ant-checkbox-wrapper-checked {
      background-color: $primary-color;
      color: $white;
    }

    span {
      padding: 0;
    }

    .ant-checkbox {
      display: none;
    }
  }
}
