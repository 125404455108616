@import 'theme/variables.scss';

.orders,
.contacts,
.tasks,
.projects,
.reports,
.team,
.attachments,
.dashboard,
.handbook,
.userProfile,
.billingSettings {
  display: flex;
  align-items: center;
  margin-right: auto;

  .title {
    margin-bottom: 0;
  }

  .button {
    margin-left: 24px;
    display: flex;
    align-items: center;
    font-size: 16px;
  }

  .mailButton {
    margin-left: 16px;
    padding: 0 5px;
  }

  .orderSettingsButton {
    margin-left: 16px;
    font-size: 16px;
  }

  .dropdownContacts {
    left: 346px;
    min-width: 212px !important;
  }
}

.dashboard {
  .title {
    margin-right: 24px;
  }

  .button {
    margin-left: 0;
  }

  .googleSyncBtn {
    margin-left: 16px;
    display: flex;
    align-items: center;
    padding: 0 6px;
  }
}

.handbook {
  .yellowDot,
  .pinkDot {
    padding-left: 48px !important;

    &::before {
      content: '';
      opacity: 1;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 24px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }

  .yellowDot::before {
    background-color: $color-yellow;
  }

  .pinkDot::before {
    background-color: $color-pink;
  }
}

.reports {
  .link {
    font-size: 16px;
    padding: 0;
    margin-left: 16px;
  }
}
