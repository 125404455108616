@import 'theme/variables.scss';

.root {
  padding: 2px 4px;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  background-color: $color-black-85;
  border-radius: 4px;
  border-color: $color-black-45;
  border-style: solid;

  &.myEvent {
    border-color: $color-yellow;
    background-color: $color-yellow-30;
  }

  &.possible,
  &.rejected {
    background-color: $color-white;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 2px;
  }

  &.accepted {
    border-top-width: 0;
    border-right-width: 0;
    border-bottom-width: 0;
  }

  &.rejected .title {
    text-decoration: line-through;
  }

  .info {
    display: flex;
    gap: 4px;
    align-items: baseline;
    width: 100%;
    overflow: hidden;
    margin-right: 4px;
  }

  .title {
    max-width: 100%;
  }

  &.timeGridWeek,
  &.small {
    .info {
      flex-direction: column;
      gap: unset;

      & > * {
        line-height: 1;
      }
    }
  }
}
