.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-top: 20px;
    }
  }
}
