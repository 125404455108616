.modal {
  padding: 47px 24px 32px;

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .title {
      margin: 0;
      font-weight: 700;
    }

    .description {
      text-align: center;
    }

    .formElements {
      width: 100%;

      .datePicker {
        min-width: 100%;
      }

      .input {
        margin: 0;
      }
    }

    .button {
      width: 240px;
    }
  }
}
