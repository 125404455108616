@import 'theme/variables.scss';

.modal {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &.withSearch {
    & :global {
      .ant-tree-switcher {
        display: none;
      }

      .ant-tree-checkbox {
        margin-left: 0;
      }
    }
  }

  .content {
    max-height: 452px;
    min-height: 40px;
    width: 100%;
    overflow: auto;
  }

  & :global {
    .ant-tree-node-content-wrapper {
      height: auto !important;

      &:before {
        content: none !important;
      }

      &.ant-tree-node-selected {
        background-color: $color-brand-90 !important;
      }
    }

    .ant-tree-checkbox-inner {
      border-color: $color-black-55;
    }
  }

  .submit {
    align-self: flex-end;
    font-size: 16px;
  }

  :global {
    .ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
    .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
      background: inherit;
    }
  }
}
