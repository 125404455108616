@import 'theme/variables.scss';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;

  .title {
    display: block;
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .fieldsWrap {
    padding: 24px 24px 9px 24px;
  }

  .dates {
    display: flex;
    justify-content: flex-start;

    .dateStart {
      margin-right: 16px;
    }
  }

  .btnSteps {
    margin: auto 25px 25px auto;
  }
}
