@import 'theme/variables.scss';

.root {
  padding: 14px 10px;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  width: 100%;

  .message {
    display: flex;
    margin-bottom: 16px;
    max-width: 80%;
    width: 100%;

    &.outgoing {
      align-self: flex-end;
    }

    .avatar {
      width: 56px;
    }

    .content {
      padding: 10px 12px;
      background-color: $color-white;
      border-radius: 8px;
      border: 1px solid $color-black-75;

      &.outgoing {
        border-radius: 8px;
      }
    }

    .title {
      margin: 0 0 8px;
    }

    .paragraph {
      margin: 0;

      & > *:not(:first-child) {
        margin-top: 4px;
      }
    }
  }
}
