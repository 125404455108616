@import 'theme/variables.scss';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 16px;

  .background {
    position: absolute;
    min-width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    z-index: -1;
  }

  .content {
    background-color: $color-brand-90;
    padding: 32px;
    border-radius: 5px;
    text-align: center;

    .title {
      white-space: pre;
    }
  }

  .button {
    width: 234px;
  }
}
