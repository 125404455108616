.root {
  position: absolute !important;
  z-index: 10000;
  background-color: white;
  right: 35px;
  bottom: 0;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);

  .divider {
    margin: 0;
  }

  .content {
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 18px 8px 8px 8px;
    height: 100%;

    .messageInputWrap {
      margin-top: auto;
    }
  }

  .widgetWrap {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.linkHeader {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
