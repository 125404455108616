@import 'theme/variables.scss';

.date {
  width: 100%;
  padding: 8px 16px;
  text-align: center;
  color: $color-black-65;
  z-index: 10;

  &:first-of-type {
    padding-top: 16px;
  }
}

.chatDate {
  font-size: 12px;
}
