@import 'theme/variables.scss';

$small: 30px;
$default: 45px;
$large: 60px;

$offset: 8px;
$border-color: rgba($text-color, 0.1);

.root {
  position: relative;
  display: flex;
  margin-bottom: 8px;
  width: 100%;
  min-height: $default;
  background-color: $white;
  border-radius: $border-radius-base;
  border: 1px solid $border-color;
  flex-shrink: 0;

  &.small {
    min-height: $small;

    .body {
      padding: $offset - 4;
    }

    .icon {
      margin-right: $offset - 4;
    }
  }

  &.large {
    min-height: $large;

    .body {
      padding: $offset + 4;
    }

    .icon {
      margin-right: $offset + 4;
    }
  }

  .body {
    padding: $offset;
    display: flex;
    flex-grow: 1;
    color: $color-black-55;
    width: 50%;
  }

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    font-size: 10px;
    color: rgba($text-color, 0.5);
  }

  .icon {
    margin-right: $offset;
    margin-top: 2px;
    color: $primary-color;
    flex-shrink: 0;
    align-items: baseline;
  }

  .name {
    margin-bottom: 2px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
  }

  .size {
    position: relative;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 12px;
  }
}

.link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  margin-right: 10px;
  overflow: hidden;
  margin-bottom: 10px;

  .href {
    color: $primary-color;
    margin-bottom: 0;
  }
}

.actions {
  height: inherit;
  padding: 5px;
  display: flex !important;
  min-height: 30px;
  align-items: center;

  border: {
    left: 1px solid $border-color;
    top: 0;
    right: 0;
    bottom: 0;
  }

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.info {
  display: flex;
  align-items: center;
  gap: 4px;
}

.infoDivider {
  color: $color-black-55;
}

.actionsIcon {
  transform: rotate(90deg);
}

.validityDate {
  font-size: 12px;
  color: $color-black-55;

  &.validityDateOutdated {
    color: $color-yellow-40 !important;
  }
}

.iconsWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  margin-left: 10px;
  margin-right: 8px;
}