@import 'theme/variables.scss';

:root .table {
  thead > tr > th.headerCell {
    padding: 16px 8px;
    font-weight: 600;
    text-transform: uppercase;
    vertical-align: top;
  }

  :global {
    .editable-cell-value-wrap {
      padding: 8px;
      cursor: pointer;
      border: 1px solid $color-black-65;
      border-radius: 4px;

      &:hover {
        border-color: rgba($primary-color, 0.5);
      }
    }

    .ant-table-tbody .ant-table-row-cell-break-word {
      padding: 8px !important;
    }

    .ant-table-body {
      overflow-x: auto !important;
    }

    .ant-table-footer {
      border-top: 1px solid $color-black-85;
      padding: 0;
      background-color: transparent;

      &::before {
        content: none;
      }
    }
  }

  .tableCellText {
    display: block;
    margin-top: 10px;
  }

  .deleteBtn {
    display: flex;
    padding: 0;
    height: auto;
    margin-top: -4px;
  }
}

.total {
  display: flex;
  align-items: center;
  margin-top: 24px;

  > div {
    margin-bottom: 0;
  }
}
