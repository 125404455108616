@import 'theme/variables.scss';
@import 'theme/styles/index.scss';

::placeholder {
  color: $color-black-55;
}

html {
  overflow: hidden; // fix product fruits dots overflow
}

html,
body,
#root,
.app {
  font-feature-settings: normal;
  margin: 0;
  min-height: 100vh;
}

.app {
  display: flex;
}

.category-icon {
  width: 20px;
}

.ant-tag,
.ant-input,
.ant-badge,
.ant-progress,
.ant-modal,
.ant-dropdown,
.ant-carousel,
.ant-calendar-picker-container,
.ant-checkbox-wrapper,
.ant-avatar,
.ant-notification {
  font-feature-settings: normal;
}

.ant-typography ol li,
.ant-typography ul li {
  margin-left: 28px;
}

.not-list {
  pointer-events: none;
}
