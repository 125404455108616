@import 'theme/variables.scss';

$widthLine: 2px;
$shadow: rgba($color-brand-90, 0.5);

%navigator {
  position: absolute;
  top: 50%;
  left: -3px;
  width: 6px;
  transform: translateY(-50%);
  height: 56px;
  background-color: $color-black-75;
  border-radius: 10px;
  transition: 0.2s ease-in-out;
}

.root {
  background: rgba($color-black-55, 0.3);
  background-clip: padding-box;
  z-index: 10;
  transition: 0.2s ease-in-out;

  width: $widthLine * 2 + 1;
  margin-left: -$widthLine;
  margin-right: -$widthLine;
  border-left: $widthLine solid rgba($white, 0);
  border-right: $widthLine solid rgba($white, 0);
  flex-shrink: 0;
  cursor: col-resize;

  &:hover {
    border-left: $widthLine solid $shadow;
    border-right: $widthLine solid $shadow;

    &.withNavigator {
      &:after {
        opacity: 1;
      }
    }
  }

  &.withNavigator {
    position: relative;

    &:before {
      content: '';
      @extend %navigator;
      z-index: 2;
    }

    &:after {
      content: '';
      @extend %navigator;
      width: 10px;
      left: -5px;
      height: 60px;
      background-color: $color-brand-90;
      z-index: 0;
      opacity: 0;
    }
  }
}
