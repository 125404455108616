.root {
  padding: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .title {
    margin: 20px 0 !important;
    text-align: center;
  }

  .buttons {
    display: flex;
    gap: 20px;
  }
}
