.root {
  padding: 39px 24px 32px;
  text-align: center;

  .title {
    margin-bottom: 30px;
  }

  .button {
    width: 100%;
    margin-bottom: 8px;
    font-size: 16px;
  }
}
