@import 'theme/variables.scss';

.root {
  display: flex;
  align-items: center;

  .commonOption {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .icon {
    width: 23px;
    height: 23px;
  }

  .textPrimary {
    color: $primary-color;
    margin-right: 5px;
  }

  .noAccess {
    color: $color-black-65;
  }

  .typeIcon {
    & > svg {
      width: 18px;
      height: 18px;
    }
  }
}
