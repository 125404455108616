@import 'theme/variables.scss';

.icon {
  border-radius: 50%;
  background-color: #efddff;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;

  &.middle {
    background-color: #f8effe;
    width: 36px;
    height: 36px;

    & > svg {
      width: 24px;
      height: 24px;
    }
  }

  &.big {
    background-color: #f8effe;
    width: 48px;
    height: 48px;

    & > svg {
      width: 32px;
      height: 32px;
    }
  }
}
