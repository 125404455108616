@import 'theme/variables.scss';

.editorContainer {
  border-radius: 5px;
  border: 1px solid $color-black-65;
  max-width: 600px;
  color: $color-black;
  position: relative;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  min-width: 100%;

  &.editorContainerMessage {
    border: none;
    background-color: white;
    border-radius: 8px;
  }

  &.isDisabled {
    background-color: $color-black-95;
    cursor: not-allowed;
  }

  .editorInner {
    position: relative;
  }

  .editorInput {
    min-height: 45px;
    resize: none;
    font-size: 15px;
    caret-color: $color-black;
    position: relative;
    tab-size: 1;
    outline: 0;
    padding: 15px 10px;
    caret-color: #444;
    max-height: 200px;
    overflow: auto;
  }

  .resizeInput {
    resize: vertical;
    max-height: 100%;
  }

  .editorPlaceholder {
    color: $color-black-55;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 15px;
    left: 10px;
    font-size: 14px;
    user-select: none;
    display: inline-block;
    pointer-events: none;
  }

  .descriptionEditorInput {
    min-height: 150px;
  }

  :global .editor-text-bold {
    font-weight: bold;
  }

  :global .editor-text-italic {
    font-style: italic;
  }

  :global .editor-text-strikethrough {
    text-decoration: line-through;
  }

  :global .editor-quote {
    margin: 12px 0 12px 10px;
    border-left: 4px solid $color-black-85;
    padding-left: 6px;
  }

  :global .editor-heading-h1 {
    font-size: 28px;
  }

  :global .editor-heading-h2 {
    font-size: 21px;
  }

  :global .editor-heading-h3 {
    font-size: 16px;
  }

  :global .editor-heading-h4 {
    font-size: 14px;
  }

  :global .editor-heading-h5 {
    font-size: 11px;
  }
}

.attachments {
  margin-top: 20px;
}

.loading {
  margin-top: 8px;
  position: absolute;
  display: flex;
  justify-content: center;
  top: -34px;
  left: 0;
  right: 0;
}
