@import 'theme/variables.scss';

.root {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;

  .filter {
    opacity: 0.5;
    max-width: 160px;
  }
}

.optionContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.replaceInfo {
  display: flex;
  align-items: center;
  gap: 8px;
}
