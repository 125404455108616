.tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 12px 24px 24px;

  & :global {
    .ant-tabs-content {
      flex: 100%;
    }
  }
}

.attachment {
  margin-bottom: 16px;
}