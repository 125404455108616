@import 'theme/variables.scss';

.root {
  .select {
    border: 1px solid rgba($primary-color, 0.08);
    border-radius: $border-radius-base;
  }

  :global {
    .ant-select-selection {
      min-width: 120px;
      height: 26px;
      background-color: $color-black-95;
      border: none;
      box-shadow: none;
    }

    .ant-select-selection__rendered {
      line-height: 26px;
    }
  }
}
