.root {
  .skeletonWrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-bottom: 24px;

    .skeleton {
      margin-bottom: 0;
    }
  }

  .documents {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-bottom: 24px;
  }

  .title {
    margin-top: 9px;
    margin-bottom: 16px;
  }

  .daysToPaymentWrap {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .buttonsWrap {
    display: flex;
    gap: 24px;
    justify-content: flex-end;
    margin-top: 9px;
  }

  .previewBtn {
    padding: 0;
  }

  .warningMessage {
    margin-bottom: 16px;
  }

  .comment {
    margin-top: 24px;
  }
}
