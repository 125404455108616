@import 'theme/variables.scss';

.root {
  .title {
    margin-bottom: 16px;
  }

  .radio {
    margin-bottom: 0;
  }

  .languageWrap {
    display: flex;
    align-items: center;
    gap: 16px;

    .title {
      margin-bottom: 0;
    }
  }

  .divider {
    margin: 24px -24px;
    width: calc(100% + 48px);
  }

  .foundationWrap {
    display: grid;
    grid-template-columns: 215px 105px 215px 403px;
    gap: 16px;
  }

  .documentsButton {
    width: auto !important;
    padding: 0;
    align-items: center;
    display: flex;
  }

  .vatWrap {
    display: flex;
    gap: 16px;
  }

  .withVat {
    margin: 20px 0 24px;
  }

  .vat,
  .currency,
  .comment {
    margin-bottom: 24px;
  }

  .locationWrap {
    display: grid;
    grid-template-columns: 1fr 200px;
    gap: 24px;
  }

  .withoutMargin {
    margin: 0;
  }

  .datesWrap {
    display: grid;
    grid-template-columns: repeat(3, 190px);
    gap: 16px;
  }

  .datePickerWrapper {
    min-width: unset;
  }

  .daysToPaymentWrap {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .requisitesBtn {
    height: auto;
    padding: 0;
    border: 0;
  }

  .buttonsWrap {
    display: flex;
    gap: 24px;
    justify-content: flex-end;
  }

  .previewBtn {
    padding: 0;
  }

  .blockWrapper {
    padding: 24px;
    border: 1px solid $color-black-85;
    border-radius: 4px;
    margin-bottom: 24px;
    background-color: white;
  }

  .blockWrapperGray {
    background-color: $color-black-95;
    border-radius: 4px;
    padding: 24px;
    margin-bottom: 24px;
  }

  .documentType {
    margin-top: 20px;
    min-width: 90px;
  }

  .scrollToPeriodicActForm {
    animation: hideBackground 5s;
    border-radius: 4px;
    padding: 4px;
  }

  @keyframes hideBackground {
    from {
      background-color: rgba($primary-color, 0.1);
    }
    to {
      background-color: transparent;
    }
  }
}
