@import 'theme/variables.scss';

.root {
  border-bottom: none;
  line-height: initial;
  background-color: transparent;
  display: flex;
  align-items: center;

  .item {
    display: flex;
    align-items: center;
    color: $primary-color;
    border-bottom: none;
    margin-right: 8px;
    margin-top: 0;
    line-height: 19px;
    padding: 8px;
    border-radius: $border-radius-base;
    padding: 8px !important;

    & > span > span {
      margin-right: 10px;
    }

    &:after {
      display: none;
    }

    &:hover,
    &:focus,
    &:active,
    &:global(.ant-menu-item-selected) {
      background-color: $color-brand-90;
      border-bottom: none;
    }

    &.divider {
      background-color: transparent;
      cursor: default;
    }
  }
}
