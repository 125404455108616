@import 'theme/variables.scss';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $color-white inset !important;
}

.ant-input,
.ant-input-number {
  min-height: $height-base;
  border: 1px solid $color-black-65;

  &:focus {
    border-color: #32a6a8;
  }
}

.ant-input-sm {
  min-height: 28px;
}

.ant-input-affix-wrapper .ant-input-prefix,
.ant-input-affix-wrapper .ant-input-suffix {
  color: $color-black-55;
}

.ant-input-affix-wrapper .ant-input-prefix {
  position: absolute;
  top: 50%;
  left: 12px;
  z-index: 2;
  display: flex;
  align-items: center;
  line-height: 0;
  transform: translateY(-50%);
}

.ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 2;
  display: flex;
  align-items: center;
  line-height: 0;
  transform: translateY(-50%);
}

.ant-input-affix-wrapper {
  position: relative;
  background-color: $color-white;
  padding: 0;
  border: none;

  &:before {
    display: none;
  }

  .ant-input-prefix {
    + .ant-input {
      padding-left: 36px;
      padding-right: 30px;
    }
  }

  & > input {
    min-height: 36px;
    padding: 4px 36px 4px 11px;
  }
}

.ant-input[name='password'] {
  padding-right: 36px;
}
