@import 'theme/variables.scss';

.root {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .controls {
    display: flex;
    justify-content: space-between;
  }

  .topics {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 16px;
  }

  .skeletonTopic {
    max-width: unset;
    width: 100%;
    height: 466px;
  }

  .topic {
    display: flex;
    flex: auto;
    flex-direction: column;
    gap: 16px;
    height: 100%;
  }

  .topicTitleWrap {
    padding: 8px;
    text-align: center;
    border-radius: 10px;
  }

  .tariffs {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 16px;
    height: 100%;
  }

  .tariff {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid $color-black-85;
    padding-bottom: 16px;
    flex: auto;
    gap: 24px;
  }

  .tariffTitleWrap {
    border-radius: 9px 9px 0 0;
    padding: 16px;
    min-height: 100px;
  }

  .tariffContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 16px;
  }

  .tariffPirceWrap {
    margin-bottom: 16px;
  }

  .tariffPirce {
    display: flex;
    align-items: baseline;
    gap: 12px;
  }

  .monthlyPrice {
    text-decoration: line-through;
    font-size: 18px;
  }

  .tariffButton {
    font-size: 16px;
    width: 100%;
    margin-top: auto;
  }

  .modules {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 20px;
  }

  .module {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    &.withBorderBottom {
      padding-bottom: 8px;
      border-bottom: 1px solid $color-brand-90;
    }
  }

  & :global {
    .ant-radio-button-wrapper::before {
      content: unset;
    }

    .ant-radio-group {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
