@import 'theme/variables.scss';

.root {
  min-height: 100vh;
  background-color: $color-black-95;
  padding: 0 16px;

  .logo {
    margin: 52px auto 42px;

    @media (max-width: $screen-sm) {
      margin: 24px auto 30px;
    }
  }

  .content {
    background-color: $color-white;
    max-width: 480px;
    width: 100%;
    margin: 0 auto;
    border-radius: 8px;
    padding: 40px 74px;

    @media (max-width: $screen-sm) {
      padding: 24px 16px;
      max-width: calc(100vw - 32px);
    }
  }

  .selectWorkspace {
    background-color: transparent;
    max-width: 786px;
    padding: 0;
  }

  .withFooter {
    max-width: 642px;
    padding: 40px 65px;

    @media (max-width: $screen-sm) {
      padding: 24px 16px;
      max-width: calc(100vw - 32px);
    }
  }

  .notDisplayed {
    display: none;
  }

  .languageSelectWrap {
    position: fixed;
    bottom: 32px;
    right: 32px;
    z-index: 9999;

    @media (max-width: $screen-md) {
      position: relative;
      margin: auto auto 20px;
      bottom: 0;
      left: 0;
    }

    .languageSelect {
      max-width: max-content;

      @media (max-width: $screen-md) {
        margin-top: 20px;
      }
    }
  }
}
