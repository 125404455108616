@import 'theme/variables.scss';
@import 'theme/styles/_mixins.scss';

.root {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  border-right: 1px solid $color-black-85;
  background-color: $color-white;
  padding: 32px;
  overflow: hidden;

  &.hidden {
    padding: 0;
    border: unset;
  }

  &.loading {
    & :global {
      .fc {
        opacity: 0.5;
      }
    }
  }

  .wrapper {
    height: 100%;
    position: relative;
  }

  .spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }

  .event {
    padding: 0;
    border: unset;
    border-radius: unset;
    background-color: unset;
    box-shadow: unset;
    overflow: hidden;
  }

  @include calendarCommonStyles;

  & :global {
    .fc {
      .fc-timeGridWeek-view {
        .fc-day-today {
          background-color: $color-brand-90;
        }
      }

      .fc-resourceTimeGridDay-view {
        .fc-timegrid-col.fc-day-today {
          background-color: $color-white;
        }

        .fc-col-header-cell {
          border-left: unset;
          text-align: left;
        }

        .fc-scrollgrid-section-header > th {
          .fc-scroller::-webkit-scrollbar {
            display: none !important;
          }
        }

        tbody > .fc-scrollgrid-section > td:nth-child(1) {
          .fc-scroller::-webkit-scrollbar {
            display: none !important;
          }
        }
      }
    }
  }
}
