@import 'theme/variables.scss';

.root {
  max-width: 642px;
  width: 100%;
  margin: 64px auto 0;
  padding-bottom: 50px;

  & :global {
    .ant-collapse-item {
      width: 100%;
      padding: 16px 24px;
      border: none;
      background-color: $color-white;
      border-radius: 10px !important;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    .ant-collapse-header {
      padding: 0 32px 0 0 !important;
      position: relative;
      color: $color-black;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;

      .ant-collapse-arrow {
        right: 0 !important;
        transform: translateY(-50%) !important;
        color: $color-black-55 !important;
      }
    }

    .ant-collapse-item-active {
      .ant-collapse-header {
        margin-bottom: 24px;
      }
    }

    .ant-collapse-content-box {
      padding: 0;
      padding-top: 0 !important;
    }
  }

  .link {
    font-weight: 600;
  }

  .footnoteTitle {
    margin-bottom: 0;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: $screen-sm) {
      text-align: left;
    }
  }

  .footnoteText {
    margin-bottom: 0;
    text-align: center;

    @media (max-width: $screen-sm) {
      text-align: left;
    }
  }
}
