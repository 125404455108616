.root {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;

  .item {
    flex-wrap: nowrap;
  }

  .detailsField {
    overflow: hidden;
  }
}

.loadingWrapper {
  display: flex;
  margin-bottom: 8px;
}
