@import 'theme/variables.scss';

.root {
  font-family: inherit;
  display: flex;
  align-items: center;

  &.large {
    .input,
    :global(.flag-dropdown) {
      height: $height-lg;
    }
  }

  input.input {
    border-color: $color-black-65;
    border-radius: $border-radius-base;
    width: 100%;
    padding: 4px 11px;
    min-height: $height-base;
  }

  .notErrors:focus,
  .notErrors:hover {
    border-color: $color-brand;
  }

  .notErrors:focus {
    box-shadow: 0 0 0 2px #16969c33;
  }

  :global {
    div.flag-dropdown {
      position: initial;
      border: none;
      background-color: transparent;
      border-radius: 0;
      order: -1;
      height: $height-base;

      .selected-flag,
      .selected-flag:focus {
        background-color: transparent;
      }
    }

    .flag-dropdown.open {
      z-index: 4 !important;
      background-color: transparent;

      .selected-flag,
      .selected-flag:focus {
        background-color: transparent;
      }
    }

    ul.country-list {
      width: 100%;
      margin-top: 4px;
      box-shadow: $shadow-2;
      border-radius: $border-radius-base;

      .country:focus {
        outline: none;
      }
    }
  }

  .disabled {
    color: $color-black-65;
    background-color: $color-black-95;
  }
}
