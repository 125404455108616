@import 'theme/variables.scss';

.root {
  margin-left: auto;
}

.iconBtn {
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  width: 242px;
  height: 100%;
  max-height: 304px;
  background: $white;
  border-radius: $border-radius-base;
  box-shadow: $shadow-3;
  padding: 16px;

  .contentHeader {
    margin-bottom: 16px;
    background-color: white;
  }

  .contentList {
    margin: 0;
    padding: 0;
    overflow-y: auto;
    height: 235px;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  .contentItem {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
  }

  .itemHeader {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
