@import 'theme/variables.scss';

.form {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  .foundationDate {
    max-width: unset;
  }

  .withoutCountryWrap {
    display: grid;
    column-gap: 16px;
    grid-template-columns: 1fr 1fr;

    & > div:last-child {
      grid-column: 1/3;
    }
  }

  .dateAndEmployees {
    display: flex;
    gap: 16px;

    & > div { 
      width: 100%;
    }
  }

  .divider {
    background-color: $color-brand-45;
    width: calc(100% + 48px);
    margin-left: -24px;
  }
}

.root {
  .contacts {
    margin-bottom: 20px;
    flex: 1 1 auto;
    overflow: auto;
  }

  .submit {
    display: flex;
    margin: auto 5px 5px auto;
    flex-shrink: 0;
  }

  .iban,
  .swift {
    text-transform: uppercase;
  }

  .uploadBlock {
    width: 100%;
    margin-top: -55px;

    :global div.ant-upload {
      width: 200px;
      margin-left: auto;
    }
  }

  .upload {
    justify-content: flex-start;
    margin-bottom: 15px;
    color: $primary-color;
    height: 30px;

    :global(.ant-upload-drag-container) {
      text-align: left;
    }

    .icon {
      margin-right: 10px;
    }
  }

  .uploadBtn {
    margin-bottom: 0;
    background-color: white;
  }
}
