@import 'theme/variables.scss';

.root {
  :global {
    .ant-collapse-borderless {
      background-color: transparent;
    }

    .ant-collapse-content-active {
      overflow: visible;
    }

    .ant-collapse-item {
      border-bottom: none;

      > .ant-collapse-header {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        max-width: 95%;
        font-weight: 600;
        font-size: 16px;
        padding: 0;
        color: $text-color;
        padding-left: 0;
      }
    }

    .ant-collapse-content {
      > .ant-collapse-content-box {
        padding: 0px;
      }
    }

    .ant-collapse
      > .ant-collapse-item.ant-collapse-no-arrow
      > .ant-collapse-header {
      color: $text-color;
      padding: 0;
    }

    .ant-collapse-item-disabled {
      .ant-collapse-header {
        &:hover {
          cursor: default;
        }
      }
    }
  }
}
