.textareaItem {
  margin: 0;
}

.textarea {
  resize: none;
}

.mb24 {
  margin: 0 0 24px !important;
}

.mb16 {
  margin: 0 0 16px !important;
}

.codeCopy {
  margin-bottom: 20px;
}

.hiddenFiled {
  display: none;
}

.tooltip {
  display: flex;
  align-items: center;
  gap: 8px;
}

.editor {
  margin-top: 24px;
}
