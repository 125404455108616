.root {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;

  .employee {
    width: 552px;
  }

  .info {
    margin-bottom: 16px;
  }

  .btnSubmit {
    margin-left: auto;
    margin-top: auto;
  }

  .table {
    :global {
      .ant-table-body,
      .ant-table-content {
        overflow-x: auto !important;
        font-size: 14px;
      }

      :root & .ant-table .ant-table-tbody > tr > td {
        padding: 8px 16px !important;
      }
    }
  }
}
