@import 'theme/variables.scss';

.root {
  line-height: 1.4;
  word-break: normal;

  &.text,
  &.paragraph {
    font-size: 14px;

    &.small {
      font-size: 12px;
    }

    &.large {
      font-size: 16px;
    }
  }

  &.regular {
    font-weight: 400;
  }

  &.bold {
    font-weight: 700;
  }

  &.semibold {
    font-weight: 600;
  }
}

.color-inherit {
  color: inherit;
}
