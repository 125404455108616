.root {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .skeleton {
    height: 260px;
    max-width: unset;
  }

  .button {
    align-self: flex-end;
    font-size: 16px;
  }
}
