@import 'theme/variables.scss';

.root {
  margin-bottom: 10px;

  .flexWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  :global {
    .ant-row.ant-legacy-form-item {
      margin-bottom: 10px;
    }
  }

  .closeBtn {
    color: $color-black-55;

    &:hover {
      color: $color-black-55;
    }
  }
}

.step {
  position: relative;
  padding-bottom: 2px;
  padding-top: 10px;

  &::before {
    position: absolute;
    content: '';
    top: 100%;
    width: 100%;
    height: 1px;
    background-color: $color-black-55;
    opacity: 0.3;
  }

  &.lastStep {
    &::before {
      content: none;
    }

    :global {
      .ant-row.ant-legacy-form-item {
        margin-bottom: 0px;
      }
    }
  }

  &.disabled {
    position: relative;
    opacity: 0.5;

    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
    }
  }
}
