@import 'theme/variables.scss';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 12px;
  min-width: 110px;
  max-width: 150px;
  padding: 4px 14px;
  text-align: center;
  height: 24px;
  font-size: 12px;
  margin-right: 0;
  color: $color-black-55;
  background-color: $color-black-95;
  transition: transform 500ms ease-in-out;
  backface-visibility: hidden;

  &:hover {
    opacity: 1;
  }

  &.small {
    height: 20px;
  }

  &.large {
    height: 28px;
  }

  &.active {
    color: $color-yellow;
    background-color: rgba($color-yellow, 0.2);
  }

  &.danger {
    color: $color-red-70;
    background-color: $color-red-90;
  }
}

.flip {
  transition: transform 500ms ease-in-out;
  transform: rotateX(180deg);
}
