@import 'theme/variables.scss';

.root {
  display: flex;
  position: relative;

  .countrySelect {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    min-height: $height-base;
    max-height: $height-base;
    overflow: hidden;
    padding: 0 8px;
  }

  .companySelect {
    input {
      min-height: $height-base;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .searchButton {
    height: calc(#{$height-base} - 6px);
    position: absolute;
    right: 3px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    box-shadow: none !important;
  }
}
