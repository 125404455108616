.root {
  padding: 16px 0;
}

.filter {
  margin: 0px 20px 16px 20px;
  flex-shrink: 0;
}

.divider {
  margin: 0 0 16px;
}
