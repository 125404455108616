.body {
  overflow-y: auto;
}

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 16px;
}

.wrapLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .loader {
    height: 150px;
    width: 150px;
  }
}

.backgroundImage {
  position: absolute;
  min-width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  z-index: -1;
}
