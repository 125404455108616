@import 'theme/variables.scss';

.root {
  width: auto !important;
  position: relative;
  display: inline-flex;
  padding: 0;
  border-radius: 4px;
  min-height: 24px;
  max-width: 218px;

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .content {
    transition: all 0.1s ease-in-out;
    max-width: 100%;
    padding: 2px 4px;
    display: flex;
  }

  &.isTag {
    margin-right: 0px !important;

    &.isClearable {
      min-width: 27px;
      justify-content: center;

      button {
        transition: all 0.1s ease-in-out;
        overflow: hidden;
        position: absolute;
        right: 0;
        width: 0;
        padding: 0;
      }

      &:hover {
        .content {
          visibility: hidden;
          max-width: calc(100% - 20px);
        }

        button {
          width: auto;
          padding: 0 4px;
        }
      }
    }

    &.isActions {
      min-width: 27px;
      justify-content: center;

      & > div:last-child {
        position: absolute;
        right: 0;
        width: 0;
        padding: 0;
        display: none;
      }

      &.isHover {
        .content {
          visibility: hidden;
          max-width: calc(100% - 20px);
        }

        & > div:last-child {
          width: auto;
          padding: 0 4px;
          display: block;
        }
      }
    }

    &.isClearable.ellipsisOnHover,
    &.isActions.ellipsisOnHover {
      justify-content: flex-start;

      .content {
        visibility: visible;
      }
    }

    &.createTypeOption {
      background-color: $color-brand-90 !important;
      cursor: pointer;

      & > div {
        padding: 4px 8px;
      }

      & > span {
        color: $color-white;
      }

      .createTypeText {
        color: $color-brand !important;
        font-size: 16px;
      }

      &.createTypeOptionSelected {
        background-color: $color-brand-45 !important;

        & > div > span {
          color: $color-white !important;
        }

        .createTypeText {
          color: $color-white !important;
        }
      }
    }

    .underline {
      text-decoration: underline;
    }

    &.clickable {
      cursor: pointer;
    }
  }
}
