@import 'theme/variables.scss';

.emojiPopover {
  & :global {
    .ant-popover-inner-content {
      padding: 0;
      border-radius: 8px;
    }

    .EmojiPickerReact {
      --epr-text-color: $color-black;
      --epr-picker-border-color: transparent;
      --epr-search-input-bg-color: $color-white;
      --epr-search-border-color: $color-black-55;
      --epr-emoji-size: 24px;

      * {
        font-family: 'Open Sans';
      }

      .epr-search-container input.epr-search {
        border: 1px solid $color-black-55 !important;

        &:focus {
          border-color: $color-brand !important;
        }

        &::placeholder {
          color: $color-black-55;
        }
      }

      .epr-emoji-category-label {
        text-transform: none !important;
        font-weight: 600 !important;
        font-size: 14px !important;
      }

      .epr-category-nav {
        display: none;
      }

      .epr-hidden,
      &.epr-search-active .epr-hidden-on-search,
      &:not(.epr-search-active) .epr-visible-on-search-only {
        display: grid !important;
        opacity: unset !important;
        visibility: unset !important;
      }

      &.epr-search-active .epr-hidden-on-search,
      li.epr-emoji-category:not(:has(.epr-visible)) {
        display: grid !important;
        opacity: 1 !important;
        visibility: unset !important;
        pointer-events: unset !important;
      }

      &.epr-main:has(input:not(:placeholder-shown)) .epr-hidden-on-search {
        .epr-emoji-category-label {
          display: none;
        }
      }
    }
  }
}

.emojiIcon {
  cursor: pointer;
}

.toolbarBtn {
  height: auto;
  padding: 0;
  border: 0;
  display: flex;
  line-height: 0;
}

.toolbarSubmit {
  padding: 0;
  height: auto;
  border: 0;
}

.btnEvent {
  width: 16px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnSubmitComment {
  font-weight: bold;
  padding: 0;
  margin-left: auto;
  margin-right: 6px;

  [disabled] {
    padding: 0;
  }
}

.private {
  font-size: 12px;
}

.isPrivate {
  margin-right: 0;
}
