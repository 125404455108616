@import 'theme/variables.scss';

.root {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  overflow: hidden;

  .step {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 8px;
    background-color: $color-white;
    position: relative;
    z-index: 9999;

    .number {
      width: 36px;
      height: 36px;
      background-color: $color-brand;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.isNext {
      .number {
        background-color: $color-black-75;
      }
    }
  }

  .lineWrap {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 4px;
    width: 100%;
    background-color: $color-white;

    .line {
      width: 100%;
      height: 1px;
      background-color: $color-black-75;

      &.isCompleted {
        height: 2px;
        background-color: $color-brand;
      }
    }
  }
}
