@import 'theme/variables.scss';

.root {
  .logo {
    display: flex;
    align-items: center;
    margin: 0 24px;
    flex-shrink: 0;
    height: 62px;
  }

  .menuWrapper {
    overflow: auto;
  }

  .supportWidgetSpace {
    display: flex;
    align-items: flex-end;
    min-height: 128px;
    width: 100%;
    margin-top: auto;
  }

  .btnCollapse {
    height: 48px;
    display: flex;
    align-items: center;
    padding: 16px;
    width: 100%;
    background-color: $color-black-25 !important;
    border-radius: 0;

    :global(.with-bottom-banner-spacer) & {
      margin-bottom: $bottom-banner-height;
    }

    > i {
      display: inline-flex;
      transition: 0.2s ease-in-out transform;
    }

    &.collapsed {
      justify-content: center;

      > i {
        transform: rotate(180deg);
      }
    }
  }
}
