.root {
  display: grid;
  padding: 24px 24px 32px;
  grid-template-columns: 425px 1fr;
  gap: 24px;

  .content {
    display: flex;
    flex-direction: column;
  }

  .title {
    margin: 32px 0;
  }

  .description {
    margin-bottom: 16px;
  }

  .submit {
    font-weight: 600;
  }

  .qr {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
