.root {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .header {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
  }

  .detailsBtn {
    font-size: 16px;
    padding-inline: 0px;
    align-self: flex-start;
  }
}
