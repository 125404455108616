.root {
  padding: 24px;

  &.withoutSubject {
    padding-top: 38px;
  }
}

.wrap {
  & :global {
    .ant-modal {
      min-width: 640px;
      width: auto !important;
      max-width: 1280px;
    }

    .ant-modal-title {
      max-width: 90%;
    }
  }
}
