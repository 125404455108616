@import 'theme/variables.scss';

.root {
  padding: 12px 12px;
  background-color: $color-white;
  position: relative;

  &.draft {
    background-color: rgba($color-brand-90, 0.5) !important;

    & > *:not(i) {
      opacity: 0.5;
    }
  }

  &.notSended {
    background-color: $color-red-90 !important;
  }

  .createdAt {
    position: absolute;
    bottom: 4px;
    right: 4px;
    line-height: 1;
    display: flex;
    align-items: center;
    gap: 4px;

    &.isLog {
      position: static;
      width: 100px;
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
    }
  }

  .clock,
  .warning {
    position: absolute;
    line-height: 1;
    bottom: 4px;
    right: 4px;
  }

  .attachmentsWrap {
    flex-direction: column-reverse;
  }

  .attachmentsWrapForLog {
    flex-direction: column-reverse;
    margin-right: -106px;
    margin-left: -10px;

    @media (max-width: 1705px) {
      margin-right: -99px;
    }

    @media (max-width: 1064px) {
      margin-right: -95px;
    }

    @media (max-width: 874px) {
      margin-right: -90px;
    }
  }

  .attachments {
    margin-top: 8px;
  }

  .content {
    margin-bottom: 6px;

    &.isLog {
      width: 100%;
      margin-bottom: 0;
    }
  }

  &.withStatusIcon {
    .createdAt {
      right: 22px;
    }
  }

  &.source {
    .content {
      margin-bottom: 0;
    }

    .createdAt {
      display: none;
    }
  }
}
