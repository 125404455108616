.form {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 16px;

  .submitBtn {
    margin-top: auto;
    margin-left: auto;
  }
}
