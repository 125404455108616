.root {
  height: 40px;
  max-width: 130px;
  margin: 0;

  & > li {
    border-radius: 4px;
    height: inherit !important;
  }
}
