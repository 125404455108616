@import 'theme/variables.scss';

.root {
  padding: 0;

  .icon {
    width: 23px;
    height: 23px;
    margin-right: 10px;
  }

  .textPrimary {
    color: $primary-color;
    margin-right: 10px;
  }

  .content {
    display: flex;
    align-items: center;
  }
}
