@import 'theme/variables.scss';

.root {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;

  .btn {
    background-color: $color-black-95;
    padding: 0 15px;
    border-radius: $border-radius-base;
    height: 26px;
    border: none;
  }

  :global .ant-btn {
    &:hover {
      color: inherit;
      background-color: rgba($color-black-55, 0.2);
    }

    &:focus {
      color: inherit;
    }
  }
}
