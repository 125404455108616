@import 'theme/variables.scss';

:root .popover {
  .btnCancel {
    color: $color-red-70;
    border-color: $color-red-70;

    &:hover {
      color: $color-red-70;
      border-color: $color-red-70;
    }
  }

  :global {
    z-index: 9999;

    .ant-popover-content {
      max-width: 202px;

      .ant-popover-inner-content {
        padding: 16px;
      }

      .ant-popover-message-title {
        font-weight: 600;
        font-size: 14px;
        padding-left: 0;
      }

      .ant-popover-buttons {
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 8px;

        .ant-btn {
          padding: 6px 15px;
          height: auto;
          margin: 0;
          flex: 1;
        }
      }
    }
  }
}
