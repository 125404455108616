.form {
  display: flex;
  flex-direction: column;
  height: 100%;

  .submitBtn {
    align-self: flex-end;
    margin-top: auto;
    flex-shrink: 0;
  }
}
