@import 'theme/variables.scss';

.root {
  margin-bottom: 10px;

  .wrapper {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-bottom: 6px;

    &.outdated {
      .icon,
      .text {
        color: $color-red-70;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .icon {
    margin-right: 8px;
    color: $color-brand;
  }

  .label {
    line-height: 14px;
    margin-right: 4px;
    color: $color-black-55;
  }

  .text {
    color: $text-color;
  }
}
