@import 'theme/variables.scss';

.root {
  padding: 13px 32px;
  width: 100%;
  box-shadow: 0px 1px 0px 0px $color-black-85;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .link {
    color: unset;

    &:hover {
      color: unset;
    }
  }

  .language {
    @media (min-width: 1100px) {
      position: fixed;
      bottom: 32px;
      right: 32px;
    }
  }
}
