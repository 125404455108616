@import 'theme/variables.scss';

.ant-tabs {
  &-tab {
    font-weight: 400;

    &:not(.ant-tabs-tab-active) {
      color: $color-black-55;
    }

    &-active {
      font-weight: 600;
    }
  }

  &.auth {
    .ant-tabs-bar {
      border-bottom: 0;
    }

    .ant-tabs-nav-scroll {
      display: flex;
    }

    .ant-tabs-nav {
      margin: 0 auto;
    }

    .ant-tabs-tab {
      color: rgba($text-color, 0.5);
      font-size: 24px;
      font-weight: bold;
      padding: 6px 12px;
    }

    .ant-tabs-tab:hover {
      color: rgba($text-color, 0.8);
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      color: $text-color;
    }

    .ant-tabs-ink-bar {
      height: 4px;
      border-radius: $border-radius-base;
      background: $gradient-base;
    }
  }

  .ant-tabs-tab {
    margin: $tabs-horizontal-margin;
    padding-left: 16px;
    padding-right: 16px;
  }
}
