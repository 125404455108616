.root {
  height: 100%;
  display: flex;
  flex-direction: column;

  .back {
    align-self: flex-start;
    font-size: 16px;
    margin-bottom: 24px;
    padding: 0;
    height: auto;
  }

  .form {
    display: flex;
    flex-direction: column;
    flex: 100%;
  }

  .datePickerWrapper {
    max-width: 100%;
  }

  .submit {
    margin: auto 0 0 auto;
  }
}
