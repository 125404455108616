@import 'theme/variables.scss';

.withDoubleClick {
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: $color-brand-95;
  }
}

.form {
  margin-bottom: 12px;

  .allActions {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;
  }

  .actionsBlock {
    display: flex;
    gap: 16px;
    margin-left: auto;
  }

  .button {
    height: auto;
    font-size: 16px;
  }
}

.field {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;

  .linkButton {
    padding: 0;
    border: unset;
    height: auto;
    font-weight: 400 !important;
  }
}
