@import 'theme/variables.scss';

$menu-item-height: 52px;

@mixin verticalCentring {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin selectedItem {
  color: $primary-color;
  border-left: 2px solid $color-brand-50;
  background-color: $color-brand-15 !important;
}

.dividerContainer {
  padding: 0 20px;
}

.badgeDot {
  & > sup {
    background-color: $color-red !important;
    box-shadow: none;
  }
}

.root {
  .menuItem {
    margin: 0;
    height: $menu-item-height;
    line-height: $menu-item-height;
    display: flex;
    align-items: center;
    border-left: 2px solid transparent;
    color: $white;

    .itemContent {
      display: flex;
      align-items: center;
      color: $color-black-85;
      transition: color 0.3s;

      & > :global(.ant-typography) {
        margin-left: 3px;
        max-width: 0;
      }

      &.withDot::after {
        content: '';
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        background-color: $color-red;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
      }
    }

    &:hover .itemContent {
      color: $white !important;
    }

    .mobileModalBtn {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      color: $color-black-85;

      span {
        margin-left: 3px;
      }
    }
  }

  .subMenuItem {
    display: flex;
    align-items: center;
  }

  :global {
    .anticon {
      font-size: 20px;
      min-width: 20px;
      margin-right: 10px;
    }

    .ant-menu-item-active {
      background-color: rgba($primary-color, 0.1);
    }

    .ant-menu-submenu.ant-menu-submenu-open {
      border-left: 2px solid $primary-color;
      background-color: rgba($primary-color, 0.2);
    }

    .ant-menu-submenu-open .ant-typography,
    .ant-menu-submenu-open .anticon {
      color: $primary-color;
    }

    .ant-menu-item-group,
    .ant-menu-submenu-title {
      position: relative;
    }

    .ant-menu-item .ant-typography,
    .ant-typography {
      color: $white;
    }

    .ant-menu-submenu {
      min-height: $menu-item-height;
      line-height: $menu-item-height;
      border-left: 2px solid transparent;
      color: $white;
    }

    .ant-menu-submenu .ant-menu {
      background-color: darken($text-color, 2%);
    }

    .ant-menu-submenu:hover,
    .ant-menu-item:hover {
      background-color: rgba($primary-color, 0.1);
    }

    .ant-menu-submenu .ant-menu-submenu-title,
    .ant-menu .ant-menu-submenu .ant-menu-submenu-title,
    .ant-menu-submenu .ant-menu-item {
      height: $menu-item-height;
      line-height: $menu-item-height;
      margin: 0;
      display: flex;
      align-items: center;
      padding: 0 20px;
    }

    .ant-menu-submenu.ant-menu-submenu-selected .ant-menu-submenu-title:hover {
      color: $primary-color;
    }

    .ant-menu-item:not(:last-child) {
      margin-bottom: 0;
    }

    .ant-menu-submenu.ant-menu-submenu-selected
      .ant-menu-submenu-title
      .ant-typography {
      color: $primary-color;
    }

    .ant-menu-submenu-selected,
    .ant-menu-item.ant-menu-item-selected {
      @include selectedItem;
    }

    .ant-menu-item.ant-menu-item-selected .ant-typography,
    .ant-menu-item.ant-menu-item-selected .anticon {
      color: $color-brand-50;
    }

    .ant-menu-sub .ant-menu-item.ant-menu-item-selected {
      background-color: $primary-color;
      color: $white;
    }

    .ant-menu-sub .ant-menu-item.ant-menu-item-selected .ant-typography {
      color: $white;
    }
  }
}

.tooltipMain {
  position: relative;
  z-index: 1100;
  left: 205px !important;

  :global {
    .ant-tooltip-inner {
      max-width: 171px;
      padding: 8px 12px;
    }
  }
}

.withMenuCollapsed {
  left: 70px !important;
}

.tooltipTitle {
  display: flex;
  flex-direction: column;

  .description {
    margin-bottom: 2px;
  }

  .btn {
    padding: 0;
    height: auto;
    align-self: end;

    span {
      text-decoration: underline;
    }
  }
}

:global {
  .ant-menu-inline-collapsed {
    width: 100%;

    .ant-menu-item,
    .ant-menu-submenu .ant-menu-submenu-title {
      padding: 0 5px !important;
    }

    .ant-menu-item {
      display: flex;
      justify-content: center;
    }
    .ant-menu-submenu .ant-menu-submenu-title .anticon,
    .ant-menu-item .anticon {
      font-size: 20px;
    }
  }

  .ant-menu-submenu-popup .ant-menu .ant-menu-item {
    padding-right: 38px;
  }
}

:global {
  .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: $text-color;
    color: $white;

    .ant-menu-sub {
      background-color: $text-color;
    }

    .ant-menu-submenu.ant-menu-submenu-selected {
      @include selectedItem;
    }

    .ant-typography {
      color: $white;
    }
  }
}

:global {
  .ant-menu-light {
    background-color: $white;
    color: $text-color;

    .ant-menu-submenu.ant-menu-submenu-selected {
      @include selectedItem;
    }

    .ant-menu-item .ant-typography,
    .ant-typography,
    .anticon {
      color: $text-color;
    }

    .ant-menu-submenu-arrow {
      background-color: $text-color;
    }

    .ant-menu-item.ant-menu-item-selected,
    .ant-menu-item.ant-menu-item-selected .anticon,
    .ant-menu-submenu.ant-menu-submenu-selected .anticon {
      color: $primary-color;
    }

    .ant-menu-sub {
      background-color: $white;
    }

    .ant-menu-sub.ant-menu {
      background-color: $color-black-95;
    }
  }
}
