.tooltip :global(.ant-tooltip-arrow) {
  top: 7px;
}

.button {
  padding: 8px;
  display: flex;
  align-items: center;
}

.buttonSmall {
  & > span {
    width: 16px;
    height: 16px;
  }
}

.icon {
  & > svg {
    width: 24px;
    height: 24px;
  }
}
