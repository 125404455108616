.root {
  .label {
    margin: 0 0 4px;
  }

  .input {
    margin: 0;
    height: 36px;

    & > li {
      height: inherit;
      border-radius: 4px;
    }
  }
}
