@import 'theme/variables.scss';

.buttonGroup {
  display: flex;
  gap: 8px;

  .radioButton {
    padding: 0 8px;
    border: 0 !important;
    border-radius: 4px;
    outline: none !important;
    box-shadow: none !important;

    &::before {
      content: none;
    }
  }

  & :global {
    .ant-radio-button-wrapper-checked {
      background-color: $color-brand-90 !important;
      color: $color-brand !important;
    }
  }
}
