@import 'theme/variables.scss';

.wrapper {
  border-radius: 8px;
  border: 1px solid $color-black-85;
  padding: 16px 24px;
  margin-bottom: 16px;
}

.btnSubmit {
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-left: auto;
}

.processModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 47px 24px 32px;

  .info {
    text-align: center;
  }

  .closeBtn {
    margin-top: 32px;
  }
}

.skeleton {
  margin-bottom: 16px;
  border-radius: 8px;
}

.successSection {
  text-align: center;
}
