.content {
  width: 100%;
  height: 100%;
  max-height: 665px;
  padding: 12px 0;
  display: flex;
}

.createModalContent {
  padding: 24px;
}

.tabs {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;

  :global {
    .ant-tabs-content {
      flex-grow: 1;
      height: 100%;
    }

    .ant-tabs-bar {
      margin: 0;
    }

    .ant-tabs-tabpane {
      height: 100%;
    }
  }
}
