@import 'theme/variables.scss';

.ant-select {
  width: 100%;

  .ant-select-selection {
    min-height: $height-base;
  }

  &.ant-select-multiple {
    .ant-select-selection-item {
      border-radius: 23px;
      background-color: $color-brand-90;
      border-color: transparent;
      gap: 10px;
    }
  }

  .ant-select-selection__choice__remove {
    right: 8px;
    font-size: 14px;
  }

  .ant-select-selection--single {
    height: $height-base;

    .ant-select-selection__rendered {
      margin-right: 30px;
    }
  }

  .ant-select-selection--multiple {
    .ant-select-selection__rendered {
      line-height: calc(#{$height-base} - 2px);
    }
  }
}

.ant-select {
  &.custom {
    min-width: 160px;

    .ant-select-selection {
      min-height: $height-base;
      border-radius: 20px;
      border-color: $primary-color;

      .ant-select-selection__placeholder {
        color: $primary-color;
      }
    }

    .ant-select-selection-selected-value {
      padding-right: 5px;
    }

    &.blue {
      .ant-select-selection {
        border-color: #038ab6;

        .ant-select-selection__placeholder {
          color: #038ab6;
        }
      }
    }
  }
}

.custom-ant-select-dropdown {
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: $color-brand-95;
    font-weight: 400;
  }
}
