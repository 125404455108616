@import 'theme/variables.scss';

.root {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;

  .prompt {
    border-radius: 4px;
    background-color: $color-black-95;
    width: 100%;
    padding: 4px 32px;
    text-align: center;
    cursor: pointer;
  }
}
