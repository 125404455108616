@import 'theme/variables.scss';

.tooltipTitle {
  display: flex;
  gap: 4px;
}

.root {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  margin-top: 8px;

  .reaction {
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 20px;
    border: 1px solid $color-black-85;
    background-color: $color-white;
    padding: 4px 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &.hasMyReaction {
      border-color: $color-brand;
      background-color: $color-brand-95;
    }

    &.firstAnimation {
      animation: firstAnimation 0.4s ease;
    }

    &.highlighting {
      animation: highlight 1s ease-in-out;
    }
  }

  :root & .count {
    sup {
      background-color: transparent;
      color: $color-brand-30;
      padding: 0;
      min-width: auto;
      height: 12px;
      line-height: 12px;
      border-radius: unset;
      box-shadow: unset;
    }
  }
}

@keyframes firstAnimation {
  0% {
    transform: scale(0);
  }

  70% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes highlight {
  25% {
    transform: scale(1.2) translateY(-4px);
  }

  50% {
    transform: scale(1.2) translateY(-4px);
  }

  60% {
    transform: translateY(0);
  }

  70% {
    transform: translateY(-2px);
  }

  80% {
    transform: translateY(0);
  }

  90% {
    transform: translateY(-1px);
  }

  100% {
    transform: translateY(0);
  }
}
