@import 'theme/variables.scss';

.root {
  display: flex;
  flex-direction: column;

  :global .ant-upload {
    width: 100%;
  }

  .title {
    margin-bottom: 16px;
    margin-top: 24px;
  }

  .input {
    margin-bottom: 20px;
  }

  .wrapperBtn {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  .submitBtn {
    width: 100%;
  }

  .uploadBtn {
    margin-bottom: 4px;
  }

  .logoInfo {
    text-align: center;
  }

  .notify {
    margin-top: 24px;
    margin-bottom: 0;
  }

  .modalCodeCopy {
    margin-bottom: 20px;

    textarea {
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 30px !important;
      line-height: 1.8;
      padding-top: 5px;
      color: $color-black;
    }

  }

  .appearance {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .languageSelect {
      text-transform: capitalize;
    }
  }
}
