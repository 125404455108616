.root {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  .select {
    border: none;
    background-color: transparent;
    min-height: auto;
    padding: 0 4px;
  }

  .overlay {
    min-width: min-content;
  }
}
