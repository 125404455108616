.form {
  height: 100%;
  display: flex;
  flex-direction: column;

  .datePicker {
    max-width: unset;
  }

  .submit {
    margin: auto 0 0 auto;
    flex-shrink: 0;
  }
}
