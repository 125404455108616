@import 'theme/variables.scss';

.root {
  overflow: hidden;

  .messages {
    background-color: transparent;
    padding: 0 14px 0 0;
    border-right: 1px solid $color-black-85;
    height: 100%;
  }

  .date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color-black-55;
    padding: 14px 0 22px;

    &:first-child {
      padding-top: 0;
    }
  }

  .messageWrap {
    padding-bottom: 16px;

    & ~ .date {
      padding-top: 0;
    }
  }
}

.skeleton {
  background-color: transparent;
  padding: 16px 0 0;
  overflow: inherit;
  height: unset;
  width: unset;

  & > div {
    max-width: unset !important;
  }
}
