@import 'theme/variables.scss';

.root {
  display: flex;
  align-items: center;
  gap: 16px;

  .radio {
    display: flex;
    margin: 0 0 0 8px;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
  }

  .message {
    display: flex;
    width: calc(100% - 48px);
    flex-direction: column;
    gap: 8px;
    background-color: $color-brand-95;
    border: 1px solid $color-black-85;
    border-radius: 8px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

    &.isRead {
      background-color: $color-black-95;
    }

    .header {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .headerRightSide {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-left: auto;
    }

    .reminderContent {
      padding: 8px;
      width: 100%;
      background-color: $color-white;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .sourceWrap {
      cursor: default;
      position: relative;
    }

    .sourceWithControls {
      margin-bottom: 8px;
    }

    .controls {
      border-radius: 4px;
    }

    .skeletonControls {
      max-width: unset;
      padding: 8px;
      background-color: $color-white;
      height: 128px;
      border-radius: 4px;

      & > li {
        height: 100% !important;
      }
    }

    .collapseButton {
      font-size: 16px;
      display: block;
      margin: auto;
      font-weight: 400 !important;
    }
  }

  .tabs {
    position: absolute;
    z-index: 99;
    width: 100%;
    background-color: $color-white;

    :global {
      .ant-tabs-nav {
        margin-bottom: 0;
      }
    }
  }

  .openNewTabBtn {
    height: auto;
    padding: 0;
    border: 0;
    display: flex;
    line-height: 0;
  }

  .badgeWrap {
    display: flex;
    align-items: center;
  }

  .badge {
    & > sup {
      min-width: 15px;
      height: 15px;
      line-height: 15px;
      font-size: 10px;
      font-weight: bold;
      padding: 0 3px;
      border-radius: 50%;
    }
  }
}

.mentionWrap {
  z-index: 1000 !important;
}

.extraTabContentWrap {
  display: flex;
  align-items: center;
  gap: 4px;
}

.closeBtn {
  padding: 8px;
  display: flex;
  align-items: center;
}
