.ant-popover-arrow {
  display: none;
}

.ant-popover {
  padding-top: 0;
  padding-bottom: 0;
}

.ant-popover-inner-content {
  padding: 10px;
}

.ant-popover-buttons {
  display: flex;
  flex-shrink: 0;
}
