@import 'theme/variables.scss';

.dropdownContent {
  width: 100%;
  min-width: 195px;
}

.dropdownBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  flex-shrink: 0;
  border-radius: 50%;
  margin-right: 0;
  margin-left: 5px;
  height: 20px;
  width: 20px;
  padding-right: 4px;
  padding-left: 4px;
}

.actionBtn {
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 8px;

  & > span {
    margin-left: 0 !important;
  }
}
