.btn {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & > button:disabled {
    padding: 0;
  }
}
