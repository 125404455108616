@import 'theme/variables.scss';

.add {
  display: inline-flex;
  align-items: center;
  padding: 0 4px;
  min-height: 24px;
  cursor: pointer;
  border: 1px dashed $color-brand;
  border-radius: 4px;

  &.short {
    padding: 0 3px;
  }
}

.search {
  padding: 0 16px;
  margin-bottom: 24px;

  & :global {
    .ant-input-prefix {
      left: 26px;
    }

    .ant-input-suffix {
      right: 26px;
    }
  }
}

.create {
  display: flex;
  align-items: center;
  margin: 0 !important;
  cursor: pointer;
  padding: 0 16px;
}

.dropdownOverlay {
  outline: none;
  background-color: $color-white;
  padding: 16px 0;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  & > span {
    margin-left: 16px;
    margin-right: 16px;
    width: fit-content;
  }
}

.creatableTag {
  padding: 0;
  margin-left: 16px;
}

.option {
  cursor: pointer;
  padding: 0 16px;

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
}

.active {
  background-color: $color-brand-95;
}

.colorItem {
  margin-bottom: 0;
  padding: 0 16px;
}

.tags {
  max-height: 240px;
  overflow: auto;
}

.skeleton {
  padding: 0 16px;
}
