.form {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > div > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  & > button {
    align-self: flex-end;
    font-size: 16px;
  }
}
