.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  max-width: 308px;
  width: 100%;
  text-align: center;

  .icon {
    margin-bottom: 16px;
  }
}
