.title {
  margin-bottom: 8px !important;
}

.actionWrap {
  width: 100%;
  max-width: 190px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
