@import 'theme/variables.scss';

.root {
  padding: 0;
  position: relative;
  border-radius: 10px;
  margin: -20px;

  &:global(.ant-notification-notice) {
    box-shadow: none;
    background-color: transparent;
  }

  .card {
    background-color: $color-brand-95;
    margin: 20px;
    font-size: 14px;
    border-radius: 4px;
    transition: 1s ease-in-out;
    box-shadow: $shadow-3;

    &.opacity {
      opacity: 0.4;
    }
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      color: $color-brand;
    }

    &:hover {
      background-color: rgba($primary-color, 0.08);

      .icon {
        color: $link-hover-color;
      }
    }
  }

  .text {
    font-size: 12px;
    line-height: 1;
    color: $color-black-35;
  }

  .body {
    flex-wrap: wrap;
    padding: 12px 10px 12px 12px;
  }

  .content {
    flex: 1 1 auto;
    margin-top: 4px;
    padding-right: 12px;
    min-height: 71px;
    cursor: pointer;

    & > span > div {
      display: inline; //multi clamp Safari fix
    }
  }

  .pagination {
    width: 100%;
  }

  .arrowNavContainer {
    display: flex;
    align-items: center;

    .arrowNav:not(:disabled) {
      color: $color-brand !important;

      &:hover {
        color: $link-hover-color !important;
      }
    }
  }

  .icon {
    color: $text-color;
  }

  .flexStart {
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
  }

  .flexWrap {
    display: flex;
    align-items: center;
  }

  .justifyBetween {
    justify-content: space-between;
  }

  :global {
    .ant-notification-notice-message,
    .ant-notification-notice-close {
      display: none;
    }
  }
}
