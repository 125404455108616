.root {
  display: flex;

  .datePicker {
    min-width: 240px;
  }

  .interval {
    display: flex;
    margin-left: 24px;
    max-width: 240px;

    .count {
      border-radius: 4px 0px 0px 4px;
    }

    .frequency {
      margin-top: 20px;
      min-width: 90px;
      border-left: none;
      border-radius: 0px 4px 4px 0;
    }
  }
}
