.root {
  padding-inline: 12px;
  transform: scaleY(0);
  overflow: hidden;
  height: 0;
  transition: all 0.2s ease;
  transform-origin: bottom;

  &.visible {
    transform: scaleY(1);
    height: auto;
    overflow: unset;
    padding: 12px;
  }

  .title {
    margin-bottom: 16px;
  }

  .prompts {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }
}
