.form {
  display: flex;
  flex-direction: column;
  height: 100%;

  .videoBtn {
    margin-bottom: 16px;
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    margin-bottom: 20px;
  }

  .employeeList {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
  }

  .employee {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .info {
      display: flex;
      align-items: center;
      width: 95%;
    }

    .avatar {
      margin-right: 8px;
    }

    .deleteBtn {
      height: auto;
    }
  }

  .submitBtn {
    align-self: flex-end;
    margin-top: auto;
    flex-shrink: 0;
  }
}
