@import 'theme/variables.scss';

.modalContent {
  padding: 24px;
}

.contentBody {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.warningSection {
  background: $color-yellow-30;
  border-radius: 4px;
  border: 1px solid $color-yellow;
  padding: 8px 16px;
  margin-bottom: 16px;
}

.formButtons {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 24px;
}
