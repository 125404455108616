@import 'theme/variables.scss';

.ant-legacy-form {
  .ant-legacy-form-item-control {
    &.has-error {
      input {
        border-color: $color-red-70;
      }

      .ant-legacy-form-explain {
        color: #f5120a;
      }
    }
  }

  .ant-legacy-form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }

  .ant-form-item-row {
    display: flex;
    flex-direction: column;
  }

  .ant-form-item-label {
    text-align: left;
    line-height: 20px;

    label {
      font-size: 12px;
      color: $color-black-55;
      height: auto;

      &:after {
        display: none;
      }
    }
  }

  .ant-legacy-form-item-label {
    line-height: 20px;

    label {
      font-size: 12px;
      color: $color-black-55;
    }
  }

  .ant-select-selection {
    border-color: $color-black-65;
  }

  .ant-legacy-form-item-children {
    & > div {
      width: 100%;

      .ant-select-selector {
        border-color: $color-black-65;
      }
    }
  }
}

.ant-legacy-form-explain {
  font-size: 12px;
}
