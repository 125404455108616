@import 'theme/variables.scss';

.root {
  display: flex;
  align-items: center;
  margin: 0;
  width: 100%;

  &.withBtn {
    width: calc(100% - 12px);
  }

  &.tag {
    width: auto;
    border-radius: 23px;
    min-height: 27px;
    font-size: 14px;
    background-color: $white;
    padding: 0 8px;

    &.small {
      max-width: 252px;
      background-color: $color-brand-90;
    }

    &.square {
      border: none;
      border-radius: $border-radius-base;
      height: 23px;
      max-width: 190px;
      padding: 2px 0px;

      :global {
        .ant-tag {
          margin-right: 0;
          padding-right: 0;
        }
      }
    }
  }

  .btnRemove {
    border-radius: 50%;
    margin-right: 0;
    margin-left: 5px;
    height: 20px;
    width: 20px;
    padding-right: 4px;
    padding-left: 4px;
  }

  .squareRemove {
    background-color: transparent;
    margin-left: 0;

    &:hover {
      background-color: transparent;
      color: $color-red-70;
    }
  }
}
