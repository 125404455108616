@import 'theme/variables.scss';

.ant-btn {
  &.ant-btn-primary {
    box-shadow: 0px 5px 10px rgba(22, 150, 156, 0.2);
    padding: 0 15px;

    &:hover {
      border-color: $color-brand-30;
      background-color: $color-brand-30;
    }

    &:disabled {
      box-shadow: none;
      border-color: $color-black-75;
      background-color: $color-black-75;
      color: $color-white;
    }
  }

  &.ant-btn-danger {
    box-shadow: 0px 5px 10px rgba(249, 113, 108, 0.2);

    &:hover {
      border-color: $color-red-60;
      background-color: $color-red-60;
    }

    &:disabled {
      box-shadow: none;
      border-color: $color-black-75;
      background-color: $color-black-75;
      color: $color-white;
    }
  }

  &.ant-btn-ghost {
    color: $color-brand;
    border-color: $color-brand;
    background-color: transparent;

    &:hover {
      border-color: $color-brand-30;
      color: $color-brand-30;
    }

    &:disabled {
      border-color: $color-black-75;
      color: $color-black-75;
    }
  }

  &.ant-btn-background-ghost {
    &:disabled {
      color: $color-black-75;
    }
  }

  &.ant-btn-link {
    font-weight: 600;
    color: $color-brand;

    &:hover {
      color: $color-brand-30;
    }

    &:disabled {
      color: $color-black-65;
    }
  }

  &.ant-btn-text {
    font-weight: 400;
    border-color: transparent;
    background-color: transparent;
    color: $color-black-55;
    box-shadow: none;

    &:hover {
      color: $color-black-65;
    }

    &:disabled {
      color: $color-black-75 !important;
    }
  }

  &.tab {
    position: relative;
    display: inline-block;
    color: $color-black-55;
    font-size: 24px;
    border: 0;
    font-weight: 700;
    padding: 0;
    box-shadow: none;
    height: auto;

    @media (max-width: $screen-sm) {
      font-size: 16px;
      font-weight: 600;
    }

    span {
      padding: 6px 16px;
    }

    span::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background: transparent;
      transform-origin: 50% 50%;
      transition: 0.2s ease-in-out;
    }

    &:hover {
      color: $color-black;
    }

    &.active {
      color: $color-black;
    }

    &.active span::before {
      width: 100%;
      background: $gradient-base;
    }
  }

  &.noshadow {
    box-shadow: none;
  }
}
