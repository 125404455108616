.content {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .count {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }

  .input {
    max-width: 220px;
  }
}
