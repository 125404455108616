.modal {
  padding: 24px;

  .input {
    margin-bottom: 24px;
  }

  .button {
    display: block;
    margin-left: auto;
  }
}
