@import 'theme/variables.scss';

// converters events
.event {
  display: flex;

  &.link {
    cursor: pointer;

    &:hover {
      color: $color-brand-90;
    }
  }
}

.eventText {
  color: $color-black-55;
  margin-right: 5px;
}

.eventIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.taskIcon {
  margin: 0 8px;
}

.orderIcon {
  width: 23px;
  margin: 0 8px;
}

.ideaIcon {
  margin: 0 8px;
}

.id {
  margin-right: 8px;
  color: $color-brand;
}
