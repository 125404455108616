@import 'theme/variables.scss';

.btnCopy {
  padding-right: 0;
  color: $color-brand !important;
}

.btnCopyActive {
  color: $color-black-75 !important;
}

.attachments {
  max-height: 150px;
  overflow: auto;
  background-color: $color-white;
  padding: 10px 8px;
}

.toolbarSubmit {
  padding: 0;
  height: auto;
  border: 0;
}

.mentionList {
  position: absolute;
  min-height: 40px;
  max-height: 220px;
  left: 0;
  bottom: calc(100% + 8px);
  border-radius: 8px;
  background-color: $color-white;
  width: 100%;
  z-index: 1000;
  overflow-y: auto;
  box-shadow: $shadow-3;

  & > div {
    display: flex;
    flex-direction: column;
  }
}

.mentionItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 16px;

  &.active {
    background-color: $color-black-95 !important;
  }

  &:hover {
    background-color: $color-black-98;
  }

  .avatarName {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.skeletonMention {
  padding: 8px 16px;
}

.parentMessage {
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  background-color: $color-white;

  &.isMessageContent {
    padding: 0;
    margin-bottom: 8px;
    background-color: transparent;

    .parentMessageInfo {
      width: 100%;
    }
  }

  .parentMessageInfo {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 80%;
    padding-left: 8px;
    cursor: pointer;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 2px;
      height: 100%;
      background-color: $color-brand;
      transition: background-color 0.3s ease-in-out;
      border-radius: 5px;
    }

    &:hover {
      &::after {
        background-color: $color-brand-30;
      }
    }
  }

  .parentMessageHeader {
    display: flex;

    * {
      margin: 0;
      font-size: 12px !important;
      color: $color-black !important;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .parentMessageContent {
    width: 100%;

    &,
    * {
      font-size: 12px !important;
      color: $color-black-55 !important;
    }
  }

  .parentMessageDeleteBtn {
    display: flex;
    height: auto;
    padding: 0;
    border: unset;
  }
}

.subject {
  border-radius: 8px 8px 0 0;
  box-shadow: none;
}

.subject input {
  border-radius: 8px 8px 0 0;
  border: none !important;
  border-bottom: 1px solid $color-black-75 !important;
  padding-right: 70px !important;

  &:focus,
  &:active,
  &:hover {
    border-color: $color-black-75 !important;
    outline: none;
    box-shadow: none;
  }
}
