@import 'theme/variables.scss';

.ant-upload.ant-upload-drag {
  background-color: transparent;

  .anticon,
  .ant-upload:hover .anticon {
    color: $primary-color;
  }

  .ant-upload-drag,
  .ant-upload-drag:hover {
    border: 1px solid transparent;
  }
}
