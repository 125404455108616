@import 'theme/variables.scss';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 9px 56px;

  .text {
    margin: 0;
  }

  .action {
    text-decoration: underline;
    flex-shrink: 0;
    cursor: pointer;
  }

  .closeBtn {
    position: absolute;
    right: 34px;
    height: auto;
    padding: 0;
    border: unset;
    color: $color-white !important;
  }
}
