@import 'theme/variables.scss';

.root {
  width: 100%;
  line-height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: $zindex-header;
  background: $layout-header-background;
  border-bottom: 1px solid #e9e9e9;
  padding: 0 16px 0 20px;
}
