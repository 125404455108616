@import 'theme/variables.scss';

.ant-alert-warning {
  background-color: $color-yellow-30;
  border-color: $color-yellow;
}

.ant-alert-error {
  background-color: $color-red-90;
  border-color: $color-red-45;
}
