.form {
  display: flex;
  flex-direction: column;

  .input {
    margin-bottom: 24px;
  }

  .button {
    margin-left: auto;
  }
}
