.root {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .item {
    flex-wrap: nowrap;
  }

  .detailsField {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
