.root {
  margin-bottom: 4px;
}

.channelIcon {
  transform: translate(50%, -50%) !important;

  svg {
    border-radius: 50%;
  }
}
