@import 'theme/variables.scss';

.root {
  margin-top: 15px;

  &:hover {
    border: 1px dashed $color-black-55;
  }

  &:global(.ant-upload-drag:not(.ant-upload-disabled):hover) {
    border-color: $primary-color;
  }

  &:global(.ant-upload) {
    background: $white;
    border: 1px dashed $color-black-55;
  }

  &:global(.ant-upload-drag) {
    height: calc(100% - 15px);
  }

  :global(.ant-upload-btn) {
    background-color: $white;
  }

  .title {
    max-width: 228px;
    margin: 0 auto;
  }
}
