@import 'theme/variables.scss';

.root {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .wrap {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .detailsTariffsBtn {
    font-size: 16px;
    align-self: center;
  }
}
