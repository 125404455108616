@import 'theme/variables.scss';

.star {
  color: $color-black-85;
  cursor: pointer;
  transition: 0.1s ease-in-out;

  &:not(:last-child) {
    padding-right: 12px;
  }

  &.hovered {
    color: $color-yellow;
  }

  &.checkedHovered {
    color: #e49402;
  }
}

.disabledStars {
  pointer-events: none;
  opacity: 0.3;
}
