@import 'theme/variables.scss';

.root {
  display: flex;
  align-items: center;
  margin-right: 8px;

  &.rootWithScheduler {
    margin-right: 2px;
  }

  &.link {
    color: $primary-color;
    cursor: pointer;
  }

  .id {
    display: inline;
    white-space: nowrap;
  }

  .icon {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    align-items: center;
    display: flex;
  }
}
