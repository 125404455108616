@import 'theme/variables.scss';

.root {
  line-height: 26px;
  text-align: left;

  .inputGroup {
    margin-bottom: 8px;
    display: flex;

    & > *::after,
    & > *::before {
      display: none;
    }

    .countrySelect {
      height: 100%;
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    .companySelect {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .ellipsis {
      flex: 1 1 auto;
      width: 50%;
    }
  }

  .wrap {
    margin-top: 16px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .error,
  .info {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2px;
  }

  .findOut {
    margin-top: 8px;
  }

  .errorRed {
    color: $color-red-70;
    margin-top: -12px;
  }

  .smallText {
    line-height: 16px;
    font-size: 12px;
    color: rgba($text-color, 0.6);
  }

  .title {
    margin-right: 10px;
    line-height: 20px;
  }
}
