.root {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .button {
    display: flex;
    flex-direction: column;
    padding: 32px 16px;
    height: 100%;
    width: 100%;
    max-width: 273px;
    border: none;
  }

  .icon,
  .titleWrap {
    margin-bottom: 8px;
  }

  .title {
    margin-bottom: 0;
  }
}
