@import 'theme/variables.scss';

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      margin-bottom: 0;
    }
  }

  .notification {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background-color: $color-white;
    border-radius: 4px;
    border: 1px solid $color-black-85;

    .sender {
      display: flex;
      align-items: center;
      gap: 8px;
      max-width: 250px;
      width: 30%;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .actions {
      width: max-content;

      & > div {
        display: flex;
        gap: 8px;
      }
    }

    .actionBtn {
      font-size: 16px;

      &::after {
        content: '';
      }
    }

    .createdAt {
      margin-left: auto;
      flex-shrink: 0;
    }
  }
}

.skeleton {
  max-width: unset;
  margin-bottom: 16px;
}

.empty {
  margin: auto !important;
}
