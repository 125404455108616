@import 'theme/variables.scss';

@mixin overlayDropdown {
  max-height: calc(100vh - 52px - 15px); // heightHeader + margin
  overflow-y: auto;
}

@mixin calendarCommonStyles {
  :global {
    .fc {
      .fc-header-toolbar {
        .fc-toolbar-chunk:nth-child(1) {
          display: flex;
          align-items: center;
        }

        .fc-button-group {
          margin-left: 24px;
        }

        .fc-toolbar-title {
          font-weight: 600;
          font-size: 16px;
          color: $color-black;
          margin-left: 24px;
        }

        .fc-button {
          font-weight: 600;
          font-size: 16px;
          background-color: $color-white;
          border: 1px solid $color-brand;
          color: $color-brand;
          padding: 6px 16px;
          box-shadow: none !important;

          &-active {
            background-color: $color-brand;
            color: $color-white;
          }
        }

        .fc-prev-button,
        .fc-next-button {
          display: flex;
          align-items: center;
          padding: 6px;
          color: $color-black-55;
        }
      }

      .fc-highlight {
        background-color: $color-black-65;
        opacity: 0.3;
      }

      .fc-timegrid-now-indicator-arrow,
      .fc-timeline-now-indicator-arrow {
        left: unset;
        right: 0;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $color-brand;
        border: unset;
      }

      .fc-timeline-now-indicator-arrow {
        top: unset;
        bottom: 0;
      }

      .fc-timegrid-now-indicator-line {
        border-color: $color-brand;
        border-width: 2px 0 0;
      }

      .fc-timeline-now-indicator-line {
        border-color: $color-brand;
        border-width: 0 0 0 2px;
      }

      // y-axis times
      .fc-timegrid-axis {
        border: unset;
        background-color: transparent !important;
      }

      // all calendar border (left, top)
      .fc-scrollgrid {
        border: unset;
      }

      // time slot inner
      .fc-timegrid-slot-label-cushion {
        padding: 0 15px 0 0;
      }

      // time slot
      .fc-timegrid-slot {
        border-left: unset;
        height: 32px;
      }

      // time slot wrap
      .fc-timegrid-slot-label {
        border: unset;
      }

      // event area lines
      tr:nth-child(2n) > .fc-timegrid-slot-lane {
        border-top: unset;
      }

      // event wrap
      .fc-event-main {
        padding: 0;
      }

      // header cell
      .fc-col-header-cell-cushion {
        display: block;
        padding: 8px;
        cursor: default;
        width: 100%;
      }
    }
  }
}
