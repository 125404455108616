@import 'theme/variables.scss';

.mention {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  width: 428px;
  background: $white;
  border-radius: $border-radius-base;
  box-shadow: $shadow-3;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;

  .mentionItem {
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    transition: 0.3s;
    cursor: pointer;
    width: 100%;

    &.aiAssistant {
      background-color: rgba($color-pink-light, 0.3);
    }

    &:hover {
      background: $color-brand-95;
    }

    &.active {
      background: $color-brand-90;
    }
  }

  .root {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .rootReplaced {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 214px;
  }

  .filter {
    opacity: 0.5;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .mentionItemHeader {
    display: flex;
    flex-direction: column;
  }

  .mentionEmployee {
    justify-content: space-between;
  }

  .mentionTypeTitle {
    padding: 8px 16px;
  }

  .skeletonTitle {
    margin: 8px;
    height: 40px;
  }
}
