@import 'theme/variables.scss';

.root {
  min-height: 200px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 20px 25px;

  .infoWrap {
    flex: 1;
  }

  .submit {
    margin: auto 0 0 auto;
    flex-shrink: 0;
    width: 172px;
    font-size: 16px;
  }
}
