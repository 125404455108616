@import 'theme/variables.scss';

.root {
  position: absolute;
  z-index: 10000000;
  right: 0;
  top: 50%;
  background-color: white;
  padding: 8px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.noSelect {
  user-select: none;
}
