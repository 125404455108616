@import 'theme/variables.scss';

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  flex: 100%;
}

.textarea {
  resize: none;
}

.attachDocuments {
  border: 1px solid $color-brand !important;
}

.divider {
  width: 100%;
  margin: 16px 0 0;
}

.actionsButton {
  align-self: flex-start;
}

.submitButton {
  margin-top: auto;
  margin-left: auto;
}

.plusIcon {
  display: flex;
  align-items: center;
  padding: 0;
}

.footerWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}
