@import 'theme/variables.scss';

.form {
  height: 100%;
  display: flex;
  flex-direction: column;

  .hint {
    font-size: 12px;
    opacity: 0.4;
    margin-top: -12px;
    margin-bottom: 12px;
    font-weight: 500;
  }

  .btn {
    margin: auto 0 0 auto;
    flex-shrink: 0;
  }

  .item {
    :global {
      .ant-legacy-form-item-label {
        margin-bottom: 5px;
      }
    }
  }

  .flexText {
    display: flex;
    align-items: flex-start;
  }

  .btnLeft {
    padding-left: 0;
    margin-top: 50px;
    font-weight: 600;
    font-size: 14px;
  }

  .iconRed {
    width: 19px;
    height: 19px;
    color: $color-red-70;
    flex-shrink: 0;
    margin-right: 10px;
  }

  label {
    font-size: 12px;
  }
}

.table {
  :global {
    .ant-table-content {
      .ant-table-body {
        margin: 0;
      }
    }
  }
}

.rejected {
  font-size: 12px;
  line-height: 16px;
  background-color: rgba($primary-color, 0.2);
  margin: -25px -25px 25px -25px;
  height: auto;
}
