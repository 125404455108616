@import 'theme/variables.scss';

.issues {
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: calc(100vh - 140px);

  .reason {
    padding: 6px 16px;
    background-color: $color-black-95;
    font-weight: 600;
  }

  .statusInfo {
    display: flex;
    gap: 16px;
    align-items: center;

    .id {
      display: flex;
      align-items: center;
    }
  }

  .title {
    margin: 0;
  }

  .createdAt {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}

.wrapLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .loader {
    height: 150px;
    width: 150px;
  }
}

.divider {
  margin: 0;
}

.errorWrap {
  display: flex;
  height: 100vh;
}
