@import 'theme/variables.scss';

.reminderBtn {
  width: auto;
  padding: 0;
}

.form {
  .actions {
    display: flex;
    gap: 8px;
  }

  .deleteBtn {
    padding: 0;
    height: auto;
    margin-top: 16px;
  }

  .input {
    max-width: 219px;
  }

  .textarea {
    min-height: 36px !important;
  }
}

.reminderContainer {
  display: flex;
  align-items: center;
  gap: 8px;

  .text {
    cursor: pointer;
  }
}

.popover {
  & :global {
    .ant-popover-inner-content {
      padding: 16px !important;
    }
  }
}
