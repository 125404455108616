.root {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.skeleton {
  height: 184px;
  max-width: unset;
}
