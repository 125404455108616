@import 'theme/variables.scss';

@mixin elipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.root {
  display: flex;
  align-items: center;
  padding: 5px 0;
  flex-shrink: 0;

  .avatar {
    margin-right: 10px;
    flex-shrink: 0;
    border: 1px solid rgba($text-color, 0.1);
  }

  .dropdownTrigger {
    margin-left: 10px;

    &:hover {
      background-color: $color-brand-90;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-weight: normal;
    font-size: 12px;
    max-width: 400px;
  }

  .link {
    @include elipsis;
    color: $text-color;
    cursor: pointer;
    margin-bottom: 5px;

    &:hover {
      color: $primary-color;
    }
  }

  .individual {
    margin-bottom: 5px;
    color: $text-color;
  }
}

.skeleton {
  display: flex;
  padding-right: 10px;

  &:global.ant-skeleton-active {
    .ant-skeleton-header {
      padding-right: 0;
    }

    .ant-skeleton-content {
      min-width: 110px;
      margin-left: 10px;
    }

    .ant-skeleton-header .ant-skeleton-avatar {
      border-radius: 50%;
    }

    .ant-skeleton-content .ant-skeleton-title {
      border-radius: $border-radius-base;
    }
  }
}
