@import 'theme/variables.scss';

.root {
  position: relative;
  margin-bottom: 16px;

  .textarea {
    resize: none;
    background-color: $color-black-95;
    border: 1px solid $color-black-65;
    padding: 10px 20px;
    padding-right: 120px;

    &[disabled]:hover {
      border: 1px solid $color-black-65;
    }
  }

  .btnCopy {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 2;
  }
}
