@import 'theme/variables.scss';

.root {
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
    height: 9px;
    width: 8px;
  }
}
