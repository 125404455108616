@import 'theme/variables.scss';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;

  .title {
    display: block;
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .fieldsWrap {
    padding: 0 25px;
  }

  .dates {
    display: flex;
  }

  .btns {
    display: flex;
    justify-content: space-between;
    padding: 0 25px 25px;
  }

  .addSprint {
    padding: 0;
    min-width: 130px;
  }

  .btnSubmit {
    margin-left: auto;
    padding: 0 33px;
  }

  .filter {
    height: $height-base;
    margin-bottom: 10px;
    width: 100%;
    background-color: white;
    border: 1px solid $color-black-65;
  }

  .popover {
    background-color: white;
    box-shadow: $box-shadow-base;
  }

  .description {
    display: block;
    margin: 16px 0;
    line-height: 1.58;
  }

  .filterRoot {
    width: 100%;

    :global {
      .ant-popover {
        width: 488px;
      }
    }
  }
}
