.root {
  display: flex;
  gap: 4px;
  align-items: center;

  .pageSizeSelect {
    border: none;
    min-height: 32px;
  }
}

.simpleRoot {
  display: flex;
  align-items: center;
  gap: 16px;

  .arrowBtn {
    padding: 0;
    border: none;
    display: flex;
  }
}
