@import 'theme/variables.scss';

:root .formItem {
  margin-bottom: 32px !important;

  :global {
    .ant-legacy-form-explain {
      position: absolute;
      top: 196px;
    }
  }
}

.rightLine {
  margin-right: 12px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    right: -8px;
    top: 50%;
    transform: translateY(-50%);
    height: 22px;
    width: 1px;
    background-color: $border-color-base;
    z-index: 2;
  }
}

:global {
  .DraftEditor-root {
    overflow: auto;
  }
}

.attachmets {
  margin-top: 20px;
}

.upload {
  color: $color-black-55;
  display: flex;
  align-items: center;
  height: auto;
  padding: 0;
  font-size: 12px;

  &:hover,
  &:focus {
    color: $color-black-65;
  }
}

.editorWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: $height-base;
  border: 1px solid $color-black-65;
  border-radius: $border-radius-base;

  &.disabled {
    opacity: 0.6;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }
  }
}

.toolbarClassName {
  background-color: $color-black-98;
  border-bottom: 0;
  margin: 0;
  font-size: 12px;
  flex-direction: row-reverse;
  justify-content: flex-end;

  &.withoutAttach {
    :global {
      .rdw-block-wrapper {
        margin-left: 0;

        &::before {
          content: none;
        }
      }
    }
  }

  :global {
    .rdw-colorpicker-wrapper,
    .rdw-dropdown-wrapper,
    .rdw-link-wrapper .rdw-option-wrapper,
    .rdw-embedded-wrapper,
    .rdw-emoji-wrapper,
    .rdw-image-wrapper,
    .rdw-history-wrapper .rdw-option-wrapper {
      background-color: transparent;
      border: 0;
      border-radius: $border-radius-base;
      transition: 0.2s ease-in-out;

      &:hover {
        background-color: rgba($color-black-55, 0.2);
        box-shadow: none;
      }
    }

    .rdw-block-wrapper,
    .rdw-link-wrapper,
    .rdw-emoji-wrapper,
    .rdw-history-wrapper {
      margin-bottom: 0;
    }

    .rdw-block-wrapper {
      margin-left: 16px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: -6px;
        top: 50%;
        transform: translateY(-50%);
        height: 22px;
        width: 1px;
        background-color: $border-color-base;
        z-index: 2;
      }
    }
  }

  :global(.rdw-dropdown-selectedtext) {
    position: relative;
    color: $color-black-55;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 20px;
    display: block;
    padding: 5px 15px 5px 2px;
  }

  :global {
    .rdw-option-wrapper,
    .rdw-option-wrapper:hover {
      border: 0;
      box-shadow: none;
      background-color: transparent;
    }
  }

  :global {
    .rdw-inline-dropdown {
      width: 32px;
    }

    .rdw-list-dropdown {
      width: 40px;
    }

    .rdw-link-dropdown {
      width: 40px;
    }
  }

  :global(.rdw-dropdown-optionwrapper) {
    box-shadow: 0 2px 6px rgba($text-color, 0.15);
    overflow-y: auto;
  }

  :global {
    .rdw-dropdown-carettoclose,
    .rdw-dropdown-carettoopen {
      border: solid $color-black-55;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 3px;
      transition: 0.2s ease-in-out;
      transform-origin: center;
      right: 4px;
      top: calc(50% - 5px);
    }

    .rdw-dropdown-carettoopen {
      transform: rotate(45deg);
    }

    .rdw-dropdown-carettoclose {
      transform: rotate(-135deg) translate(-3px, -3px);
    }
  }

  :global {
    .rdw-colorpicker-modal,
    .rdw-emoji-modal,
    .rdw-link-modal {
      transform: translateX(-50%);
      padding: 10px;
    }

    .rdw-colorpicker-modal {
      height: auto;
    }

    .rdw-emoji-modal {
      width: 214px;
    }

    .rdw-link-modal .rdw-link-modal-input,
    .rdw-link-modal .rdw-link-modal-target-option {
      margin-bottom: 8px;
    }

    .rdw-colorpicker-modal .rdw-colorpicker-modal-options {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  :global .rdw-colorpicker-modal-options {
    overflow: auto;
  }

  :global {
    .rdw-colorpicker-cube {
      border: 1px solid $color-black-95;
      border-radius: $border-radius-base;
    }

    .rdw-colorpicker-cube:hover {
      border-color: $color-black-55;
    }
  }

  :global(.rdw-emoji-wrapper) {
    @extend .rightLine;
  }

  :global {
    .rdw-emoji-wrapper .rdw-option-wrapper,
    .rdw-history-wrapper .rdw-option-wrapper,
    .rdw-colorpicker-wrapper .rdw-option-wrapper {
      margin: 0;
      height: 100%;
    }

    .rdw-link-modal {
      height: auto;
    }
  }
}

.editorClassName {
  padding: 0px 10px 5px;
  max-height: 250px;
  min-height: 150px;
  overflow: auto;
  line-height: 1.35;
  color: $text-color;

  :global(.public-DraftStyleDefault-block) {
    margin: 8px 0;
  }

  :global(.public-DraftEditorPlaceholder-root) {
    margin-top: 8px;
  }

  :global(
    .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before
  ) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    left: -25px;
  }
}

:global(.ant-legacy-form-item-control) {
  line-height: 1.7;
}
