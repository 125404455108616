@import 'theme/variables.scss';

.body {
  display: flex;
  overflow: auto;
  height: 100%;

  :global(.with-bottom-banner-spacer) ~ div & {
    padding-bottom: $bottom-banner-height;
  }
}

.spin {
  display: flex;
  margin: 0 auto;
  align-items: center;
}

.text {
  margin-top: 30px;
  text-align: center;
}

.block {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  flex: 1 1 50%;
}

.blockWidth {
  flex-grow: 0;
}

.collapsePanel {
  padding-bottom: 13px;
}

.userRole {
  margin-bottom: 14px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  overflow: hidden;

  .label {
    font-size: 12px;
    color: $label-color;
    margin-bottom: 2px;
  }
}

.addUserRoleButton {
  display: flex;
  align-items: center;
  padding: 0;
  margin-right: auto;
}

.header {
  padding: 0 15px;
}

.content {
  padding: 10px 0;
  padding-right: 0;
  border-right: 1px solid rgba($color-black-55, 0.3);

  .titleWrap {
    margin-bottom: 12px;
  }

  .title {
    margin: 0;

    &.outdated {
      color: $color-red-70;
    }
  }

  .btnFooter {
    display: flex;
    align-items: center;
    margin-right: 30px;
    padding-left: 0;
    color: $text-color;

    .icon {
      margin-right: 0px;
    }

    &:hover {
      color: $primary-color;
    }
  }

  .footerContainer {
    display: flex;
    align-items: center;
  }

  .link {
    padding-left: 0;
    font-weight: bold;
    text-align: left;
  }

  .createOrderButton {
    display: flex;
    align-items: center;
    align-self: flex-start;
    padding: 10px 0;
    height: unset;
    color: $label-color;
  }

  .badge {
    margin-right: 10px;
  }

  .dates {
    margin-bottom: 0;
  }

  .location {
    margin-bottom: 12px;
  }

  .completeRequire {
    margin-bottom: 12px;
  }

  .description {
    margin: 12px 0 4px;
  }
}

.flex {
  display: flex;

  .label {
    margin-right: 4px;
  }
}

.flexAuto {
  display: flex;
  flex: 1 1;
  overflow: auto;
  padding-bottom: 10px;
}

.flexOverflow {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1 0;
  padding: 0 15px;

  & > * {
    flex-shrink: 0;
  }

  & > :global(.ant-divider) {
    margin: 10px 0;
  }

  .tagsHeader {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tagsTitle {
      margin-bottom: 0;
    }
  }

  .tagList {
    text-align: left;
  }
}

.empty {
  margin-bottom: 20px;
  align-self: center;
  justify-self: center;
  margin: 0 auto;
}

.alert {
  margin: 12px 0;
}

.reminder {
  margin-bottom: 12px;
}
