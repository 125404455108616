@import 'theme/variables.scss';

.table {
  min-height: 135px;
  overflow: hidden;
  margin-bottom: 24px;

  :root & :global {
    .ant-table-thead > tr > th .ant-table-column-title {
      color: $color-black;
      font-size: 12px;
      font-weight: 600;
    }

    .ant-table .ant-table-thead > tr > th {
      padding: 16px 8px 8px;
    }

    .ant-table .ant-table-tbody > tr > td {
      padding: 8px 8px 16px !important;
      font-size: 14px;
      color: $color-black;
    }

    .ant-spin-nested-loading,
    .ant-spin-container,
    .ant-table,
    .ant-table-content,
    .ant-table-scroll {
      height: 100%;
      overflow: hidden;
    }

    .ant-table-body {
      height: calc(100% - 44px);
    }

    .ant-table-placeholder {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: none;
    }
  }

  .suspended {
    color: $color-red-70;
  }
}
