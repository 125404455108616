@import 'theme/variables.scss';

.form {
  display: flex;
  flex-direction: column;
  flex: auto;

  .alert {
    margin-bottom: 24px;
  }
}

.employeeSelectsWrap,
.user {
  display: grid;
  grid-template-columns: 368px 1fr;
  gap: 16px;
}

.contactSelectsWrap {
  display: grid;
  grid-template-columns: 228px 124px 1fr;
  gap: 16px;
}

.datePicker {
  min-width: auto;
}

.user {
  align-items: center;
  margin-bottom: 16px;
}

.delete {
  color: $color-red !important;
}

.button {
  margin: auto 0 0 auto;
}

.actions {
  cursor: pointer;
  text-align: end;
}

.employeeSelect {
  padding: 1px 4px;
}

.modal {
  padding: 24px;

  .modalForm {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .modalDatePicker {
    max-width: unset;
    width: 100%;
  }

  .modalSubmit {
    font-size: 16px;
    align-self: flex-end;
  }
}
