@import 'theme/variables.scss';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 24px 32px;
  gap: 20px;
  text-align: center;

  .title {
    margin: 0;
  }

  .description {
    margin: 0;
    text-align: center;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;

    .btnCancel {
      font-weight: 600 !important;
      color: $color-red-70 !important;
    }
  }
}
