@import 'theme/variables.scss';

.modalContent {
  padding: 60px 24px 32px 24px;
}

.table {
  :global {
    .ant-table-thead
      > tr
      > th
      .ant-table-header-column
      .ant-table-column-sorters::before:hover {
      background-color: transparent;
    }

    .ant-table-column-title {
      text-transform: uppercase;
    }
  }
}

.creator {
  span:nth-child(2) {
    color: $color-brand;
    font-size: 14px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .contentPagination {
    margin-top: 4px;
    gap: 8px;
  }
}

.actionsBtn {
  margin: auto;
}
