@import 'theme/variables.scss';

.root {
  flex: auto;
  display: flex;
  flex-direction: column;
  height: calc(100% - 110px);

  .admin {
    padding: 0;
  }

  .emptyWrap {
    display: flex;
    height: 100%;
  }

  .adminName {
    font-weight: normal;
    font-size: 14px;
    word-break: break-word;
  }

  .notConnectedRow {
    background-color: $color-black-95;
  }

  .pointer {
    cursor: pointer;
  }

  .trash {
    height: auto;
    padding: 0;
    border: none;
    background: transparent;
  }

  .kind {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 4px;

      svg {
        border-radius: 50%;
      }
    }
  }

  .switchWrapError {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

:root .table {
  flex: auto;

  :global {
    .ant-table .ant-table-column-title {
      text-transform: uppercase;
    }
  }
}
