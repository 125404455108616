@import 'theme/variables.scss';

.btn {
  padding: 0;
  display: flex;
  align-items: center;
  height: unset;
  margin: 0;

  & > span {
    font-weight: 600 !important;
  }
}
