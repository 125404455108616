@import 'theme/variables.scss';

.root {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.tag {
  cursor: default;
}

.arrowWrap {
  display: flex;
}

.arrow {
  align-items: center;
  cursor: pointer;
}

.addAnimation {
  animation: add 0.5s ease-in-out;
}

.dropdown {
  background-color: $color-white;
  padding: 16px;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  max-height: 240px;
  overflow: auto;

  div:not(:last-child) {
    margin-bottom: 12px;
  }
}

@keyframes add {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-2px);
  }
  20% {
    transform: translateY(-5px) rotate(15deg);
  }
  40% {
    transform: translateY(-5px) rotate(-15deg);
  }
  60% {
    transform: translateY(-5px) rotate(15deg);
  }
  80% {
    transform: translateY(-5px) rotate(-15deg);
  }
  90% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0);
  }
}
