@import 'theme/variables.scss';

.root {
  padding: 10px;
  cursor: pointer;

  .row {
    margin-bottom: 0;
    flex-grow: 1;
  }

  .btn {
    color: $primary-color;
    margin-right: 10px;
  }
}
