@import 'theme/variables.scss';

.root {
  padding-top: 6px;
  height: 100%;

  :global .ant-tabs-nav {
    margin-left: 20px;
    margin-right: 20px;
  }

  .tab {
    overflow-y: auto;
    padding: 0 20px 20px;
  }

  .title {
    font-size: 14px;
    line-height: 1.1875;
    font-weight: normal;
    display: block;
  }

  .subTitle {
    grid-column-start: 1;
    grid-column-end: 3;
    margin-top: $size-8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;

    &:first-of-type {
      margin-top: $size-24px;
    }
  }

  .label {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
    margin-bottom: $size-8px;
  }

  .description {
    font-size: 12px;
    font-weight: 400;
    white-space: normal;
    word-break: break-word;
    text-align: start;
    color: $color-black-25;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $size-16px;

    .block {
      display: flex;
      flex-direction: column;
      min-height: 138px;
      height: auto;
      padding: 16px;
      border-radius: $border-radius-base;
      border: none;

      .icon {
        margin-bottom: $size-8px;
      }

      span {
        margin-left: 0;
      }

      &:hover {
        box-shadow: $box-shadow-base;
        border-color: transparent;
      }
    }

    .disabledBlock {
      filter: grayscale(100%);
    }
  }

  .tabs {
    height: 100%;

    :global .ant-tabs-content {
      max-height: calc(100% - 60px);
    }
  }

  .alertSla {
    margin: 16px 0 24px;
  }
}
