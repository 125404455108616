.attachaments {
  margin-bottom: 16px;
  margin-top: 16px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
}
