.root {
  padding: 0 16px 8px;
  flex-shrink: 0;

  .statuses {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    &.odd {
      .btnAction:first-child {
        flex: 1 1 100%;
        max-width: unset;
      }
    }
  }

  .btnAction {
    flex: 1 1 auto;
    max-width: 50%;
    white-space: unset;
    min-height: 40px;
    height: auto;
  }

  :global(> .ant-divider) {
    margin: 0 0 15px;
  }
}
