@import 'theme/variables.scss';

.root {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .button {
    height: 28px;
    display: flex;
    align-items: center;
  }

  .count {
    margin-left: 8px;
    width: 16px;
    height: 16px;
    background: $color-black-85;
    border-radius: 12px;
    font-weight: 600;
    font-size: 12px;
    color: $color-black-35;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
