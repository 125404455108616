@import 'theme/variables.scss';

.root {
  :global {
    .ant-select-arrow {
      margin-top: -8px;
    }

    .ant-select-selection {
      border-color: $color-black-65;

      &:hover {
        border-color: $color-brand;
      }
    }
  }
}
