@import 'theme/variables.scss';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;

  .service {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    background-color: $color-brand-90;
    margin: -24px -24px 24px;

    .title,
    .serviceSelect {
      margin-bottom: 0;
    }

    .serviceSelect {
      min-width: 240px;
      margin-bottom: 0;
    }

    .serviceOption {
      display: flex;
      align-items: center;

      span {
        margin-left: 8px;
      }
    }
  }

  .portWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    .sslItem {
      & > span {
        visibility: hidden;
      }

      .ssl {
        height: 36px;
        display: flex;
        align-items: center;
      }
    }
  }

  .instructionContainer {
    display: flex;
    align-items: center;
    gap: 2px;
    margin-bottom: 16px;

    .instructionIconWrap {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $color-yellow;
    }
  }

  .instructionBtn {
    height: auto;
    padding: 0;
    line-height: 1;
    text-align: left;
    color: $color-brand-20 !important;
    font-size: 16px;
  }

  .btn {
    width: 100%;
  }
}
