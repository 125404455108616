@import 'theme/variables.scss';

.panelHeader {
  display: flex;
  align-items: center;
  gap: 8px;
}

.collapse {
  & :global {
    .ant-collapse-item {
      width: 100%;
      border: none;
    }

    .ant-collapse-header {
      width: fit-content;
      margin: 0 auto;
      border: 1px solid transparent;
      border-radius: 10px !important;
      padding-top: 4px !important;
      padding-bottom: 4px !important;

      .ant-collapse-arrow {
        transform: translateY(-50%);
      }
    }

    .ant-collapse-content-box {
      padding: 0 !important;
    }
  }

  .collapseTitle {
    margin-bottom: 0 !important;
  }

  .arrowUp {
    & > svg {
      transform: rotate(-180deg);
    }
  }
}

.date {
  font-size: 12px;
  padding: 4px 0;

  &:first-of-type {
    padding-top: 0 !important;
  }
}
