@import 'theme/variables.scss';

$animation-duration: 0.3s;
$animation-easing: ease-out;

@mixin animate-slide($from: 'top') {
  opacity: 0;

  @if $from == 'top' {
    top: 0;
    animation: fadeInSlideTop $animation-duration $animation-easing forwards;
  } @else if $from == 'bottom' {
    bottom: 0;
    animation: fadeInSlideBottom $animation-duration $animation-easing forwards;
  }
}

@keyframes fadeInSlideTop {
  0% {
    opacity: 0;
    top: 0;
  }
  100% {
    opacity: 1;
    top: 32px;
  }
}

@keyframes fadeInSlideBottom {
  0% {
    opacity: 0;
    bottom: 0;
  }
  100% {
    opacity: 1;
    bottom: 32px;
  }
}

.root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .spinWrap {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: $color-white;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid $color-black-85;
    z-index: 999;
    @include animate-slide('bottom');

    & > div {
      display: flex;
    }

    &.isReverse {
      @include animate-slide('top');
      bottom: unset;
    }
  }

  .scroll {
    display: flex;
    flex-direction: column;
    flex: auto;
  }

  .messages {
    background-color: $color-white;
    padding: 14px 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }

  .skeleton {
    padding: 0;
    overflow: inherit;
    height: unset;
    width: unset;
  }
}
