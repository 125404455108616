@import 'theme/variables.scss';

.content {
  display: flex;
  flex-direction: column;
  height: 100%;

  .relationsSelect {
    & label {
      &::after {
        display: none;
      }
    }
  }

  .subTitle {
    font-weight: bold;
    font-size: 16px;
  }

  .fieldsWrap {
    padding: 25px;

    :global {
      .ant-switch {
        margin: 0;
      }
    }

    .upload {
      :global .ant-btn-link {
        color: rgba($color-black-55, 0.6);

        &:hover {
          color: $color-brand-90;
        }
      }

      svg {
        stroke: rgba($white, 0.6);
      }
    }
  }

  .letterKind {
    width: 240px;
    margin-top: 10px;
  }

  .uploadBlock {
    width: 100%;

    :global div.ant-upload {
      width: 200px;
      padding: 5px 0;
      margin-left: auto;
      background: transparent;
      border: none;
    }
  }

  .editorTools {
    display: flex;
    justify-content: flex-end;
    position: relative;
    align-items: center;
    margin-top: -60px;
  }

  .upload {
    justify-content: flex-start;
    margin-bottom: 15px;

    :global .ant-upload-drag-container {
      align-items: flex-start;
      justify-content: flex-end;
      display: flex;

      .ant-btn-link {
        background-color: rgba($white, 0.9);
        margin-right: 21px;
      }
    }
  }

  .field,
  .dateStart {
    position: relative;

    .btnDelete {
      width: 20px;
      height: 20px;
      position: absolute;
      right: -22px;
      top: 30px;
      opacity: 0;
      transition: 0.2s ease-in-out;

      & > i {
        display: flex;
      }
    }

    &:hover {
      .btnDelete {
        opacity: 1;
      }
    }
  }

  .dateStart {
    display: flex;
    margin-right: 14px;
    padding-right: 25px;

    .btnDelete {
      right: 2px;
    }
  }

  .field {
    :global {
      .ant-legacy-form-item {
        width: 100%;
      }
    }
  }

  .dates {
    display: flex;

    .datePicker {
      min-width: 130px;
      max-width: 170px;
    }
  }

  .subTasks {
    .subTask {
      display: flex;
      align-items: center;
      .subTaskItem {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;

        div:first-child {
          width: 100%;
        }

        .subTaskResponsible {
          position: absolute;
          right: 5px;
        }
      }

      .btnDelete {
        margin-bottom: 15px;
      }
    }
  }

  .timeset {
    display: flex;

    :global {
      .ant-row {
        width: 72px;
        margin-right: 15px;
      }
    }
  }

  .divider {
    margin: 0;
    background: rgba($primary-color, 0.2);
    height: 2px;
  }

  .btnDropdown {
    padding: 8px 0;
    margin-right: auto;
    margin-left: 20px;

    button {
      font-weight: bold;
    }

    .plus {
      position: absolute;
      left: -5px;
      top: 10px;
    }
  }

  .dropdownOverlay {
    left: 20px;
    margin-left: 30px;
  }

  .btnSteps {
    margin: auto 25px 25px auto;
  }

  .typeTaskSelect {
    background-color: rgba($primary-color, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;

    :global .ant-row {
      &.ant-legacy-form-item {
        margin-bottom: 0;
      }
    }
  }

  .select {
    width: 200px;
  }

  .subtask {
    position: relative;

    .subtaskResponsible {
      position: absolute;
      top: 2px;
      right: 8px;
    }

    .btnDelete {
      position: absolute;
      right: -24px;
      top: 0;
    }
  }

  .flexWrap {
    display: flex;
    margin-top: 10px;

    .item {
      margin-bottom: 6px;
    }

    .location {
      flex-grow: 1;
      flex-basis: 50%;
      margin-right: 20px;
    }
  }

  .visibleCalendarBtn {
    width: 100%;
    margin-bottom: 16px;
  }

  .slaAlert {
    margin-bottom: 16px;
  }
}

.changingSchedulerFrom {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > div > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  & > button {
    align-self: flex-end;
    font-size: 16px;
  }
}

.mentionWrap {
  z-index: 1000;
}
