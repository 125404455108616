.root {
  height: 100%;
  display: flex;
  flex-direction: column;

  .m24 {
    margin-bottom: 24px;  
  }

  .m0 {
    margin-bottom: 0;
  }

  .button {
    width: 100%;
  }
}