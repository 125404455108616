@import 'theme/variables.scss';

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 35px 15px;
  overflow: hidden;
  margin: auto;

  .text {
    width: 100%;
    max-width: 316px;
    margin: 27px auto 0;
    text-align: center;
  }
}

.logoSvg {
  width: 160px;
  margin: 0 auto;
}

.links {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 50px auto 0;
}

.link {
  position: relative;
  display: flex;
  width: 168px;

  & + .link {
    margin-left: 12px;
  }
}

.backgroundImg {
  width: 100%;
  max-width: 240px;
  margin: 62px auto 0;
}

.icon > svg {
  max-width: 100%;
}
