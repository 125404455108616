.root {
  display: flex;

  .country {
    height: 100%;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-top: 0;
    padding-bottom: 0;
    max-width: 200px;
  }

  .title {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
