.root {
  display: flex;
  flex-direction: row;

  .titleWrap {
    display: flex;
    gap: 8px;
    margin: 0 4px 0 0;
  }

  .icon {
    margin-top: 1px;
  }
}
