@import 'theme/variables.scss';

.root {
  position: relative;
  margin-bottom: 18px;

  .title {
    margin-bottom: 0;
  }

  .btn {
    position: absolute;
    right: 0;
    background: none;
    border: none;
    opacity: 0.8;
    padding: 0;
    min-width: 26px;
    height: auto;
    display: flex;
    font-size: 20px;

    button {
      padding: 0;
    }

    &:hover {
      opacity: 1;
    }
  }

  .tooltip {
    right: -10px !important;
    left: unset !important;
    width: 170px;
    white-space: normal;
    font-weight: 400;
    cursor: default;
  }

  .tooltipTitle {
    display: flex;
    flex-direction: column;
  }

  .tooltipButton {
    margin-top: 4px;
    align-self: flex-end;
    text-decoration: underline;
    cursor: pointer;
  }

  :global {
    .ant-collapse-header {
      margin-bottom: 12px;
    }
  }
}
