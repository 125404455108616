@import 'theme/variables.scss';

@keyframes hideBackground {
  from {
    background-color: #89e5e9;
  }
}

.searchedMessage {
  animation: hideBackground 5s;
}

@keyframes hideBorder {
  from {
    border-color: #89e5e9;
    background-color: #89e5e9;
  }
}

.searchedMessageInHistoryAction {
  animation: hideBorder 5s;
}
