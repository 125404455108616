.joining {
  font-size: 14px;
}

.link {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}
