.root {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .addons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .buttons {
    display: flex;
    gap: 24px;
    align-items: center;
    align-self: flex-end;
  }

  .button {
    font-size: 16px;
  }
}
