@import 'theme/variables.scss';

.root {
  width: 100%;
  height: $height-base;
  border: 1px solid $color-black-65;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    border: 1px solid $color-black-65;
    color: $text-color;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.8;
    box-shadow: none;
    border-color: transparent;
    background-color: $color-black-95;
    color: rgba($text-color, 0.45);

    &:hover,
    &:focus {
      color: rgba($text-color, 0.45);
    }
  }
}

.colorBlock {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: $primary-color;
  flex-shrink: 0;
}

.popover {
  &::before {
    content: '';
    position: absolute;
    top: 6px;
    left: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid white;
  }

  :global(.ant-popover-inner) {
    box-shadow: 0px 10px 25px rgba(35, 23, 5, 0.2);
    border-radius: 12px;
    padding: 6px;
  }
}

.colorPicker {
  :global {
    .react-colorful {
      width: 100%;
      height: 100%;
    }

    .react-colorful__saturation {
      margin-bottom: 16px;
      border-bottom: none;
      border-radius: $border-radius-base * 2;
      width: 275px;
      height: 238px;
    }

    .react-colorful__last-control {
      border-radius: 24px;
      height: 12px;
      margin-bottom: 24px;
    }

    .react-colorful__pointer {
      width: 20px;
      height: 20px;
      border: 3px solid white;
      cursor: pointer;
    }
  }
}
