.ant-modal-content {
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s ease-in-out;
    border-radius: $border-radius-base;
    width: 56px;
    height: 56px;

    &:hover {
      background-color: rgba($color-black-55, 0.1);
    }
  }

  .ant-modal-title {
    font-size: 20px;
    font-weight: 600;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-confirm-btns {
    display: none;
  }
}
