@import 'theme/variables.scss';

.ant-tooltip {
  .ant-tooltip-inner {
    font-size: 12px;
    background-color: $color-black-15;

    .ant-typography,
    span {
      font-size: 12px;
      color: $color-white;
    }
  }
}

.ant-tooltip-arrow {
  & > span::before {
    background-color: $color-black-15;
  }
}
