@import 'theme/variables.scss';

.card {
  border: 1px solid $color-black-85;
  border-radius: $border-radius-base;
  padding: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;

  &.active {
    background-color: $color-brand-90;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -8px;

      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid $color-brand-90;

      z-index: 1;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -11px;

      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-left: 10px solid rgba($primary-color, 0.4);

      z-index: 0;
    }
  }

  .head {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    flex-wrap: wrap;
  }

  .outdated {
    color: $color-red-70;
  }

  .users {
    margin-left: auto;

    .user {
      margin-left: 8px;

      &:first-child {
        margin-left: 0px;
      }
    }
  }
}
