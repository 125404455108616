@import 'theme/variables.scss';

.tabs {
  overflow-y: auto;
  padding: 15px 25px;
  height: 100%;

  .tab {
    align-items: left;
  }

  .image {
    :global(.ant-empty-image) {
      margin-bottom: 16px;
    }

    svg {
      width: 105px !important;
      height: 105px !important;
    }
  }

  :global {
    .ant-tabs-nav {
      .ant-tabs-tab-active {
        color: $text-color;
      }
    }
  }
}
