@import 'theme/variables.scss';

.react-datepicker {
  display: flex;
  font-family: inherit;
  z-index: 100;

  .react-datepicker__navigation {
    top: 9px;
  }

  .react-datepicker__navigation--next--with-time {
    right: 90px;
  }
}

.react-datepicker-popper {
  z-index: 111;
}

.react-datepicker__header--time {
  padding: 0;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  color: $text-color;
  font-size: 12px;
  line-height: $height-base;
  text-transform: uppercase;
}

.react-datepicker__header {
  background: $white;
  padding-top: 0;
  border-bottom: $border-color-base;
}

.react-datepicker__day-names {
  background: lighten($primary-color, 45%);
}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  background: $primary-color;
  color: $white;
  outline: none;
}

.react-datepicker__time-list-item {
  line-height: 30px;
  height: 30px;
  padding: 0px 10px;
}

.react-datepicker__time-container {
  border-color: $color-black-65;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  padding: 0 10px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-weight: normal;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: $primary-color;
}
