.replaceWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 12px;
  width: 160px;
  align-items: flex-start;
  flex: 0 1 auto;
  overflow: hidden;
}

.userWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}
