.alertMessage {
  text-align: left;
  margin-bottom: 0 !important;

  ul {
    margin-bottom: 0;

    li {
      list-style-type: disc;
    }
  }
}
