@import 'theme/variables.scss';

$bg: lighten($primary-color, 45%);
$bgHover: darken($color-black-95, 10%);

$border-color: #a3a5a8;

.rootWrap {
  position: relative;
  display: flex;
  flex-direction: column;

  .label {
    font-size: 12px;
    color: $color-black-55;
  }

  .optionList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    margin: auto;
    gap: 4px;

    .optionItem {
      cursor: default;
    }

    .optionItem:last-child {
      margin-right: 0;
    }
  }

  .optionItem {
    margin: 2px 4px 2px 0;

    &.fullWidth {
      max-width: 100%;
    }
  }
}

.root {
  border: 1px solid $border-color;
  background-color: white;
  color: $color-black-55;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  border-radius: $border-radius-base;
  max-height: 120px;
  overflow: hidden auto;

  &.default {
    min-height: $height-base;
  }

  &.small {
    min-height: 28px;
  }

  &:hover,
  &:focus,
  &:active {
    border-color: $primary-color;
  }

  & ~ .optionList {
    margin-top: 4px;
  }

  &:global(.ant-popover-open .anticon) {
    transform: rotate(0);
  }

  & > :global(.anticon) {
    margin-left: auto !important;
  }

  &.disabled {
    background-color: $color-black-95;
    cursor: not-allowed;

    &:hover,
    &:focus {
      background-color: $color-black-95;
      border-color: $color-black-65;
    }

    & > i {
      display: none !important;
    }
  }

  .label {
    color: $text-color;
    font-weight: bold;
    margin-right: 5px;
  }

  &.badge {
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: $color-brand;
      border: 2px solid $color-white;
      border-radius: 50%;
      right: -4px;
      top: 0;
    }
  }
}

.popoverOverlay {
  width: 100% !important;
  min-width: 200px;
  transform-origin: 50% 0px;
  background-color: white;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: $border-radius-base;
  border-bottom-right-radius: $border-radius-base;

  :global(.ant-input-affix-wrapper) {
    padding: 0 10px;
  }

  :global(.ant-popover-inner) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: none;
    background-color: transparent;
  }

  :global(.ant-popover-inner .ant-popover-inner-content) {
    padding: 0;
    width: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    box-shadow: $box-shadow-base;
  }

  .list {
    :global(.ant-checkbox-inner) {
      width: 18px;
      height: 18px;
    }

    :global(.ant-checkbox-inner::after) {
      width: 6px;
      height: 12px;
    }

    :global(.ant-checkbox) {
      display: none;
      top: 0;
    }

    :global(.ant-checkbox + span) {
      width: 100%;
      padding: 0;
      overflow: hidden;
    }

    .option {
      display: flex;
      align-items: center;
      margin: 0;
      min-height: 40px;
      width: 100%;
      transition: 0.2s ease-in-out;
      padding: 4px 10px;
      background-color: white;
      color: $primary-color;

      &:hover {
        background-color: $color-brand-90;
      }

      &.multiple :global(.ant-checkbox) {
        display: block;

        + span {
          padding-right: 8px;
          padding-left: 8px;
          flex-grow: 1;
        }
      }

      &.withPosition {
        align-items: flex-start;

        & :global(.ant-checkbox) {
          padding-top: 10px;
        }
      }

      &.disabled {
        background-color: $color-black-95 !important;

        span {
          color: $color-black-55 !important;
        }
      }

      .icon {
        margin-top: 6px;
        margin-right: 5px;
      }
    }

    .option:hover {
      background-color: #e9fbfc;
      color: $text-color;
    }

    .option:global(.ant-checkbox-wrapper-checked) {
      background-color: #e9fbfc;
    }

    :global(.ant-spin.ant-spin-spinning) {
      display: flex;
      justify-content: center;
    }
  }

  .search {
    margin: 10px;
    width: auto;

    :global(.ant-input-prefix) {
      left: 20px;
    }

    & > input {
      min-height: 36px !important;
    }
  }

  .empty {
    text-align: left;
    padding: 5px 10px;
  }
}
.showSelectedOptionSeparatelyList {
  .optionSeparately {
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 10px 6px 10px 0;
    min-width: 100%;
    background: none !important;
    border-radius: 0 !important;
    margin: 0;
    font-size: 14px;

    &:last-child {
      padding-bottom: 0px;
    }
  }
}

.flexWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.addButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  font-weight: 400 !important;
}
