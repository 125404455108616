@import 'theme/variables.scss';

.itemContent {
  display: flex;
  align-items: center;

  .avatar {
    margin-right: 5px;
  }

  .titleWrap {
    overflow: hidden;
  }

  .title {
    width: 100%;
  }

  .additionalInfo {
    width: 100%;
    line-height: 1;
    white-space: normal;
  }

  &.isArchived {
    .avatar {
      opacity: 0.5;
    }

    .title {
      opacity: 0.5;
    }
  }

  &.isExpired {
    .avatar {
      opacity: 0.5;
    }
  }

  &.isBlocked {
    .title {
      opacity: 0.5;
    }

    cursor: not-allowed;
  }
}

.dropdownOverlay {
  :global(.ant-dropdown-menu) {
    border-radius: 10px 0 10px 10px;
    max-height: 478px;
    overflow: auto;
    min-width: 138px;
  }

  :global(.ant-dropdown-menu-item) {
    display: block;
  }
}

.worksapce {
  margin-right: 15px;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 100%;
  border-bottom: 2px solid transparent;

  &.active {
    border-bottom: 2px solid $primary-color;
  }
}

.notification {
  height: 100%;

  &:last-child .worksapce {
    margin-right: 15px;
  }
}

.btn {
  color: $color-black-55;
  border-color: transparent;
  box-shadow: none;

  &:hover {
    background-color: $color-black-95;
    border-color: transparent;
    color: $color-black-55;
  }
}

.notification {
  sup {
    color: $color-red-70;
  }
}
