@import 'theme/variables.scss';

.root {
  padding: 12px;
  border-radius: $border-radius-base;
  border: 1px solid $color-black-85;
  background-color: $color-white;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  & :global {
    .ant-skeleton-paragraph {
      margin: 0;
    }

    .ant-skeleton-content .ant-skeleton-paragraph > li + li {
      margin-top: 4px;
      width: 20% !important;
    }
  }
}
