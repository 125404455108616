@import 'theme/variables.scss';

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;
  padding-bottom: 16px;

  .meMessageWrap {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 8px;
    align-self: flex-end;
    max-width: 80%;
  }

  .meMessage {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: $color-brand-90;
    padding: 10px 12px;
    word-break: break-word;
  }

  .agentMessageWrap {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-right: auto;

    .agentMessage {
      border-top-right-radius: 8px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      background-color: $color-black-95;
      padding: 10px 12px;
      word-break: break-word;
      max-width: 70%;
    }

    .copyContent {
      align-self: flex-start;
    }
  }

  .username {
    margin-bottom: 8px;
  }

  .time {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
    margin-top: 4px;
  }

  .agentTyping {
    display: flex;
    gap: 4px;

    .dots {
      height: 14px;
      display: flex;
      align-items: center;
      animation: blink 1s infinite;
    }

    @keyframes blink {
      0% {
        opacity: 0.2;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.2;
      }
    }
  }
}

.copyTooltip {
  z-index: 9999;
}
