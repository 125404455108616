@import 'theme/variables.scss';

.avatar {
  margin-right: 10px;
}

.member {
  display: flex;
  align-items: center;

  :first-child {
    border: none;
  }
}

.btn {
  box-shadow: none;
  margin-right: -10px;
  border: none;
  color: $text-color;
}
