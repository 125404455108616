.root {
  display: flex;
  flex-direction: column;
  text-align: left;

  .day {
    text-transform: uppercase;
  }

  .number {
    margin: 0 !important;
  }
}
