.title {
  margin-bottom: 0 !important;
}

.infoButtonsWrap {
  margin-top: 12px;
}

.tagsHeader {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .tagsTitle {
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 700;
  }
}

.tagList {
  margin-bottom: 20px;
}

.infoButtons {
  margin-bottom: 12px;
}

.handbook {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 12px;

  .wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .link {
    max-width: 50%;
    display: block;
  }
}

.chat {
  flex: 100%;
  padding: 16px;
  overflow: hidden;
}

.reminder {
  margin-bottom: 16px;
}

.subscription {
  margin-left: 16px;
}

.validityExpirationWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
