@import 'theme/variables.scss';

.root {
  :global(.ant-modal-header) {
    border-bottom: none;
  }

  .modalCodeCopy {
    margin-bottom: 20px;

    textarea {
      border-color: $color-brand;
      background-color: $color-brand-90;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 40px !important;
      line-height: 1.8;
      padding-top: 8px;
      color: $color-black;
    }

    button {
      bottom: 6px;
    }
  }

  .textareaWrap {
    position: relative;

  
    .textarea {
      resize: none;
      padding: 10px 20px;
      padding-right: 120px;
      padding-top: 8px;
      border-color: $color-brand;
      background-color: $color-brand-90;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 40px !important;
      line-height: 1.7;
      color: $color-black;
    }
  
    .btnCopy {
      position: absolute;
      bottom: 8px;
      right: 10px;
      z-index: 2;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    max-width: 300px;
    margin: 0 auto;

    button {
      width: 100%;
    }
  }
}
