@import 'theme/variables.scss';

.root {
  padding-inline: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  background: $color-white;

  .wave {
    display: flex;
    align-items: center;
    gap: 3px;
  }

  .dot {
    width: 3px;
    height: 3px;
    background-color: $color-black-75;
    border-radius: 50%;
    animation: wave 1.1s linear infinite;

    &:nth-child(2) {
      animation-delay: -0.9s;
    }

    &:nth-child(3) {
      animation-delay: -0.7s;
    }
  }
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-5px);
  }
}
