@import 'theme/variables.scss';

.root {
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;

  .content {
    display: flex;
    flex-direction: column;

    .textPrimary {
      color: $primary-color;
    }
  }

  .channelIcon {
    transform: translate(50%, -50%) !important;

    svg {
      border-radius: 50%;
    }
  }
}
