@import 'theme/variables.scss';

.ant-drawer {
  .ant-drawer-header {
    border-bottom-color: $color-brand-45;
    padding: 0 24px;
  }

  .ant-drawer-title {
    width: 100%;
    display: flex;
    align-items: center;

    .ant-typography {
      margin-bottom: 0;
    }
  }

  .ant-drawer-wrapper-body {
    display: flex;
    flex-direction: column;
  }

  .ant-drawer-body {
    overflow: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
}

.ant-drawer-close {
  height: 100%;
  line-height: inherit;
}
