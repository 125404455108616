@import 'theme/variables.scss';

.root {
  position: relative;
  flex-shrink: 0;
  height: 100%;
  overflow: hidden;

  &.open {
    width: 100%;

    .content {
      width: 100%;
      height: 100%;
      opacity: 1;
      position: relative;
      overflow: hidden;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 100%;
  }

  .closed {
    background-color: $white;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: $color-brand-90;
    }
  }

  .hidden {
    display: none !important;
  }
}

.head {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #e9e9e9;

  .title {
    margin: 0 auto 0 0;
  }

  .resetBtn {
    padding: 0;
    height: auto;
    font-weight: 400 !important;
    margin-left: 16px;
    border: 0;
  }

  .collapseBtn {
    display: flex;
    padding: 0;
    border: 0;
    height: auto;
    margin-left: 16px;
  }
}

.grayColor {
  color: $color-black-55;
}
