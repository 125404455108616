@import 'theme/variables.scss';

.withHint {
  :global {
    .ant-row.ant-form-item {
      margin-bottom: 8px;
    }
  }
}

.spin {
  position: absolute;
  top: 30px;
  right: 0;
  left: 0;
}
