.root {
  display: flex;
  gap: 8px;

  .button {
    margin-top: 8px;
    width: 100%;
  }
}

.divider {
  margin: 0;
}

.popover {
  max-width: 396px !important;

  :global {
    .ant-popover-content{
      max-width: 396px !important;
    }
  }
}
