@import 'theme/variables.scss';

.search {
  max-width: 370px;
  margin-left: 10px;
  flex: 1 1 20%;
  padding: 0;

  & > input {
    min-height: 36px !important;
  }
}

.flexWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
