@import 'theme/variables.scss';

.root {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 9px;
  z-index: 1000;
  background-color: $color-red-70;
  text-align: center;
  color: #fff;
  font-size: 16px;

  @media (max-width: 1024px) {
    display: none;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }
}
