.root {
  background-color: white;
  padding: 24px 32px 32px;
  width: 100%;
  border-radius: 8px;

  .successInfo {
    text-align: center;
    max-width: 492px;
    width: 100%;

    .mb4 {
      margin-bottom: 4px !important;
    }

    .ratingInfo {
      display: flex;
      justify-content: center;
      align-items: center;

      .ratingNumber {
        display: block;
        margin-left: 4px;
      }

      .myRating {
        margin-right: 12px;
      }
    }

    .entityInfo {
      display: block;
      margin-bottom: 24px;
    }

    .content {
      word-break: break-word;
    }
  }

  .input {
    min-height: 130px !important;
  }

  .successIcon {
    display: flex !important;
    justify-content: center;
  }

  .title {
    margin-bottom: 24px;
    text-align: center;
  }

  .btnSubmit {
    width: 100%;
  }
}

.wrapLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .loader {
    height: 150px;
    width: 150px;
  }
}
