@import 'theme/variables.scss';

.history {
  display: flex;
  align-items: baseline;
  padding: 10px 8px;
  border-radius: 8px;
  border: 1px solid $color-black-85;
  font-size: 12px;
  margin: 6px 0;

  & > span:last-child {
    display: inline-block;
  }
}

.infoWrap {
  position: relative;
}

.fromText {
  position: absolute;
  left: 0;
  font-weight: 600;
  font-size: 12px;
  display: inline-block;
  width: fit-content;
  line-height: normal;
}

.collapseBtn {
  width: fit-content;
  height: auto;
  padding: 0;
  font-size: 12px;
  border: none;
  line-height: normal;
}

.textWrap {
  &:has(> span > ul) {
    padding-left: 20px;
  }
}
