@import 'theme/variables.scss';

$defaultWidth: 150px;

.root {
  display: flex;
  width: 100%;
  height: auto;

  &.fullWidth {
    :global(.ant-upload) {
      width: 100%;
      height: 100%;
    }
  }

  &:global(.ant-upload-drag:not(.ant-upload-disabled):hover) {
    border-color: transparent;
  }

  &:global(.ant-upload-drag) {
    border: 1px solid transparent;
  }

  :global {
    .ant-upload-list-item {
      padding: 2px;
      width: $defaultWidth;
      height: $defaultWidth;
      flex: 0 0 auto;
    }

    .ant-upload-list-item-info {
      position: relative;
      border-radius: 4px;
      overflow: hidden;
    }

    .ant-upload-list-item-info > span {
      height: 100%;
    }

    .ant-upload-list-item-info img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .ant-upload-list-item-thumbnail {
      opacity: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  :global {
    .ant-upload {
      width: 200px;
      min-width: 200px;
      height: 150px;
      background-color: lighten($primary-color, 42%);
      color: $primary-color;
      border-radius: $border-radius-base;
      overflow: hidden;
      margin-bottom: 8px;
    }

    .ant-upload-list-item-uploading-text {
      text-align: center;
    }

    .ant-upload-list {
      display: flex;
      flex-wrap: wrap;
    }

    .ant-upload-list-item-name {
      position: absolute;
      bottom: 0;
      white-space: normal;
      height: auto;
      line-height: 14px;
      font-size: 12px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .title,
  .description {
    color: $primary-color;
  }

  .description {
    font-size: 14px;
    letter-spacing: 1px;
    margin-bottom: 0;
  }
}

.root.inline {
  .content {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  .icon {
    margin-right: 5px;
  }

  .title {
    margin-right: 5px;
    white-space: nowrap;
  }

  .description {
    white-space: nowrap;
  }

  :global {
    .ant-upload {
      margin-bottom: 0;
      padding: 0;
      min-width: auto;
      color: inherit;
    }

    .ant-upload-drag {
      width: 100%;
      height: inherit;
      background: none;
      color: inherit;
      border: initial;
      overflow: hidden;
    }

    .anticon,
    .ant-upload:hover .anticon {
      color: inherit;
    }

    .ant-upload-btn {
      width: 100%;
      background: none;
      padding: 0;
      margin-bottom: 0;
    }
  }
}
