.panel {
  border-bottom: 1px solid #1dc2c9;
  margin-bottom: 16px;
  padding-bottom: 24px;

  & + & {
    border: 0;
    margin: 0;
    padding: 0;
  }

  .title {
    line-height: 1.5;
    margin-bottom: 16px;
    padding-top: 8px;
  }
}
