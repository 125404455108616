.root {
  display: flex;
  flex-direction: column;
  height: 100%;

  .mb24 {
    margin-bottom: 24px;
  }

  .button {
    width: 100%;
  }

  .statusEmailError {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .updateAuthBtn {
      font-size: 12px;
      padding: 0;
      height: auto;
    }
  }

  .source {
    margin: 0;
  }
}
