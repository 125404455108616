@import 'theme/variables.scss';

.root {
  width: 100%;
  justify-content: flex-end;
  display: flex;

  .rightPanel {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  .bell {
    margin: 0 9px;
  }
}
