@import 'theme/variables.scss';

.root {
  position: relative;
  flex-shrink: 0;

  &.open {
    width: 100%;

    .content {
      width: 100%;
      height: 100%;
      opacity: 1;
      position: relative;
      overflow: hidden;
    }
  }

  .content {
    padding-top: 12px;
    height: auto;
  }

  .contentBottom {
    overflow-y: auto;
    padding: 0 15px;
  }

  .head {
    display: flex;
    flex-direction: column;
  }
}

.btnBack {
  padding: 0 15px;
  flex-grow: 0;
}

.flexWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  margin-bottom: 10px;
}

.btn {
  border: none;
  color: $color-black-55;

  &:hover {
    background-color: $color-brand-90;

    & > span > span {
      color: #32a6a8;
    }
  }

  :global(> .anticon) {
    line-height: 24px;
  }
}
