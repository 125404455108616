@import 'theme/variables.scss';

.root {
  display: flex;
  align-items: center;
  gap: 8px;

  .children * {
    color: $color-yellow-40 !important;
  }
}
