@import 'theme/variables.scss';

.root {
  padding: 18px 24px;
  font-size: 12px;
  border-bottom: 1px solid rgba($text-color, 0.1);

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .agreement {
    margin-bottom: 10px;

    .agreementText {
      color: $color-black-55;
    }
  }

  .title {
    padding: 0;
    height: auto;
    margin-right: auto;
    font-size: 14px;
    font-weight: bold;
    color: $color-brand-90;
  }

  .restoredTitle {
    margin: 0 10px;
    color: $color-black-55;
  }

  .btn {
    padding-left: 10px;
    padding-right: 0;
    height: auto;
    font-size: 12px;
    font-weight: bold;
    color: $color-brand-90;
  }
}
