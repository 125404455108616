.form {
  padding: 24px 29px;

  .subtitle {
    margin-bottom: 24px;
  }

  .options {
    display: flex;
    flex-direction: column;
    gap: 16px;

    span {
      white-space: pre-line;
    }
  }

  .submitBtn {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .markdownInput {
    min-height: 100px;
  }

  .paperButton {
    cursor: pointer;
    margin: 0;
  }

  .attachment {
    margin-bottom: 16px;
  }
}
