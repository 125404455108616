.root {
  display: flex;
  gap: 8px;
  margin: 4px;
  max-width: 211px;
  white-space: normal;
}

.tooltipContent {
  display: flex;
  flex-direction: column;
}

.tooltipName {
  font-weight: bold;
  margin-bottom: 4px;
}
