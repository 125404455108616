.content {
  display: flex;
  gap: 48px;
  justify-content: space-between;
  align-items: center;
}

.moreDetailBtn {
  height: auto;
  margin: 16px 0 0;
  padding: 0;
  font-size: 16px;
}

.mb0 {
  margin-bottom: 2px !important;
}

.loadingText {
  text-align: center;
  display: block;
  max-width: 207px;
}

.spin {
  text-align: center;
}

.fadeOut {
  -webkit-animation: fade-out 12s ease-out both;
  animation: fadeOut 12s ease-out both;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
