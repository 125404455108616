@import 'theme/variables.scss';

.root {
  min-width: 32px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: $primary-color;
  display: flex;
  align-items: center;
  justify-content: center;

  &.isMessageReminder {
    background-color: $color-red-70;
  }

  &.isArchivedDiscussion {
    background-color: $color-black-45;
  }
}
