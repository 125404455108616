@import 'theme/variables.scss';

.createBtn {
  display: flex;
  align-items: center;
  font-weight: 600 !important;
  padding: 0;
  margin: 24px 0;
}

.list {
  display: flex;
  gap: 16px;
  flex-direction: column;
  overflow: auto;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 8px;
    border: 1px solid $color-black-85;
    border-radius: 4px;
    cursor: pointer;
    gap: 12px;

    .textWrap {
      padding: 8px 0 8px 8px;
      width: 100%;
      min-width: 0;
    }

    .text {
      margin: 0;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;

  .alert {
    margin-bottom: 16px;
  }

  .btnSubmit {
    margin-top: auto;
    margin-left: auto;
  }
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  margin-top: auto;
  margin-bottom: auto;

  .title,
  .description {
    margin: 0;
  }
}

.deleteTextAction {
  color: $color-red !important;
}
