@import 'theme/variables.scss';

.link {
  color: $color-brand !important;
  cursor: pointer;
  margin-bottom: 0 !important;

  &:hover {
    color: $color-brand-30 !important;
  }
}

.withHighlight {
  strong {
    font-weight: 400;
    padding: 1px;
    background-color: $color-yellow-20;
    border-radius: 4px;
  }
}
