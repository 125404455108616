@import 'theme/variables.scss';

.root {
  margin-top: 16px;
  margin-left: 16px;
  height: calc(100% - 100px);
}

.btn {
  border: none;
  color: $color-black-55;

  &:hover {
    background-color: $color-brand-90;
  }

  :global(> .anticon) {
    line-height: 24px;
  }
}

.content {
  height: 100%;
  display: flex;
}

.empty {
  margin: 0 auto 20px;
  align-self: center;
  justify-self: center;
}
