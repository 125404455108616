.ant-avatar {
  text-transform: uppercase;

  img {
    object-fit: cover;
  }

  > .ant-avatar-string {
    top: 0px;
  }
}
