@import 'theme/variables.scss';

.overlayClassName {
  padding: 0;
  box-shadow: $shadow-3;
  border-radius: $border-radius-base;
  background-color: $white;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  :global {
    .ant-btn {
      &:disabled {
        background-color: transparent;
        color: $color-black-55;
      }
    }

    .ant-dropdown-menu-submenu-expand-icon {
      display: none;
    }

    .ant-dropdown-menu {
      position: relative;
      border: unset;
      background-color: $white;
      width: 100%;
      box-shadow: none;

      &::after {
        content: '';
        width: calc(100% - 40px);
        display: block;
        position: absolute;
        border-bottom: 1px solid $color-black-85;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .ant-dropdown-menu-submenu-active {
      .ant-dropdown-menu-submenu-title {
        color: $color-black-55;
      }
    }

    .ant-dropdown-menu-submenu-title {
      width: 100%;
      padding: 7px 20px;
      text-align: left;
      align-items: center;
      display: flex;
      height: auto !important;
      min-height: $height-base;
      color: $color-black;
      white-space: break-spaces;
      background-color: $white !important;
      margin: 0;
      line-height: 1.5 !important;

      &:hover {
        color: $color-black-55;
      }
    }
  }

  :root & button.action {
    width: 100%;
    padding: 7px 20px;
    text-align: left;
    align-items: center;
    display: flex;
    height: auto;
    min-height: $height-base;
    color: $color-black;
    white-space: break-spaces;

    &:hover {
      color: $color-black-55;
    }
  }
}

.divider {
  margin: 4px 0;
}

.btn {
  width: 24px;
  height: 24px;
  border: 1px solid $color-black-75;
  background: $color-black-95;
  border-radius: $border-radius-base;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:active,
  &:focus {
    background-color: $color-black-98;
    color: $color-black-45;
    border-color: $color-black-75;
  }

  // Spin
  & > div > div {
    display: flex;
  }
}

.btnSort {
  flex-shrink: 0;
  padding: 0;
  width: 18px;
  height: 18px;
  margin-right: 4px;
  border: none;

  &:hover,
  &:active,
  &:focus {
    color: $color-black-75;
  }
}

.submenuPopup {
  color: red;

  :global {
    .ant-dropdown-menu-item {
      height: auto !important;
      line-height: 1.5 !important;
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  ul {
    padding: 0;
    box-shadow: $shadow-3;
    border-radius: $border-radius-base;
    background-color: $white !important;
  }

  :root & button.action {
    width: 100%;
    padding: 7px 20px;
    text-align: left;
    align-items: center;
    display: flex;
    height: auto;
    min-height: $height-base;
    color: $color-black;
    white-space: break-spaces;

    &:hover {
      color: $color-black-55;
    }
  }
}
