@import 'theme/variables.scss';

.root {
  word-break: break-word;
  line-height: normal;
  margin-top: 1px;

  p {
    margin-bottom: 0;
    white-space: break-spaces;
  }

  img,
  video {
    max-width: 100%;
    box-sizing: initial;
  }

  pre {
    margin: 5px 0;
    white-space: normal;
  }
}

// для отрисовки прежде созданных задач
.root + .root,
.root + .root > p {
  display: inline;
}

:global {
  a.mention-link {
    color: $color-black-35;
    border-radius: 24px;
    border: none;
    background-color: $color-black-85;
    padding: 2px 8px;
    white-space: nowrap;
    line-height: 2;
  }

  .code-hljs {
    display: inline-block;
    background-color: $color-black-95;
    padding: 2px 10px;
    border-radius: $border-radius-base;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
      monospace;
  }

  .hljs {
    background-color: $color-black-95;
    border-radius: $border-radius-base;
    font-size: 14px;
  }
}
