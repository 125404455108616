@import 'theme/variables.scss';

.formWrapper {
  display: flex;
  flex-direction: column;
}

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.datePicker {
  max-width: 100%;
}

.submitWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.fileButton {
  border: 1px solid $color-brand !important;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.addBtn {
  padding: 0;
  display: flex;
  align-items: center;
}

.hiringWrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
