@import 'theme/variables.scss';

.body {
  overflow-y: auto;
}

.root {
  flex: 1;
  overflow: auto;
  height: 100%;


  @media (max-width: $screen-md) {
    height: 100svh;
  }

  .container {
    max-width: 832px;
    padding: 16px;
    width: 100%;
    margin: 0 auto;
  }
}
