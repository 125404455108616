@import 'theme/variables.scss';

.root {
  position: relative;

  .toBottom {
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: $color-black-85;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.15));
    bottom: 24px;
    right: 10px;
    cursor: pointer;
    opacity: 0;

    &.visible {
      animation: showToBottom 0.5s;
      animation-fill-mode: forwards;
    }

    &.hidden {
      animation: hideToBottom 0.5s;
      animation-fill-mode: forwards;
    }
  }

  .badge {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    background-color: $color-red;
    right: 0;
    top: 0;
  }
}
@keyframes hideToBottom {
  99% {
    opacity: 0;
  }

  100% {
    visibility: hidden;
  }
}

@keyframes showToBottom {
  to {
    opacity: 1;
  }
}
