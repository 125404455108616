.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 24px;

  .title {
    margin: 0;
  }

  .button {
    font-size: 16px;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
