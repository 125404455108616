.root {
  height: 100vh;
  width: 100%;
  display: flex;

  &:hover {
    .pagination {
      opacity: 1;
    }
  }
}

.document {
  position: relative;
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  z-index: 2;
  gap: 12px;

  :global {
    .react-pdf__Page__textContent {
      top: -4px !important;
      transform: translate(-50%) rotate(0deg) !important;
    }

    .linkAnnotation {
      display: none;
    }
  }
}

.spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pagination {
  opacity: 0;
  transition: all ease-in-out 0.2s;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
