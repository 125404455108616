@import 'theme/variables.scss';

.form {
  display: flex;
  flex-direction: column;
  height: 100%;

  .ivitationType {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 8px;
  }

  .image {
    flex-shrink: 0;
    margin-bottom: 16px;
  }

  .submit {
    margin-top: auto;
    align-self: flex-end;
    flex-shrink: 0;
  }

  & :global {
    .ant-select-selection,
    .ant-select-selector {
      border-color: $color-black-65 !important;
    }
  }
}
