@import 'theme/variables.scss';

.fieldWrap {
  margin-bottom: 16px;
}

.form {
  display: flex;
  flex-direction: column;
}

.formButtons {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 24px;
}

.colorItem {
  margin-bottom: 0;
}

.colorGroup {
  justify-content: space-between;
}

.warningSection {
  background: $color-yellow-30;
  border-radius: 4px;
  border: 1px solid $color-yellow;
  padding: 8px 16px;
  margin-bottom: 16px;
}

.datePickerWrapper {
  max-width: 100%;
}
