@import 'theme/variables.scss';

.content {
  padding: 20px 30px;
  height: 100%;

  .form {
    height: 100%;
  }
}
