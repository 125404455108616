@import 'theme/variables.scss';

.root {
  display: flex;
  align-items: center;
  word-break: break-word;
}

.iconWrap {
  position: relative;
}

.outdatedIcon {
  position: absolute;
  top: -3px;
  right: 9px;
}
