@import 'theme/variables.scss';

.collapse {
  .panel {
    margin-bottom: 10px;
    display: block;
    border: 1px solid $color-brand-30 !important;
    border-radius: 5px;
    text-align: center;
  }

  .panel:last-child {
    border-radius: 5px;
  }

  .commentBlock {
    margin-top: 24px;

    .commentText {
      font-size: 16px;
    }

    .button {
      height: auto;
      padding: 0;
    }
  }

  & :global {
    .ant-collapse-header {
      padding: 0px !important;
      font-size: 16px !important;
      line-height: 1.5 !important;
      color: $color-brand-30 !important;
    }

    .ant-collapse-content-box {
      padding: 0px !important;
    }

    .ant-collapse-item {
      padding: 6px 16px;

      &-active {
        padding: 16px;

        .ant-collapse-header {
          margin-bottom: 24px;
        }
      }
    }
  }

  .content {
    :global(iframe) {
      width: 100%;
    }
  }
}
