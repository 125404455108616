@import 'theme/variables.scss';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 26px;
  padding: 0;
  margin-right: 4px;
  margin-left: auto;
  flex-shrink: 0;
}
