@import 'theme/variables.scss';

.root {
  display: flex;
  flex-direction: column;

  .relationsSelect {
    & label {
      &::after {
        display: none;
      }
    }
  }

  .projectFields {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
  }

  .fieldsWrap {
    padding: 15px 25px 22px;

    .description {
      margin-bottom: 16px;

      :global {
        .ant-form-explain {
          margin-top: -6px;
          position: absolute;
        }

        .ant-form-item {
          margin-bottom: 16px !important;
        }
      }
    }

    :global .has-error {
      .ant-form-explain {
        margin-top: 4px;
      }

      [class*='control'] {
        border-color: $color-red-70;
      }
    }
  }

  .subTitle {
    font-size: 16px;
    line-height: 1.375;
    font-weight: bold;
  }

  .block {
    display: block;
    margin-top: 9px;
  }

  .members {
    :global {
      .ant-row {
        &.ant-form-item {
          margin-bottom: 9px;
        }
      }
    }
  }

  .withHint {
    :global {
      .ant-row.ant-form-item {
        margin-bottom: 8px;
      }
    }
  }

  .letterKind {
    width: 240px;
    margin-top: 10px;
  }

  .dates {
    .btnDelete {
      top: 25px;
    }
  }

  .dateContainer {
    display: flex;
    align-items: center;
  }

  .datePicker {
    min-width: 150px;
    max-width: 170px;
  }

  .field,
  .dateStart {
    position: relative;

    .btnDelete {
      width: 20px;
      height: 38px;
      position: absolute;
      right: -22px;
      top: 20px;
      opacity: 0;
      transition: 0.2s ease-in-out;

      & > i {
        display: flex;
      }
    }

    &:hover {
      .btnDelete {
        opacity: 1;
      }
    }

    &::after {
      // для появления крестика удаления поля при наведении на место самого крестика
      content: '';
      position: absolute;
      width: 11px;
      height: 20px;
      top: 51%;
      right: -11px;
    }
  }

  .dateStart {
    position: relative;
    padding-right: 25px;

    .btnDelete {
      right: 2px;
    }

    &:hover {
      .btnDelete {
        opacity: 1;
      }
    }
  }

  .timeset {
    display: flex;

    :global {
      .ant-input {
        width: 72px;
        margin-right: 15px;
      }
    }
  }

  .visibleCalendarBtn {
    width: 100%;
    margin-bottom: 16px;
  }

  .divider {
    margin: 0;
    margin-bottom: 25px;
    background: rgba($primary-color, 0.2);
    height: 2px;
  }

  .actionsWrap {
    align-self: flex-start;
    margin-left: 25px;
  }

  .btnDropdown {
    margin-right: auto;
    padding-left: 0;
    font-size: 16px;
    display: flex;
    align-items: center;
  }

  .dropdownOverlay {
    left: 20px;
    margin-left: 30px;
  }

  .footerActions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 25px;
  }

  .typeTaskSelect {
    background-color: $color-brand-90;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;

    .text {
      font-weight: bold;
    }

    :global .ant-row {
      &.ant-form-item {
        margin-bottom: 0;
      }
    }
  }

  .select {
    width: 200px;
  }

  .flexWrap {
    display: flex;
    flex-wrap: wrap;

    .item {
      margin-bottom: 6px;
    }

    .location {
      flex-grow: 1;
      flex-basis: 50%;
      margin-right: 20px;
    }
  }

  .slaAlert {
    margin-bottom: 16px;
  }
}

.mentionWrap {
  z-index: 1000;
}
