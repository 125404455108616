@import "theme/variables.scss";

.title {
  margin-bottom: 16px !important;
}

.subtitle {
  margin-bottom: 4px !important;
  margin-top: 16px;
}

.radio {
  margin-top: 16px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .rootCheckbox {
    margin: 0;
    padding-top: 8px;

    .checkbox {
      display: flex;
      align-items: center;
      margin: 0;
    }
  }

  .day {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .timepickerWrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    position: relative;

    .mb32 {
      margin-bottom: 32px;
    }

    .errorMessage {
      position: absolute;
    }

    .rowTimepicker {
      display: flex;
      gap: 8px;
    }

    .timepicker {
      display: flex;
      align-items: center;
      border: 1px solid $color-black-65;
      border-radius: 5px;

      .inputTimepicker {
        margin: 0;
      }

      :global(.ant-time-picker-input) {
        border: none;
        background-color: transparent;
      }
    }

    .addIntervalBtn {
      padding: 0;
    }
  }
}
