@import 'theme/variables.scss';

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 620px);
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  min-height: 100%;
  padding: 16px;

  .backgroundImage {
    position: absolute;
    min-width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    z-index: -1;
  }
}
