.form {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;

  .button {
    margin-top: auto;
    margin-left: auto;
  }
}
