.root {
  display: flex;
  flex-shrink: 0;
  gap: 8px 16px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  height: 40px;

  .button {
    padding: 0;
    border: unset;
  }
}
