.uploadForm {
  height: 100%;
  display: flex;
  flex-direction: column;

  .divider {
    background-color: #D8D9DA;
    height: 1px;
    width: 100%;
  }

  .form {
    display: flex;
    flex-direction: column;
    flex: 100%;
  }

  .submit {
    margin: auto 0 24px auto;
  }
}

.relations {
  margin-bottom: 0;
}

.upload>span {
  display: block;
  margin-bottom: 16px;
}

.emptyRoot {
  margin: 0 auto;
  max-width: 228px;
}