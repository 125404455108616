@import 'theme/variables.scss';

.root {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  padding: 24px;
  border-radius: 5px;
  text-align: center;

  .title {
    word-wrap: break-word;
    text-align: center;
    margin-bottom: 16px;
  }

  .description {
    margin-bottom: 16px;
  }

  .privacy {
    margin-top: auto;

    .checkbox {
      display: flex;

      :global .ant-checkbox-checked .ant-checkbox-inner {
        background-color: var(--background-button);
        border-color: var(--background-button);
      }
    }

    .text {
      display: block;
      font-size: 10px;
    }

    .link {
      font-weight: 600;
    }
  }

  .submitBtn {
    display: flex;
    align-items: center;
    margin: 0 auto;
    border: none;
    color: $white;
    overflow: hidden;
    max-width: 100%;

    .btnText {
      display: block;
      font-weight: 600;
      color: $white;
    }
  }

  input:disabled,
  textarea:disabled {
    background: white;
  }

  .phone {
    text-align: left;

    span {
      text-align: center;
    }
  }

  .textarea {
    resize: none;
  }
}

.footer {
  margin-top: 8px;
}

.logo {
  svg {
    width: 180px;
    height: 40px;
  }

  margin-bottom: 32px;
}

.customLogo {
  max-width: 180px;
  max-height: 180px;
  margin-bottom: 32px;
}

.attachFile {
  text-align: left;
  padding: 0;
}

.attachBtnDisabled {
  cursor: not-allowed;
}

.attachBtn {
  display: flex;
  align-items: center;
  padding: 0;
}
