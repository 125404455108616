@import 'theme/variables.scss';

.ant-radio-group {
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none;
  }

  .ant-radio {
    &:hover {
      .ant-radio-inner {
        border-color: $color-brand;
      }
    }

    .ant-radio-inner {
      border-color: $color-black-55;
    }
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: $color-brand;
  }

  .ant-radio-disabled {
    .ant-radio-inner {
      border-color: $color-black-75 !important;
      background-color: transparent;
    }

    &.ant-radio-checked {
      .ant-radio-inner::after {
        background-color: $color-black-75;
      }
    }
  }

  &.without-border {
    .ant-radio-button-wrapper:not(:first-child)::before {
      display: none;
    }
  }

  .ant-radio-button-wrapper {
    position: relative;
    border: 1px solid $primary-color;
    color: $primary-color;
  }

  .ant-radio-button-wrapper-disabled,
  .ant-radio-button-wrapper-disabled:hover {
    background: $color-black-95;
    border: 1px solid $color-black-55;
    color: $color-black-55;
  }

  .ant-radio-button-wrapper-checked,
  .ant-radio-button-wrapper-checked:hover {
    background: $primary-color;
    color: $white;
  }
}

.ant-radio-group {
  &.ant-radio-group-solid {
    .ant-radio-button-wrapper {
      border-color: $primary-color;

      &:hover {
        &.ant-radio-button-wrapper-checked {
          background-color: $primary-color;
          border-color: $primary-color;

          &:hover {
            cursor: default;
            background-color: $primary-color;
          }
        }
      }

      .ant-radio-button-wrapper-checked {
        background-color: $primary-color;
        pointer-events: none;
        cursor: default;
      }
    }
  }
}
