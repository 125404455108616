@import 'theme/variables.scss';

.root {
  position: relative;

  .title {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
    padding: 10px 0;
    margin-right: 34px;
  }

  .btn {
    position: absolute;
    right: 0;
    background: none;
    border: none;
    opacity: 0.8;
    padding: 0;
    min-width: 26px;
    margin-top: 5px;
    font-size: 20px;

    button {
      padding: 0;
    }

    &:hover {
      opacity: 1;
    }
  }

  .divider {
    margin: 10px 0;
  }

  .option {
    border: 1px solid $color-black-95;
    padding: 0 8px;
    margin: 0 2px 5px 2px;
    border-radius: $border-radius-base;
    height: 36px;

    &:hover {
      background-color: rgba($primary-color, 0.1);
    }

    &.disabled {
      &:hover,
      &:focus {
        background-color: transparent;
        cursor: default;
      }
    }
  }

  :global {
    .ant-tree {
      .ant-tree-switcher-noop {
        display: none;
      }

      .ant-tree-switcher:not(.ant-tree-switcher-noop) {
        position: absolute;
        z-index: 2;
        margin-top: 5px;

        & + .ant-tree-node-content-wrapper {
          & [class*='option'] {
            padding-left: 29px;
          }
        }
      }

      .ant-tree-treenode-switcher-open {
        [role*='treeitem'] {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: -24px;
            left: -14px;
            display: block;
            width: 15px;
            height: 48px;
            border: $color-black-95 solid 1px;
            border-top: none;
            border-right: none;
          }
        }
      }

      .ant-tree-node-content-wrapper {
        position: relative;
        z-index: 1;
        width: 100%;
        height: $height-base;
        padding: 0;
        border-radius: $border-radius-base;
        background: #fff;
      }

      .ant-tree-node-content-wrapper-normal {
        &:hover {
          background-color: transparent;
        }
      }

      li ul {
        padding-left: 24px;
      }
    }
  }
}

.statistic {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  margin-bottom: 10px;

  .text {
    color: $color-black-55;
  }

  .left {
    margin-right: 46px;
    flex-shrink: 0;
  }

  .right {
    flex-grow: 1;
  }

  :global(.ant-progress-text) {
    font-size: 12px;
    color: $color-black-55;
    font-weight: 600;
  }

  :global(.ant-progress-bg) {
    border-radius: 10px !important;
  }
}

.treeSubTasks {
  :global {
    .ant-tree-treenode {
      width: 100%;

      &:not(:last-child) {
        padding-bottom: 12px;
      }
    }
  }
}
