@import 'theme/variables.scss';

.root {
  text-align: center;
  padding: 47px 24px 32px;

  .select {
    max-width: 320px;
    width: 100%;
  }

  .spinWrap {
    margin-top: 30px;
  }

  .buttons {
    & > :not(:last-child) {
      margin-right: 20px;
    }

    button.close {
      color: $color-red-70;

      &:hover {
        color: $color-red-80;
      }
    }
  }

  .btnNo {
    background-color: transparent;
    color: $color-red-70;
  }
}
