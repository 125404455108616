.form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.videoBtn {
  margin-bottom: 16px;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  margin-bottom: 20px;
}

.submitBtn {
  align-self: flex-end;
  margin-top: auto;
  flex-shrink: 0;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
}

.listItem {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
}

.infoWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: 350px;
}

.nameWrap {
  display: flex;
  align-items: center;
  gap: 8px;
}
