@import 'theme/variables.scss';

$height: 40px;

.modal {
  :global {
    .ant-tree.ant-tree-directory
      > li
      span.ant-tree-node-content-wrapper:hover::before,
    .ant-tree.ant-tree-directory
      .ant-tree-child-tree
      > li
      span.ant-tree-node-content-wrapper:hover::before {
      background: none;
    }
    .ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-switcher {
      color: $color-black-65;
    }

    .ant-tree.ant-tree-directory
      > li.ant-tree-treenode-selected
      > span.ant-tree-node-content-wrapper::before,
    .ant-tree.ant-tree-directory
      .ant-tree-child-tree
      > li.ant-tree-treenode-selected
      > span.ant-tree-node-content-wrapper::before {
      background: none;
    }

    .centred {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: $height;
    }

    .ant-checkbox-wrapper {
      margin-right: 10px;
      @extend .centred;
    }

    .ant-tree {
      li {
        padding: 0;
      }

      .ant-tree-treenode-selected {
        background: $color-brand-95;
      }

      .ant-tree-checkbox {
        margin: 0;
        margin-right: 10px;
        @extend .centred;

        &::after {
          visibility: hidden;
        }
      }

      span.ant-tree-switcher {
        color: $color-black-55;
        min-width: 18px;
        padding-right: 8px;
        @extend .centred;
      }

      span.ant-tree-node-content-wrapper {
        background-color: transparent;
        padding: 0;
        width: auto;
        @extend .centred;
        justify-content: flex-start;
        overflow: hidden;
      }

      .ant-tree-child-tree {
        span.ant-tree-node-content-wrapper {
          width: calc(100% - 54px);

          &.ant-tree-node-content-wrapper-close,
          &.ant-tree-node-content-wrapper-open {
            width: auto;
          }
        }
      }

      .ant-tree-title {
        display: inline-flex;
        justify-content: space-between;
        flex-grow: 1;
        overflow: hidden;
      }

      .ant-tree-treenode-switcher-close.ant-tree-treenode-selected {
        background-color: #e9fbfc;
      }
    }
  }
}

.jobTitle {
  flex-grow: 1;
  text-align: right;
  height: 40px;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  cursor: default;
}
