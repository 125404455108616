.root {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .buttonWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
