@import 'theme/variables.scss';

.ant-pagination {
  .ant-pagination-prev,
  .ant-pagination-item,
  .ant-pagination-next {
    border: 0;

    a {
      border: 0;
    }
  }

  .ant-pagination-item {
    margin-right: 4px;

    &.ant-pagination-item-active {
      background-color: $primary-color;
    }

    &.ant-pagination-item-active a {
      color: $white;
    }
  }
}
