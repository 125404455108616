.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 24px 32px;
  gap: 20px;

  .title {
    margin: 0;
  }

  .description {
    margin: 0;
    text-align: center;
  }

  .text {
    cursor: pointer;
  }
}
