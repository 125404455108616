@import 'theme/variables.scss';

.ant-rate {
  font-size: 17px;
  color: $color-yellow;

  .ant-rate-star {
    margin-right: 4px;
  }
}
