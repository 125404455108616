@import 'theme/variables.scss';

.root {
  position: relative;
  padding: 10px;
  border: 0;
  display: flex;
  min-width: 180px;
  max-width: 265px;
  background-color: transparent;

  &.line {
    max-width: 500px;

    .position {
      width: 100%;
      align-items: center;
      align-self: center;
    }

    .ellipsis {
      width: 100%;
      max-width: 400px;
    }

    .content {
      flex-direction: row;
      align-items: center;
    }

    .name {
      margin-right: 10px;
    }
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
  }

  .name,
  .position,
  .phone,
  .email {
    margin-bottom: 2px;
    font-size: 12px;
    text-align: left;
    line-height: 1.4;
    word-break: break-all;
  }

  .phone,
  .email {
    font-size: 13px;
  }

  .link {
    padding: 0;
    height: auto;
    color: $color-black !important;
  }

  .unlink {
    color: $text-color;
    cursor: inherit;
  }

  .label {
    text-transform: uppercase;
    color: $color-black-55;
    font-size: 12px;
  }

  .name {
    font-weight: bold;
    margin-top: 0;
    color: $text-color;
    display: flex;
    white-space: pre-line;
    align-self: flex-start;

    .rate {
      margin-left: auto;
      font-weight: normal;
    }
  }

  .position {
    line-height: 14px;
    display: flex;
    align-items: center;
    align-self: flex-start;

    span {
      margin-right: 5px;
      white-space: break-spaces;
    }
  }

  .rate {
    line-height: 20px;
    color: $label-color;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    :global {
      .ant-popover-open {
        .anticon {
          transform: none;
        }
      }
    }
  }

  &.vertical {
    min-width: 200px;
    text-align: center;

    .body,
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
    }

    .position {
      text-align: center;
    }

    .avatar {
      margin-bottom: 10px;
    }

    .name {
      text-align: center;
      max-width: 100%;
    }
  }

  &.horizontal {
    .body {
      display: flex;
      width: 100%;
    }

    .body > .link {
      align-self: flex-start;
    }

    .avatar {
      margin-right: 10px;
    }
  }

  &.fullWidth {
    width: 100%;
    max-width: 100%;
  }
}
