.root {
  display: flex;
  height: 100%;
  width: 100%;

  .wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    text-align: center;
  }

  .image {
    display: flex;
    margin-bottom: 20px;
  }

  .message {
    margin-bottom: 32px;
  }
}