@import 'theme/variables.scss';

.form {
  display: flex;
  flex-direction: column;
}

.datePickerWrapper {
  max-width: 100%;
}

.formButtons {
  display: flex;
  justify-content: end;
  margin-top: 16px;
}
