@import 'theme/variables.scss';

.content {
  display: block;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  :global {
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-width: 0;
      outline-width: 0;
    }
  }
}

.title {
  margin-bottom: 24px;
  text-align: center;

  &.cardTitle {
    margin-bottom: 8px;
  }

  &.subtitle {
    margin-top: 0 !important;
  }
}

.comment {
  text-align: center;
  margin-top: 24px;
}

.button {
  height: auto;
  padding: 0;
  font-size: 16px;
  font-weight: 400 !important;
}
