.root {
  .text {
    margin-bottom: 24px;
  }

  .types {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  .card {
    padding: 16px;
    min-height: 140px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  .icon, .title {
    margin-bottom: 8px;
  }
}