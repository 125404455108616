@import 'theme/variables.scss';
@import 'theme/styles/button.scss';

// Usage example:
//
// @include buttonColors(
//   $default-color $default-background-color,
//   $hover-color $hover-background-color,
//   $disabled-color $disabled-background-color,
// )
@mixin buttonColors($default, $hover: null, $disabled: null) {
  color: nth($default, 1);
  background-color: nth($default, 2);
  border-color: nth($default, 3);

  @if $hover {
    &:hover {
      color: nth($hover, 1);
      background-color: nth($hover, 2);
      border-color: nth($hover, 3);
    }
  }

  @if $disabled {
    &:disabled {
      color: nth($disabled, 1);
      background-color: nth($disabled, 2);
      border-color: nth($disabled, 3);
    }
  }
}

:root button.root {
  &.widthExpanded {
    padding: {
      left: 32px;
      right: 32px;
    }
  }

  &.widthDefault {}

  &.widthFull {
    width: 100%;
  }

  &.moodNegative {
    &.typeLink {
      @include buttonColors($color-red-70 transparent transparent,
        $color-red-60 transparent transparent,
        $color-black-65 transparent transparent);
    }

    &.typeText {
      @include buttonColors($color-red-70 transparent transparent,
        $color-red-60 transparent transparent,
        $color-black-65 transparent transparent);
    }

    &.typePrimary {
      @include buttonColors($color-white $color-red-70 $color-red-70,
        $color-white $color-red-60 $color-red-60,
        $color-white $color-black-75 $color-black-75 );
    }

    &.typeSecondary {
      @include buttonColors($color-red-70 transparent $color-red-70,
        $color-red-60 transparent $color-red-60,
        $color-black-65 transparent $color-black-65 );
    }
  }

  &.moodPositive {
    &.typeLink {
      @include buttonColors($color-brand transparent transparent,
        $color-brand-30 transparent transparent,
        $color-black-65 transparent transparent);
    }

    &.typeText {
      @include buttonColors($color-brand transparent transparent,
        $color-brand-30 transparent transparent,
        $color-black-65 transparent transparent);
    }

    &.typePrimary {
      @include buttonColors($color-white $color-brand $color-brand,
        $color-white $color-brand-30 $color-brand-30,
        $color-white $color-black-75 $color-black-75 );
    }

    &.typeSecondary {
      @include buttonColors($color-brand transparent $color-brand,
        $color-brand-30 transparent $color-brand-30,
        $color-black-65 transparent $color-black-65 );
    }

    &.typeTertiary {
      @include buttonColors($color-black $color-black-95 $color-black-95,
        $color-black $color-black-85 $color-black-85,
        $color-black-55 $color-black-95 $color-black-95 );
    }
  }

  &.typeGoogle {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    box-shadow: 0px 2px 5px rgba(137, 140, 144, 0.4);
    color: rgba(0, 0, 0, 0.54);
    background-color: $color-white;
    border: 0.5px solid transparent;
    transition: border-color 0.2s ease;

    &:hover {
      border-color: #4285f4;
      box-shadow: none;
      outline: 3px solid rgba(66, 133, 244, 0.1);
    }

    &:active {
      border-color: transparent;
      background-color: #eeeeee;
      box-shadow:
        0px 0px 1px rgba(0, 0, 0, 0.084),
        0px 1px 1px rgba(0, 0, 0, 0.168);
      outline: none;
    }

    &:disabled {
      border: none;
      background-color: #eeeeee;
      box-shadow:
        0px 0px 1px rgba(0, 0, 0, 0.084),
        0px 1px 1px rgba(0, 0, 0, 0.168);
      filter: grayscale(1);
      outline: none;
      color: rgba(0, 0, 0, 0.4);

      svg[data-icon='google']>path {
        fill: cadetblue;
      }
    }

    span:last-of-type {
      margin-left: 21px;
    }
  }

  &.typeGoogleDrive {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 16.41px;
    color: rgba(0, 0, 0, 0.54);
    background-color: $color-white;
    border: 1px solid #D8D9DA;
    transition: border-color 0.2s ease;

    &:hover {
      border-color: #4285f4;
      outline: 3px solid rgba(66, 133, 244, 0.1);
    }

    &:active {
      border-color: transparent;
      background-color: #eeeeee;
      outline: none;
    }

    &:disabled {
      border: none;
      background-color: #eeeeee;
      filter: grayscale(1);
      outline: none;
      color: rgba(0, 0, 0, 0.4);

      svg[data-icon='google']>path {
        fill: cadetblue;
      }
    }

    span:last-of-type {
      margin-left: 21px;
    }
  }
}