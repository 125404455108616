.link {
  &.tab {
    position: relative;
    display: inline-block;
    color: rgba($text-color, 0.5);
    font-size: 24px;
    border: 0;
    font-weight: bold;
    padding: 6px 16px;
    box-shadow: none;
    height: auto;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 4px;
      background: transparent;
      border-radius: $border-radius-base;
      transform-origin: 50% 50%;
      transition: 0.2s ease-in-out;
    }

    &:hover {
      color: rgba($text-color, 0.8);
    }

    &.active {
      color: $text-color;
    }

    &.active::before {
      width: 100%;
      background: linear-gradient(
        to right,
        $primary-color 0%,
        $color-green 100%
      );
    }
  }
}
