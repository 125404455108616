@import 'theme/variables.scss';

.ant-slider {
  .ant-slider-rail {
    background-color: $color-black-85;
  }

  .ant-slider-track {
    background-color: $color-brand;
  }

  .ant-slider-handle {
    border-color: $color-brand;
  }
}
