@import 'theme/variables.scss';

.dates {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.partialBlocked {
  background-color: $color-yellow-30;

  &:hover {
    background-color: $color-yellow-30;
  }
}

.tooltip > div > div {
  text-align: center;
}

.popper {
  pointer-events: none;
  filter: brightness(0.9);

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    top: 50%;
    left: 35%;
    z-index: 120;

    margin: auto;
    border: 4px solid #eaf0f6;
    border-radius: 50%;
    border-top: 4px solid $color-brand;
    animation: spinner 1s linear infinite;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
