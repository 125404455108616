@import 'theme/variables.scss';

.root {
  .tabs {
    flex-shrink: 0;

    :global {
      .ant-tabs-tab {
        max-height: 45px;
        display: inline-flex;
        align-items: center;

        &:hover {
          color: $text-color;
        }
      }

      .ant-tabs-bar {
        margin-bottom: 0;
      }

      .ant-tabs-nav > div {
        display: flex;
        align-items: center;
      }

      .ant-tabs-tab-active {
        color: $text-color;
      }
    }
  }

  .content {
    flex-grow: 1;
    overflow: auto;
    display: flex;
  }
}

.chat {
  width: 100%;
  margin-bottom: 12px;
}

.permissionsError {
  max-width: 260px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .icon {
    margin-top: 50px;
    margin-bottom: 16px;
  }
}
