@import 'theme/variables.scss';

.root {
  display: flex;
  height: 100%;
  max-height: inherit;

  .pane {
    transition: all 0.2s ease-in-out;
  }

  .pane:last-child {
    flex: 1 1 auto;
    width: 1%;
  }
}
