@import 'theme/variables.scss';

.videoBtn {
  margin-bottom: 16px;
}

.description {
  margin-bottom: 24px;
}

.form {
  display: flex;
  flex-direction: column;
  flex: auto;

  .select {
    margin-bottom: 24px;

    & > span {
      font-weight: 600;
      font-size: 14px !important;
      color: $color-black !important;
      margin-bottom: 8px !important;
    }
  }

  .submit {
    align-self: flex-end;
    margin-top: auto;
  }
}

.skeletonRoot {
  display: flex;
  flex-direction: column;
  flex: auto;

  .paragraph {
    margin-bottom: 24px;

    & > li:not(:first-child) {
      margin-top: 4px;
    }
  }

  .input {
    margin-bottom: 24px;
  }

  .buttonWrap {
    margin-top: auto;
  }

  .button {
    margin-left: auto;
  }
}
