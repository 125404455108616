@import 'theme/variables.scss';

.root {
  :global(.ant-drawer-close) {
    display: none;
  }

  :global(.ant-drawer-title) {
    display: flex;
  }

  .btnClose {
    color: $color-black-55;
    height: $layout-header-height;
    border: none;
    background: none;
    margin-left: auto;
    padding: 0 4px;
  }

  .btnArrow {
    border: none;
    height: $layout-header-height;
    color: $primary-color;
    background-color: transparent;
    padding: 0 4px;
    margin-right: 8px;
  }
}

.isContentDrawer {
  display: flex;
  flex-direction: column;

  .content {
    padding-bottom: 40px;
    flex-grow: 1;
  }
}
