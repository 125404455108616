@import 'theme/variables.scss';

.root {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;

  .file {
    margin: 16px 0;
  }

  .table {
    :global {
      .ant-table {
        overflow: auto;
      }

      table {
        overflow-x: auto;
        font-size: 14px;

        .ant-table-row {
          background: $color-red-90;
        }

        td {
          border: 1px solid $color-red;
          border-left: none;
          border-right: none;
          border-bottom: none;

          &:first-child {
            border-left: 1px solid $color-red;
          }

          &:last-child {
            border-right: 1px solid $color-red;
          }
        }

        tr:last-child td {
          border-bottom: 1px solid $color-red !important;
        }
      }

      .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
        background: unset;
      }

      :root & .ant-table .ant-table-tbody > tr > td {
        padding: 8px 15px !important;
      }
    }
  }

  .btn {
    min-height: 40px;
    margin-top: auto;
    margin-left: auto;
  }

  .count {
    color: black !important;
  }
}
