.datePickerWrapper {
  max-width: 100%;
}

.formItem {
  margin-bottom: 0;
}

.datePicker {
  margin-right: 0;
}
