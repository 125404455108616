@import 'theme/variables.scss';

.root {
  display: flex;
  flex-direction: column;

  :global(.ant-radio-button-wrapper::before) {
    display: none;
  }

  .form {
    margin-bottom: 24px;
  }

  .grayColor {
    color: $label-color;
  }

  .dangerPanel {
    background-color: $color-red-90;
    padding: 8px 16px;
    border-radius: $border-radius-base;
    border: 1px solid $color-red;
    margin-bottom: 24px;
  }

  .sources {
    cursor: default;

    & > i {
      display: none !important;
    }
  }

  .welcomeMessageTitle {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0 0 16px;
  }

  .appearance {
    margin: 24px 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.textareaWrap {
  position: relative;
  margin-bottom: 16px;

  .textarea {
    resize: none;
    background-color: rgba($color-black-95, 0.4);
    border: 1px solid rgba($color-black-85, 0.7);
    padding: 10px 20px;
    padding-right: 120px;
  }

  .btnCopy {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 2;
  }

  &.modalCodeCopy {
    .textarea {
      border-color: $color-brand;
      background-color: $color-brand-90;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 40px !important;
      line-height: 1.8;
      padding-top: 8px;
      color: $color-black;
    }

    .btnCopy {
      bottom: 6px;
    }
  }
}

.modal {
  :global(.ant-modal-header) {
    border-bottom: none;
  }
}

.radioItem {
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
