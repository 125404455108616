@import 'theme/variables.scss';

.root {
  width: 100%;

  & > div {
    height: 100%;
  }
}

.item {
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
  padding: 0 25px;
  width: 100%;

  .itemInfo {
    display: flex;
    flex-grow: 1;

    .avatar {
      margin-right: 10px;
      min-width: 44px;
    }

    .estimateText {
      opacity: 0.7;
      margin-bottom: 6px;
    }

    .info {
      flex-grow: 1;
    }

    .user {
      font-weight: bold;
      font-size: 12px;
    }

    .subtask {
      display: flex;
      margin-top: 5px;
      margin-bottom: 5px;
      cursor: pointer;

      .paragraph {
        width: 45%;
        margin-bottom: 0;
      }
    }

    .description {
      .paragraph {
        color: $text-color;
      }
    }
  }

  .btnContainer {
    align-items: flex-start;
    display: flex;
    margin-top: 12px;

    :global {
      .ant-divider {
        margin: 12px 0;
        background: rgba($color-black-55, 0.5);
        height: 15px;
      }

      .ant-btn-link {
        color: $text-color;

        &:hover {
          color: $color-brand-90;
        }
      }
    }
  }
}

.dates {
  display: flex;
  align-items: center;

  .dateItem + .dateItem {
    margin-left: 4px;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 40px;
  width: 100%;
}

.estimate {
  width: 100%;
  max-width: 250px;
}
