@import 'theme/variables.scss';

.rootWrap {
  display: flex;
  flex-direction: column;
  border: none;

  .label {
    font-size: 12px;
    color: $color-black-55;
  }

  input.input {
    border: none !important;

    &:hover {
      box-shadow: none !important;
    }
  }

  .flexWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  .icon {
    margin: 0 2px;
    flex-shrink: 0;
  }

  .date {
    min-width: auto;
    max-width: 100%;
  }

  .input {
  }

  .popperClassName {
    margin-top: 0;

    :global(.react-datepicker__triangle) {
      display: none;
    }
  }
}

.root {
  display: flex;
  align-items: center;
  background-color: $white;
  height: $height-base;
  border: 1px solid $color-black-65;
  border-radius: 5px;

  :global(.ant-input-affix-wrapper > input) {
    border: none;
    background-color: inherit;
    min-height: auto;

    &:hover {
      box-shadow: none !important;
    }
  }
}

.disabled {
  background-color: $color-black-95;

  :global(.ant-input) {
    min-height: unset !important;
    height: 34px !important;
    background-color: $color-black-95;
  }
}

::placeholder {
  color: $color-black-65 !important;
}
