@import 'theme/variables.scss';

$border-color: rgba($color-black-85, 0.5);

.rootWrap {
  position: relative;
  min-width: 195px;
  max-width: 220px;
  width: 100%;

  &.primary {
    :global {
      .react-datepicker-ignore-onclickoutside {
        background-color: $color-brand-90;
      }
    }

    .calendarClassName {
      background-color: $color-brand-90;

      :global {
        .react-datepicker__day:hover,
        .react-datepicker__month-text:hover,
        .react-datepicker__quarter-text:hover,
        .react-datepicker__time
          .react-datepicker__time-list
          .react-datepicker__time-list-item:hover {
          background-color: $white;
        }

        .react-datepicker__header,
        .react-datepicker__time {
          background-color: $color-brand-90;
        }

        .react-datepicker__time-list {
          max-height: 220px;
        }

        .react-datepicker__day--keyboard-selected,
        .react-datepicker__day--selected,
        .react-datepicker__time-list
          .react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
          &:hover {
            background-color: $primary-color;
          }
        }
      }
    }
  }

  :global {
    .react-datepicker__close-icon {
      padding-right: 4px;
    }

    .react-datepicker__close-icon::after {
      background-color: $color-black-55;
      transition: 0.2s ease-in-out;
      opacity: 0.4;
      line-height: 11px;
    }

    .react-datepicker__close-icon:hover::after {
      opacity: 1;
    }

    .react-datepicker-popper[data-placement^='bottom'] {
      margin-top: 4px;
    }

    .react-datepicker-popper[data-placement^='top'] {
      margin-bottom: 4px;
    }
  }
}

.root {
  margin-right: 10px;
  width: 100%;
  height: $height-base;
  background-color: initial !important;
  outline: none;
  border-radius: $border-radius-base;
  color: $text-color;
  transition: 0.2s ease-in-out;

  &:focus {
    border-color: $primary-color;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
  }

  &:hover {
    border-right-width: 1px !important;
    outline: 0;
    border-color: $primary-color;
  }

  &::placeholder {
    color: $label-color;
  }
}

.popperClassName {
  box-shadow: $shadow-3;

  .calendarClassName {
    border: none;

    :global {
      .react-datepicker__day {
        border-radius: 50%;
        color: $text-color;
      }

      .react-datepicker__day-names {
        background-color: transparent;
        text-transform: capitalize;
      }

      .react-datepicker__day--in-range,
      .react-datepicker__day--keyboard-selected.react-datepicker__day--today,
      .react-datepicker__day--selected {
        background-color: $primary-color;
        color: $white;
      }

      .react-datepicker__day--keyboard-selected,
      .react-datepicker__day--selected,
      .react-datepicker__day.react-datepicker__day--in-range {
        &:hover {
          background-color: $primary-color;
        }
      }

      .react-datepicker__day--disabled {
        color: $color-black-55;
        cursor: not-allowed;
      }

      .react-datepicker__time-container {
        border-color: $border-color;
        border-bottom-left-radius: $border-radius-base;
        overflow: hidden;
      }

      .react-datepicker__month.react-datepicker__month--selecting-range {
        .react-datepicker__day--in-selecting-range {
          background-color: darken($primary-color, 8%);
          color: $white;
        }

        .react-datepicker__day.react-datepicker__day--selected {
          background-color: $primary-color;
        }
      }

      .react-datepicker__day.react-datepicker__day--outside-month {
        color: rgba($text-color, 0.3);
      }

      .react-datepicker__day--in-range.react-datepicker__day--outside-month {
        color: $white;
      }

      .react-datepicker__time-container .react-datepicker__header,
      .react-datepicker__month-container .react-datepicker__current-month {
        border-bottom: 1px solid $border-color;
        height: $height-lg;
      }

      .react-datepicker-time__header,
      .react-datepicker__current-month {
        font-weight: bold;
      }

      .react-datepicker-time__header {
        text-transform: capitalize;
        height: $height-lg;
        line-height: $height-lg;
      }

      .react-datepicker__triangle {
        display: none;
      }
    }
  }
}

.container {
  display: flex;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $height-lg;
  border-bottom: 1px solid $border-color;

  :global {
    .ant-select-dropdown-menu-vertical {
      text-align: left;
    }

    .ant-select-dropdown-menu-item {
      &:hover {
        &:not(.ant-select-dropdown-menu-item-disabled) {
          background-color: $color-brand-90;
          border-radius: $border-radius-base;
        }
      }
    }
  }

  :global(.ant-select:first-child) {
    margin-right: 30px;
  }

  .select :global {
    width: auto;

    .ant-select-selector {
      border: none;
    }

    .ant-select-dropdown-menu {
      max-height: 190px;
    }

    .ant-select-selection,
    .ant-select-selector {
      border: none;
      font-weight: bold;
      background-color: transparent;
      box-shadow: none !important;
      height: $height-lg;
    }

    .ant-select-selection__rendered {
      line-height: $height-lg;
    }

    .ant-select-selection-selected-value,
    .ant-select-selection-item {
      font-size: 12px;
      text-transform: capitalize;
      font-weight: 700;
      box-shadow: none;

      &:hover {
        color: $primary-color;
      }
    }
  }

  .option {
    text-transform: capitalize;
  }

  .btnPrev,
  .btnNext {
    color: $text-color;
    display: flex;
    align-items: center;

    &:hover {
      color: $primary-color;
    }
  }

  .btnPrev {
    margin-right: auto;
  }

  .btnNext {
    margin-left: auto;
  }
}
