@import 'theme/variables.scss';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;

  &.outdated {
    .title {
      color: $color-red-70;
    }
  }

  .flexWrap {
    display: flex;
    align-items: center;
  }

  .flexOferflow {
    margin-right: 10px;
    overflow: hidden;
    flex-shrink: 1;
  }

  .tooltipContent {
    margin: 5px;
  }
}

.tooltip {
  cursor: pointer;

  :global(.ant-tooltip-inner) {
    padding: 0;
  }
}

.isNew {
  animation: isNew 5s;
}

.textNoAccess {
  color: $color-black-65;
}

@keyframes isNew {
  0% {
    box-shadow: 0 0 2px 2px rgba($color-yellow, 0.7);
  }

  50% {
    box-shadow: 0 0 2px 2px rgba($color-yellow, 0.7);
  }
}
