@import 'theme/variables.scss';

.root {
  width: 100%;
  position: relative;
  z-index: 3;

  .dropdown {
    background-color: $white;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow: auto;
    z-index: 1;
    box-shadow: 0 4px 12px rgba($text-color, 0.15);
    border-radius: $border-radius-base;
  }

  .item {
    margin: 0;
    height: $height-base;
    line-height: $height-base;
    padding: 0 10px;
    cursor: pointer;

    &.active {
      background-color: $color-black-95;
    }
  }
}
