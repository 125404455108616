@import 'theme/variables.scss';

$width: 25px;

.table {
  :global {
    .ant-table-thead > tr > th.ant-table-column-has-actions:hover {
      background-color: $color-black-95;
    }

    .ant-table-thead
      > tr
      > th
      .ant-table-header-column
      .ant-table-column-sorters::before:hover {
      background-color: transparent;
    }

    .ant-table-column-title {
      text-transform: uppercase;
    }

    .ant-table-cell-fix-right-first {
      right: 0 !important;
    }

    .ant-table-cell-scrollbar {
      display: none;
    }
  }
}

.creator {
  font-weight: normal;

  span:nth-child(2) {
    color: $color-brand;
    font-size: 14px;
  }
}

.filterWrap {
  width: 100%;
  height: 100%;

  .btnFilter {
    background-color: transparent;
    border-radius: 0;
    height: 100%;
    width: $width;
    color: $color-black-55;
    border: none;
    overflow: hidden;
    padding: 4px;

    &:global(.ant-popover-open) {
      background-color: $color-brand-90;
    }
  }

  svg {
    width: 12px;
  }
}

.isOpacity {
  :global(.ant-table-thead > tr > th),
  :global(.ant-table-tbody > tr > td:not(:last-child)) {
    opacity: 0.5;
  }
}

.actionsBtn {
  margin: auto;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .contentPagination {
    margin-top: 4px;
    gap: 8px;
  }
}

.withHighlight {
  strong {
    font-weight: 400;
    padding: 1px;
    background-color: $color-yellow-20;
    border-radius: 4px;
  }
}
