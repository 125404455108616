@import 'theme/variables.scss';

.root {
  :global .ant-drawer-header {
    .ant-drawer-title {
      font-weight: 600;
      font-size: 20px;
    }
  }

  .stepsContainer {
    height: 100%;

    .stepsContent {
      height: 100%;
    }
  }
}
