@import 'theme/variables.scss';

.root {
  background-color: $color-white;
  transition: background-color 0.3s ease-in-out;

  &.website {
    background-color: $color-yellow-30;
  }

  &.telegram {
    background-color: $color-azure-light;
  }

  &.email {
    background-color: $color-green-30;
  }

  .attachments {
    background-color: transparent;
    padding: 8px 8px 0;
  }

  .padding {
    padding: 8px;
  }

  .editorWrap {
    position: relative;
    border-radius: 8px;
    border: 1px solid $color-black-75;
  }

  .noTopBorderRadius,
  .noTopBorderRadius > input {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .editor {
    border-radius: 8px 8px 0 0;
  }

  .toolbar {
    padding: 12px 16px;
    background-color: $color-black-95;
    border-radius: 0 0 8px 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;

    .left {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  .right {
    display: flex;
    gap: 20px;
    margin-left: auto;
  }

  .emailsSelect {
    min-height: 36px;
    border-radius: 0;
    border-bottom: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-color: $color-black-75;

    &:focus,
    &:active,
    &:hover {
      border-color: $color-black-75 !important;
      outline: none;
      box-shadow: none;
    }
  }

  .subject {
    border-radius: 0;
  }
}

.wrapRestoreContact {
  display: flex;
  background-color: $color-white;

  .restoreContactBtn {
    width: 100%;
    margin: 0px 8px 24px;
  }
}

.wrapRestoreContact {
  display: flex;
  background-color: $color-white;

  .restoreContactBtn {
    width: 100%;
    margin: 0px 8px 24px;
  }
}
