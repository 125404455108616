.root {
  display: flex;
  padding: 24px;
  overflow-y: auto;
  flex: 0 0 auto;
  height: 100%;

  &.empty {
    padding: 16px;
    justify-content: center;
  }

  & > div {
    height: 100%;
    width: 100%;
  }

  .emptyContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
