.content {
  padding: 32px 24px;
  text-align: center;
}

.iconWrap {
  margin-bottom: 20px;
  padding: 36px 0 0;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
  line-height: 1.4;
  font-weight: 700;
}

.btnWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}
