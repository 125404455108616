@import 'theme/variables.scss';

.root {
  margin: auto 0 8px;
  border-radius: 12px;

  .textarea {
    padding-top: 12px;
    padding-bottom: 12px;
    resize: none;
    border-bottom: 1px solid transparent !important;
    border-radius: 12px 12px 0 0;
    border-color: $color-black-75;

    &:focus {
      box-shadow: none;
      border-bottom: 1px solid #32a6a8 !important;
    }
  }

  .toolbar {
    display: flex;
    justify-content: flex-end;
    background-color: $color-black-95;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    padding: 12px 16px;
    border: 1px solid $color-black-75;
    border-top: none;

    .sendButton {
      height: auto;
      padding: 0;
      margin: 0;
      font-weight: 600 !important;
    }
  }
}

.bottomText {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 12px;
}
