@import 'theme/variables.scss';

.root {
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .leftSide {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;

    .infoWrap {
      max-width: 75%;
    }
  }

  .rightSide {
    display: flex;
    gap: 16px;
  }

  .button {
    padding: 0;
    margin: 0;
  }
}

.innerHeader {
  width: 100%;
  overflow: hidden;
}
