.root {
  height: 100%;
  display: flex;
  flex-direction: column;

  :global(.ant-divider-horizontal) {
    min-height: 1px;
  }

  .button {
    width: 100%;
  }

  .statusEmailError {
    display: flex;
    align-items: center;

    .updateAuthBtn {
      font-size: 12px;
      padding: 0;
      height: auto;
    }
  }

  .mb0 {
    margin-bottom: 0 !important;
  }

  .mt16 {
    margin-top: 16px;
  }

  .mb16 {
    margin-bottom: 16px;
  }

  .tooltip {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .editor {
    margin-top: 16px;
  }
}
