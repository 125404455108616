.root {
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  .header {
    margin: 32px 0;
  }

  .title {
    margin: 0 0 16px !important;
  }

  .link {
    margin-top: 16px;
    font-weight: 600;
    font-size: 16px;
  }

  .buttons {
    margin-top: 32px;
    display: flex;
    gap: 20px;
  }
}
