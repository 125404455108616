@import 'theme/variables.scss';

.root {
  width: 100%;
}

.card {
  display: flex;
  border: 1px solid $border-color-base;
  border-radius: $border-radius-base;
  margin-bottom: 10px;

  .content {
    flex-grow: 1;
    padding: 10px;
  }

  .avatar {
    max-width: 180px;
    border-left: 1px solid $border-color-base;
    padding: 10px;
  }
}
