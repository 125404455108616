.notificationList {
  margin-left: 24px;
  margin-top: 24px !important;
}

.firstPane {
  transition: none !important;
}

.secondPane {
  padding-top: 15px;
}

.secondPaneWithActions {
  height: calc(100% - 144px);
  padding-top: 15px;
}
