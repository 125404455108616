@import 'theme/variables.scss';

.root {
  padding: 16px 12px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: auto;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    padding-right: 44px;
    width: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 100%;
    width: 100%;
    max-height: 500px;
    overflow: auto;
    padding-right: 12px;
  }

  .icon {
    margin-right: 8px;
  }

  .status {
    margin: 0 auto 0 0;
  }

  .actionButtons {
    display: flex;
    align-items: center;
    gap: 16px;

    .toTaskButton {
      padding: 0;
      height: auto;
      border: unset;
      display: flex;
    }
  }

  .title {
    margin-bottom: 12px;
  }

  .collapseTitle {
    margin-bottom: 0;
  }

  .location {
    margin-bottom: 12px;

    i {
      margin: 0 8px 0 0;
    }

    span {
      margin: 0;
    }
  }

  .googleMeet {
    margin-bottom: 12px;
  }

  .divider {
    margin: 0 0 12px 0;
  }

  .attendee {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    .employeeInfo {
      display: flex;
      align-items: center;
      gap: 8px;
      max-width: 80%;
    }
  }

  .spinWrapper {
    width: 100%;
  }

  .statusesBtns {
    display: flex;
    padding-top: 8px;
    border-top: 1px solid $color-black-85;
    gap: 8px;
    width: 100%;
    margin-top: 12px;
    padding-right: 12px;

    & > button {
      flex: 1 1 50%;
    }
  }
}
