.root {
  padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .twoFields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  .buttonWrap {
    margin-top: auto;
  }

  .button {
    margin: 8px 0 0 auto;
  }
}
