.root {
  min-height: 234px;
  display: flex;
  flex-direction: column;
}

.skeleton {
  display: flex;
  flex-direction: column;
  flex: auto;
  gap: 24px;
  width: 100%;
  height: 100%;
  padding: 16px 12px 24px 24px;

  .title {
    margin: 0;
    width: 80%;
    height: 24px;
  }

  .bodyWrap {
    height: 100%;
    flex: auto;
  }

  .body {
    height: 100%;
    margin: 0;
  }

  .buttonsWrap {
    display: flex;
    gap: 24px;
  }

  .button {
    max-width: unset;
  }
}
