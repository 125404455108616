@import 'theme/variables.scss';

.root {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: $color-white;
  border-radius: 4px;
  border: 1px solid $color-black-85;
  transition: 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    box-shadow: 0 2px 15px 0 $color-black-85;
  }

  .flex {
    padding: 3px 8px;
    display: flex;
    align-items: center;
  }

  .link {
    flex: auto;
    width: 1%;
    min-width: 300px;

    & > span {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  }

  .expiration {
    display: flex;
    max-width: calc(100% - 24px);
  }

  .calendar {
    height: auto;
    padding: 0;
  }

  .tags {
    margin-right: 16px;
  }

  .subscription {
    margin-right: 16px;
  }
}
