@import 'theme/variables.scss';

$successColor: lighten($primary-color, 54%);

.date-flag {
  position: relative;
  background-color: $successColor;
  color: $primary-color;
  font-size: 12px;
  padding: 2px 5px;
  display: flex;
  align-items: center;

  &::before,
  &::after {
    position: absolute;
    content: '';
    right: 100%;
    border: 10px solid $successColor;
    border-left-color: transparent;
  }

  &::before {
    top: 0;
    border-bottom: 10px solid transparent;
  }

  &::after {
    bottom: 0;
    border-top: 10px solid transparent;
  }

  &.danger {
    background-color: $color-red-90;
    color: $color-red-70;

    &::after {
      border: 11px solid $color-red-90;
      border-left: 10px solid transparent;
    }
  }
}

.date-tag {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  min-width: 100px;

  .date-tag-label {
    padding-left: 2px;
    padding-bottom: 2px;
    color: $color-black-55;
    text-transform: uppercase;
  }

  .date-tag-value {
    background-color: $successColor;
    color: $primary-color;
    font-weight: 600;
    padding: 2px 12px;
    border-radius: 10px;
  }

  &.danger .date-tag-value {
    background: $color-red-90;
    color: $color-red-70;
  }
}

.ant-collapse-borderless {
  background-color: transparent;
}
