.title {
  margin-bottom: 16px !important;
}

.subtitle {
  margin-bottom: 4px !important;
}

.text {
  display: block;
  margin-bottom: 16px;
}

.inputWrapper {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}
