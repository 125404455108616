@import 'theme/variables.scss';

.dropdownContent {
  width: 100%;
  min-width: 195px;
}

.delete {
  color: $color-red !important;

  & > span {
    color: $color-red !important;
  }
}
