.root {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;

  .title {
    margin-bottom: 16px;
  }

  .btn {
    margin-top: auto;
    margin-left: auto;
    width: 160px;
  }

  .radioGroup {
    margin-bottom: 24px;
    display: flex;
    gap: 16px;
  }
}
