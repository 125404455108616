$size: 18px;
$smallSize: 15px;

.ant-checkbox,
.ant-tree-checkbox {
  &:hover {
    .ant-checkbox-inner {
      border-color: $color-brand;
    }
  }

  .ant-checkbox-inner {
    border-color: $color-black-55;
  }

  &.ant-checkbox-checked .ant-checkbox-inner {
    border-color: $color-brand;
  }

  &.ant-checkbox-disabled {
    .ant-checkbox-inner {
      border-color: $color-black-75 !important;
      background-color: transparent;
    }

    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: $color-black-75;

        &::after {
          border-color: $color-white;
        }
      }
    }
  }

  .ant-checkbox-inner,
  .ant-tree-checkbox-inner {
    width: $size;
    height: $size;

    &::after {
      width: 6px;
      height: 12px;
      left: 20%;
      top: 45%;
    }
  }

  &.ant-checkbox-indeterminate,
  &.ant-tree-checkbox-indeterminate {
    .ant-checkbox-inner,
    .ant-tree-checkbox-inner {
      background-color: $primary-color;
      border-color: $primary-color;

      &::after {
        top: 50%;
        left: 50%;
        width: calc(100% - 6px);
        height: 2px;
        background-color: $color-white;
      }
    }
  }
}

.ant-checkbox:hover::after,
.ant-tree-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
  display: none;
}

.ant-checkbox-wrapper {
  &.primary {
    color: $primary-color;
  }

  &.small {
    .ant-checkbox-inner {
      height: $smallSize;
      width: $smallSize;

      &::after {
        width: 6px;
        height: 10px;
      }
    }

    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        border: none;
      }

      &::after {
        display: none;
        height: $smallSize;
      }
    }
  }
}
