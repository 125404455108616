@import 'theme/variables.scss';

.tag {
  background-color: $color-danube-20;
  border-radius: 20px;
  padding: 2px 8px;
  margin-left: 8px;
}

.wrapper {
  display: flex;
  align-items: center;
}
